<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      :title="'Data Editor Test'"
    ></subpage-header>
    <div class="subpage-information-area-margin">
      <!-- Content Goes Here -->
      <div class="box bunya-box no-shadow empty-state large">
        <h3>Data Editor Content Goes Here</h3>
        <data-editor></data-editor>
      </div>
    </div>
  </section>
</template>

<style>

</style>

<script>

import SubpageHeader from '@/components/SubpageHeader.vue'
import DataEditor from '@/components/DataEditor/DataEditor.vue'

export default {
  components: { SubpageHeader, DataEditor },
  name: 'data-editor-view'
}

</script>
