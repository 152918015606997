<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21 9.67394V20C21 21.1046 20.1046 22 19 22H15V14.4H9V22H5C3.89543 22 3 21.1046 3 20L3 2L9 2V5.007L12 3L21 9.67394Z" :fill="fill"/>
  <path d="M21 9.67394V20C21 21.1046 20.1046 22 19 22H15V14.4H9V22H5C3.89543 22 3 21.1046 3 20L3 2L9 2V5.007" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M23 11L12 3L1 11" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>

export default {
  name: 'IconHome',
  props: ['stroke', 'fill']
}

</script>
