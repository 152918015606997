<template>
  <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_timeline</title>
    <g id="icon_timeline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="home" transform="translate(12.000000, 9.500000) scale(1, -1) translate(-12.000000, -9.500000) translate(5.000000, 3.000000)" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
        <path d="M0,12 L0,4.4 L0,4.4 L7,0.2 L14,4.4 L14,12 C14,12.5522847 13.5522847,13 13,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 Z" id="Path"></path>
      </g>
      <line x1="19" y1="20" x2="5" y2="20" id="Path" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
      <line x1="15" y1="8" x2="9" y2="8" id="Path" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
      <circle id="Oval" :fill="stroke" cx="12" cy="20" r="2"></circle>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconTimeline',
  props: ['stroke', 'fill']
}

</script>
