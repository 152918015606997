<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1.5 1.5L16.5 16.5" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M16.5 1.5L1.5 16.5" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>

export default {
  name: 'IconCross',
  props: ['stroke', 'fill']
}

</script>
