<template>
  <!-- <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.447716 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1V17C16 17.5523 15.5523 18 15 18H1C0.447715 18 0 17.5523 0 17V1Z" :stroke="stroke" stroke-width="2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0.25H13V7.25L10.4474 6.25L8 7.25V0.25Z" :stroke="stroke" stroke-width="2" stroke-linejoin="round"/>
  <path d="M3.41666 7.25H4.58332" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M3.41667 10.75H12.5833" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M3.41666 14.5H9.99999" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  </svg> -->
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_template_20</title>
    <g id="icon_template_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-4" transform="translate(2.000000, 1.000000)" stroke="#AAAAAA" stroke-width="2">
            <g id="Group-10">
                <path d="M1,0 L15,0 C15.5522847,-1.01453063e-16 16,0.44771525 16,1 L16,17 C16,17.5522847 15.5522847,18 15,18 L1,18 C0.44771525,18 6.76353751e-17,17.5522847 0,17 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z" id="Rectangle"></path>
            </g>
            <polygon id="Rectangle" stroke-linejoin="round" points="8 0 13 0 13 7 10.4474485 6 8 7"></polygon>
            <line x1="3.41666667" y1="6" x2="4.58333333" y2="6" id="Line-4" stroke-linecap="round"></line>
            <line x1="3.41666667" y1="10" x2="12.5833333" y2="10" id="Line-4" stroke-linecap="round"></line>
            <line x1="3.41666667" y1="14" x2="10" y2="14" id="Line-4" stroke-linecap="round"></line>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconSaveAsTemplate',
  props: ['stroke', 'fill']
}

</script>
