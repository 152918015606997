import Http from '@/http'

const ExpensesStore = {
  namespaced: true,
  state: () => ({
    expenses: [],
    expensesGraphData: {
      labels: [],
      colors: [],
      data: []
    }
  }),
  actions: {
    fetch ({ commit }) {
      const url = process.env.VUE_APP_ROOT_API + 'expenses'
      return new Promise((resolve, reject) => {
        Http.get(url).then(
          response => {
            if (response.data) {
              commit('setExpenses', response.data)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetchGraphData ({ commit }) {
      const url = process.env.VUE_APP_ROOT_API + 'expenses/graph-data'
      return new Promise((resolve, reject) => {
        Http.get(url).then(
          response => {
            if (response.data) {
              const expensesGraphData = {
                labels: response.data.labels,
                colors: response.data.colors,
                values: response.data.values
              }
              commit('setExpensesGraphData', expensesGraphData)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    }
  },
  getters: {
    paid (state) {
      return state.expenses.filter((expense) => {
        return (expense.finalized && expense.paid_at !== null)
      })
    },
    unpaid (state) {
      return state.expenses.filter((expense) => {
        return (expense.finalized && expense.paid_at === null)
      })
    },
    draft (state) {
      return state.expenses.filter((expense) => {
        return (expense.finalized === false)
      })
    },
    graphData (state) {
      return {
        // TODO Localize labels
        chartData: {
          labels: state.expensesGraphData.labels,
          datasets: [
            {
              label: 'Recent Expenses',
              backgroundColor: state.expensesGraphData.colors,
              data: state.expensesGraphData.values
            }
          ]
        },
        extraOptions: {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    }
  },
  mutations: {
    setExpenses (state, expenses) {
      state.expenses = expenses
    },
    setExpensesGraphData (state, expensesGraphData) {
      state.expensesGraphData = expensesGraphData
    }
  }
}

export default ExpensesStore
