<template>
  <!-- <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.024 5.47493C10.7382 5.76656 10.7382 6.2333 11.024 6.52493L12.224 7.72493C12.5157 8.01079 12.9824 8.01079 13.274 7.72493L16.1015 4.89743C16.8737 6.60388 16.5082 8.6102 15.1837 9.93464C13.8593 11.2591 11.853 11.6246 10.1465 10.8524L4.96404 16.0349C4.34272 16.6562 3.33536 16.6562 2.71404 16.0349C2.09272 15.4136 2.09272 14.4062 2.71404 13.7849L7.89654 8.60243C7.12434 6.89598 7.48989 4.88966 8.81433 3.56522C10.1388 2.24078 12.1451 1.87523 13.8515 2.64743L11.0315 5.46743L11.024 5.47493Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_sml_setup_18</title>
    <g id="icon_sml_setup_18" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <path d="M10.7759903,5.2278585 C10.4901331,5.51949113 10.4901331,5.98622587 10.7759903,6.2778585 L11.9759903,7.4778585 C12.2676229,7.76371566 12.7343576,7.76371566 13.0259903,7.4778585 L15.8534903,4.6503585 C16.6256918,6.35680611 16.2601346,8.36313185 14.9356991,9.68756732 C13.6112636,11.0120028 11.6049379,11.37756 9.89849026,10.6053585 L4.71599026,15.7878585 C4.09466991,16.4091788 3.0873106,16.4091788 2.46599026,15.7878585 C1.84466991,15.1665382 1.84466991,14.1591788 2.46599026,13.5378585 L7.64849026,8.3553585 C6.87628874,6.64891089 7.24184596,4.64258515 8.56628143,3.31814968 C9.8907169,1.99371421 11.8970426,1.62815698 13.6034903,2.4003585 L10.7834903,5.2203585 L10.7759903,5.2278585 Z" id="Path" stroke="#AAAAAA" stroke-width="2"></path>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconSetup',
  props: ['stroke', 'fill']
}

</script>
