<template>
  <div class="is-title-bar">
    <div class="box fix-padding main-page-header is-hidden-touch">
      <div class="columns is-mobile is-marginless">
        <div class="column is-12 green-line"></div>
      </div>
      <div v-if="breadcrumb" class="level is-mobile breadcrumb-position">
        <div class="level-left">
          <div class="level-item">
            <a href="javascript:void(0)" v-on:click="$emit('breadcrumb')">
              <span class="is-inline is-light">
                <b-icon icon="chevron-left" class="chevron-color"/>
                {{ breadcrumb }}
              </span>
            </a>
          </div>
        </div>
        <div class="level-right"></div>
      </div>
      <div class="level header-level-position">
        <div class="level-left">
          <div class="level-item">
            <div style="display:block">
              <h1 class="subpage-title rubik">{{ title }}</h1>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item" v-if="false">
            <b-field class="header-search" expanded>
              <b-input placeholder="Search..." type="search" icon="magnify" expanded>
              </b-input>
            </b-field>
          </div>
          <div class="level-item" v-if="primaryAction" style="align-items: flex-start;">
            <button class="button is-primary" v-on:click="$emit(primaryAction.emit)">{{ primaryAction.name }}</button>
          </div>
        </div>
      </div>
      <hr v-if="showSubmenu">
      <div class="level no-margin" v-if="showSubmenu">
        <div class="level-left">
          <div class="level-item">
            <icon v-if="subtitleIcon" :icon="subtitleIcon"/>
            <h2>{{ subtitle }}</h2>
          </div>
        </div>
        <div class="level-right">
          <div v-for="(secondaryAction, index) in secondaryActions" v-bind:key="index" class="level-item">
            <a class="header-link" v-on:click="$emit(secondaryAction.emit)">
              <span class="is-inline"><icon :icon="secondaryAction.icon" width="20" height="20"/> {{ secondaryAction.name}}</span>
            </a>
          </div>
          <div class="level-item">
            <snowman-menu :items="tertiaryActions" v-on="$listeners"/>
          </div>
        </div>
      </div>
    </div>
    <!-- invoices mobile header -->
    <div class="box fix-padding main-page-header is-hidden-desktop">
      <nav-bar-mobile></nav-bar-mobile>
      <div class="level is-mobile header-level-position has-mobile-margin">
        <div class="level-left">
          <div class="level-item">
            <h1 class="subpage-title rubik">{{ title }}</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item" v-if="primaryAction" style="align-items: flex-start;">
            <button class="button is-primary liftbutton" v-on:click="$emit(primaryAction.emit)">{{ primaryAction.name }}</button>
          </div>
        </div>
      </div>
      <hr v-if="showSubmenu">
      <div class="level is-mobile no-margin is-flex-title" v-if="showSubmenu">
        <div class="level-left">
          <div class="level-item">
            <icon :icon="subtitleIcon"/>
            <h2>{{ subtitle }}</h2>
          </div>
        </div>
        <div class="level-right secondary-with-margin-top" v-if="mobileSnowmanActions">
          <div class="level-item" v-if="mobileSnowmanActions">
            <snowman-menu :items="mobileSnowmanActions" v-on="$listeners"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icons/Icon'
import SnowmanMenu from '@/components/SnowmanMenu'
import NavBarMobile from '@/components/NavBarMobile'

export default {
  name: 'SubpageHeader',
  components: { Icon, SnowmanMenu, NavBarMobile },
  computed: {
    showSubmenu () {
      return !!this.secondaryActions || !!this.tertiaryActions || !!this.subtitle
    },
    mobileSnowmanActions () {
      let mobileSnowmanActions = []
      // if (this.primaryAction) {
      //   mobileSnowmanActions.push(this.primaryAction)
      // }
      if (this.secondaryActions) {
        mobileSnowmanActions = mobileSnowmanActions.concat(this.secondaryActions)
      }
      if (this.tertiaryActions) {
        mobileSnowmanActions = mobileSnowmanActions.concat(this.tertiaryActions)
      }
      return mobileSnowmanActions
    }
  },
  props: ['primaryAction', 'secondaryActions', 'tertiaryActions', 'title', 'subtitle', 'subtitleIcon', 'breadcrumb']
}
</script>

<style lang="scss" scoped>
  .has-mobile-margin {
    margin-left: 30px;
    margin-top: 25px;
    margin-right: 15px;
    margin-bottom: 0px;
  }

.is-flex-title {
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
}

//.breadcrumb-position {
//  margin-top: 5px;
//  margin-bottom: -16px !important;
//}
//MARC Fix dashboard mobile fix
@media only screen and (min-width: 1024px) {
.breadcrumb-position {
  margin-top: 5px;
  margin-bottom: 0px !important;
  margin-left: -5px;
  //display: none;
  }
}
.is-inline {
  display: flex !important;
  font-size: 14px;
  font-weight: 600;
  padding-top: 2px;
  align-items: center;
  &:hover {
    i {
    color: #1BCA8E;
    }
  }
  .icon-container {
    padding-right: 5px;
  }
}
.is-flex {
  display: flex;
  margin-right: 5px;
}

.icon-container {
  //display: flex;
  //background-color: crimson;
  padding-right: 5px;
}

.no-margin {
  margin-bottom: -30px;
}

// .header-search {
//   margin-bottom: 0px;
//   margin-right: .75rem;
//   .control.has-icons-left .input, .control.has-icons-left .taginput .taginput-container.is-focusable, .taginput .control.has-icons-left .taginput-container.is-focusable, .control.has-icons-left .select select {
//     padding-left: 2.25em;
//     width: 39px !important;
//   }
// }

.is-light {
  color: #4A90E2;
}
</style>
