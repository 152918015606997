<template>
  <th :style="style" class="has-text-centered"><abbr :title="name">{{ abbreviation }}</abbr></th>
</template>

<style>

th {
  min-height: 10px;
}

</style>

<script>

export default {
  computed: {
    style () {
      return 'width: ' + this.width + '%'
    }
  },
  name: 'data-editor-header',
  props: ['width', 'name', 'abbreviation']
}

</script>
