import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import Clients from './views/Clients.vue'
import Invoices from './views/Invoices.vue'
import Quotes from './views/Quotes.vue'
import Settings from './views/Settings.vue'
import Reports from './views/Reports.vue'
import DataEditorView from './views/DataEditorView.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/data-editor-view',
      name: 'data-editor-view',
      component: DataEditorView
    },
    {
      path: '/',
      name: 'default-route',
      component: () => import(/* webpackChunkName: "full-page" */ './views/Default.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "tables" */ './views/Dashboard.vue')
    },
    {
      path: '/clients',
      name: 'clients',
      component: Clients
    },
    {
      path: '/clients/create',
      name: 'clients-create',
      component: () => import(/* webpackChunkName: "client-editor" */ './views/ClientEditor.vue')
    },
    {
      path: '/clients/:id',
      name: 'clients-show',
      props: true,
      component: () => import(/* webpackChunkName: "clients" */ './views/Client.vue')
    },
    {
      path: '/clients/:id/edit',
      name: 'clients-edit',
      props: true,
      component: () => import(/* webpackChunkName: "client-editor" */ './views/ClientEditor.vue')
    },
    {
      path: '/suppliers',
      name: 'suppliers',
      component: () => import(/* webpackChunkName: "suppliers" */ './views/Suppliers.vue')
    },
    {
      path: '/quotes',
      name: 'quotes',
      component: Quotes
    },
    {
      path: '/quotes/create',
      name: 'quotes-create',
      component: () => import(/* webpackChunkName: "quotes-edit" */ './views/QuoteEdit.vue')
    },
    {
      path: '/quotes/:id',
      name: 'quotes-show',
      props: true,
      component: () => import(/* webpackChunkName: "quotes-edit" */ './views/QuoteEdit.vue')
    },
    {
      path: '/reports',
      component: Reports,
      children: [
        {
          path: '/reports',
          name: 'reports-home',
          component: () => import(/* webpackChunkName: "reports-home" */ './components/Reports/Home.vue')
        },
        {
          path: '/reports/bas',
          name: 'reports-bas',
          component: () => import(/* webpackChunkName: "reports-bas" */ './components/Reports/Bas.vue')
        },
        {
          path: '/reports/income',
          name: 'reports-income',
          component: () => import(/* webpackChunkName: "reports-sales-taxes" */ './components/Reports/Income.vue')
        },
        {
          path: '/reports/sales-taxes',
          name: 'reports-sales-taxes',
          component: () => import(/* webpackChunkName: "reports-sales-taxes" */ './components/Reports/SalesTaxes.vue')
        }
      ]
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: () => import(/* webpackChunkName: "reports" */ './views/Tasks.vue')
    },
    {
      path: '/settings',
      component: Settings,
      children: [
        {
          path: '/settings',
          name: 'settings-home',
          component: () => import(/* webpackChunkName: "settings-home" */ './components/Settings/Home.vue')
        },
        {
          path: '/settings/account-details',
          name: 'settings-account-details',
          component: () => import(/* webpackChunkName: "settings-account-details" */ './components/Settings/AccountDetails.vue')
        },
        {
          path: '/settings/availability',
          name: 'settings-availability',
          component: () => import(/* webpackChunkName: "settings-account-details" */ './components/Settings/Availability.vue')
        },
        {
          path: '/settings/change-password',
          name: 'settings-change-password',
          component: () => import(/* webpackChunkName: "settings-change-password" */ './components/Settings/ChangePassword.vue')
        },
        {
          path: '/settings/theme',
          name: 'settings-theme',
          component: () => import(/* webpackChunkName: "settings-theme" */ './components/Settings/Theme.vue')
        },
        {
          path: '/settings/subscription',
          name: 'settings-subscription',
          component: () => import(/* webpackChunkName: "settings-subscription" */ './components/Settings/Subscription.vue')
        },
        {
          path: '/settings/bank-accounts',
          name: 'settings-bank-accounts',
          component: () => import(/* webpackChunkName: "settings-bank-accounts" */ './components/Settings/BankAccounts.vue')
        },
        {
          path: '/settings/bank-accounts/create',
          name: 'settings-bank-accounts-create',
          component: () => import(/* webpackChunkName: "settings-bank-accounts-edit" */ './components/Settings/BankAccountEdit.vue')
        },
        {
          path: '/settings/bank-accounts/:id',
          name: 'settings-bank-accounts-show',
          props: true,
          component: () => import(/* webpackChunkName: "settings-bank-accounts-edit" */ './components/Settings/BankAccountEdit.vue')
        },
        {
          path: '/settings/invoice-settings',
          name: 'settings-invoice-settings',
          component: () => import(/* webpackChunkName: "settings-invoice-settings" */ './components/Settings/InvoiceSettings.vue')
        },
        {
          path: '/settings/service-categories',
          name: 'settings-service-categories',
          component: () => import(/* webpackChunkName: "settings-service-categories" */ './components/Settings/ServiceCategories.vue')
        },
        {
          path: '/settings/service-categories/create',
          name: 'settings-service-categories-create',
          component: () => import(/* webpackChunkName: "settings-service-categories-edit" */ './components/Settings/ServiceCategoryEdit.vue')
        },
        {
          path: '/settings/service-categories/:id',
          name: 'settings-service-categories-show',
          props: true,
          component: () => import(/* webpackChunkName: "settings-service-categories-edit" */ './components/Settings/ServiceCategoryEdit.vue')
        },
        {
          path: '/settings/services',
          name: 'settings-services',
          component: () => import(/* webpackChunkName: "settings-services" */ './components/Settings/Services.vue')
        },
        {
          path: '/settings/services/create',
          name: 'settings-services-create',
          component: () => import(/* webpackChunkName: "settings-services-edit" */ './components/Settings/ServiceEdit.vue')
        },
        {
          path: '/settings/services/:id',
          name: 'settings-services-show',
          props: true,
          component: () => import(/* webpackChunkName: "settings-service-edit" */ './components/Settings/ServiceEdit.vue')
        },
        {
          path: '/settings/stripe',
          name: 'settings-stripe',
          component: () => import(/* webpackChunkName: "settings-stripe" */ './components/Settings/Stripe.vue')
        },
        {
          path: '/settings/tax-rate-groups',
          name: 'settings-tax-rate-groups',
          component: () => import(/* webpackChunkName: "settings-tax-rate-groups" */ './components/Settings/TaxRateGroups.vue')
        },
        {
          path: '/settings/tax-rate-groups/create',
          name: 'settings-tax-rate-group-create',
          component: () => import(/* webpackChunkName: "settings-tax-rate-group-edit" */ './components/Settings/TaxRateGroupEdit.vue')
        },
        {
          path: '/settings/tax-rate-groups/:id',
          name: 'settings-tax-rate-group-edit',
          props: true,
          component: () => import(/* webpackChunkName: "settings-tax-rate-group-edit" */ './components/Settings/TaxRateGroupEdit.vue')
        },
        {
          path: '/settings/users',
          name: 'settings-users',
          component: () => import(/* webpackChunkName: "settings-users" */ './components/Settings/Users.vue')
        },
        {
          path: '/settings/users/create',
          name: 'settings-users-create',
          component: () => import(/* webpackChunkName: "settings-users-edit" */ './components/Settings/UserEdit.vue')
        },
        {
          path: '/settings/users/:id',
          name: 'settings-users-show',
          props: true,
          component: () => import(/* webpackChunkName: "settings-users-edit" */ './components/Settings/UserEdit.vue')
        }
      ]
    },
    {
      path: '/calendar',
      name: 'calendar',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "tables" */ './views/Calendar.vue')
    },
    {
      path: '/forms',
      name: 'forms',
      component: () => import(/* webpackChunkName: "forms" */ './views/Forms.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue')
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: Invoices
    },
    {
      path: '/invoices/create',
      name: 'invoices-create',
      props: true,
      component: () => import(/* webpackChunkName: "invoices" */ './views/InvoiceEdit.vue')
    },
    {
      path: '/invoices/:id',
      name: 'invoices-show',
      props: true,
      component: () => import(/* webpackChunkName: "invoices-edit" */ './views/InvoiceEdit.vue')
    },
    {
      path: '/expenses',
      name: 'expenses',
      component: () => import(/* webpackChunkName: "expenses" */ './views/Expenses.vue')
    },
    {
      path: '/expenses/:id',
      name: 'expenses-show',
      props: true,
      component: () => import(/* webpackChunkName: "expenses" */ './views/Expenses.vue')
    },
    {
      path: '/email',
      component: () => import(/* webpackChunkName: "email" */ './views/Email.vue'),
      children: [
        {
          path: '/payment',
          name: 'payment',
          component: () => import(/* webpackChunkName: "email" */ './views/email/Payment.vue')
        }
      ]
    },
    {
      path: '/full-page',
      component: () => import(/* webpackChunkName: "full-page" */ './views/FullPage.vue'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Login.vue')
        },
        {
          path: '/password-reset',
          name: 'password-reset',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/PasswordReset.vue')
        },
        {
          path: '/password-reset-confirm/:passwordResetToken',
          name: 'password-reset-confirm',
          props: true,
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/PasswordResetConfirm.vue')
        },
        {
          path: '/error-in-card',
          name: 'error-in-card',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Error.vue')
        },
        {
          path: '/error-simple',
          name: 'error-simple',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/Error.vue'),
          props: { isInCard: false }
        },
        {
          path: '/lock-screen',
          name: 'lock-screen',
          component: () => import(/* webpackChunkName: "full-page" */ './views/full-page/LockScreen.vue')
        },
        {
          path: '/logout',
          name: 'logout',
          component: () => import(/* webpackChunkName: "logout" */ './views/Logout.vue')
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  store.dispatch('cancellationTokens/cancelPendingRequests')
  store.dispatch('closeSettingsModal')
  next()
})

export default router
