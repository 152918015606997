<template>
  <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0206 9.56714V9.56714C8.39059 9.56714 5.44922 6.62769 5.44922 3H18.5921C18.5921 6.62769 15.6507 9.56714 12.0206 9.56714Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.9411 8.14352L5.44922 13.5076L12.0206 21.3881L18.5921 13.5076L16.4853 7.80859" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.0215 21.3879V14.8208" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_theme_24</title>
    <g id="icon_theme_24" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <path d="M12,9 C8.1332,9 5,5.8668 5,2 L19,2 C19,5.8668 15.8668,9 12,9 Z" id="Stroke-365" stroke="#AAAAAA" stroke-width="2"></path>
        <polyline id="Path" stroke="#AAAAAA" stroke-width="2" points="8 7.79389794 6 13.8754232 12 22 18 13.8754232 15.9161 7.79389794"></polyline>
        <line x1="12" y1="22" x2="12" y2="15" id="Stroke-367" stroke="#AAAAAA" stroke-width="2"></line>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconTheme',
  props: ['stroke', 'fill']
}

</script>
