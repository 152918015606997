<template>
  <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>arrow_med_10</title>
      <g id="arrow_med_10" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M8.27788958,2.2875549 C8.66882862,1.89784858 9.30159576,1.89778024 9.69261897,2.28740211 L9.69261897,2.28740211 L9.71307646,2.30784026 L9.71307646,2.30784026 C10.1045319,2.69846472 10.1043693,3.33162968 9.71322914,3.72153779 L9.71322914,3.72153779 L5.72211202,7.71060547 C5.62061823,7.81177922 5.50282519,7.88669132 5.3771945,7.93534085 C5.01651335,8.07576207 4.59134628,8.00095662 4.29948776,7.71061413 L0.295820531,3.72774359 C-0.098871076,3.33510217 -0.100533083,2.69684264 0.292108335,2.30215104 C0.29283747,2.30141809 0.293567733,2.30068627 0.29429912,2.29995558 L0.303360661,2.2909026 C0.696357997,1.89827672 1.33292924,1.89759841 1.72676242,2.28938587 L5.008,5.554 Z" id="Combined-Shape" fill="#AAAAAA"></path>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconChevronDown',
  props: ['stroke', 'fill']
}

</script>
