<template>
  <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6 10H5" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 14H5" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 6H5" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15 10H9" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15 14H9" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15 6H9" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <rect x="1" y="1" width="18" height="18" rx="2.95238" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_new_task_20</title>
    <g id="icon_new_task_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Group-28" transform="translate(1.000000, 1.000000)" :stroke="stroke" stroke-width="2">
            <g id="Group-20" transform="translate(4.000000, 4.000000)">
                <line x1="1" y1="5" x2="-8.8817842e-15" y2="5" id="Path"></line>
                <line x1="1" y1="9" x2="2.89102076e-13" y2="9" id="Path"></line>
                <line x1="1" y1="1" x2="-2.24154029e-13" y2="1" id="Path"></line>
                <line x1="10" y1="5" x2="4" y2="5" id="Path"></line>
                <line x1="10" y1="9" x2="4" y2="9" id="Path"></line>
                <line x1="10" y1="1" x2="4" y2="1" id="Path"></line>
            </g>
            <rect id="Rectangle" x="0" y="0" width="18" height="18" rx="2.95238095"></rect>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconTask',
  props: ['stroke', 'fill']
}

</script>
