<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      :primary-action="primaryAction"
      title="Quotes"
      v-on:add="goToQuoteCreate">
    </subpage-header>
    <div class="subpage-information-area-margin" v-if="showOnboarding">
      <hr class="is-hidden-touch">
      <div class="box bunya-box no-shadow empty-state large">
        <div class="no-content">
          <img class="large" src="../assets/emptyStates/empty_quotes_lrg.svg">
          <h3 class="large">Quotes</h3>
          <h5 class="large">You haven't created any quotes yet.</h5>
          <router-link :to="{ name: 'quotes-create' }" class="button is-primary">Create quote</router-link>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- for desktop -->
      <div class="subpage-information-area-margin is-hidden-touch">
        <hr class="is-hidden-touch">
        <p v-if="isLoading">loading...</p>
        <div class="columns" v-else>
          <div class="column is-4">
            <div class="title-padding">
              <icon icon="invoicesPaid" stroke="#1BCA8E"/>
              <span><h2 class="inline-text">Invoiced</h2></span>
            </div>
            <card-column :items="invoiced" component="QuoteCard" v-if="invoiced.length"/>
            <div class="box no-border bunya-box" v-else>
              <p>No invoiced quotes.</p>
            </div>
          </div>
          <div class="column is-4">
            <div class="title-padding">
              <icon icon="message" stroke="#FF8B41"/>
              <span><h2 class="inline-text">Issued or Accepted</h2></span>
            </div>
            <card-column :items="issued" component="QuoteCard" v-if="issued.length"/>
            <div class="box no-border bunya-box" v-else>
              <p>No issued or accepted quotes.</p>
            </div>
          </div>
          <div class="column is-4">
            <div class="title-padding">
              <icon icon="invoicesDraft" stroke="#D1D1D1"/>
              <span><h2 class="inline-text">Draft</h2></span>
            </div>
            <card-column :items="draft" component="QuoteCard" v-if="draft.length"/>
          </div>
        </div>
      </div>
      <!-- for mobile -->
      <div class="mobile-invoice-information-area is-hidden-desktop">
        <div class="level is-mobile no-margin-bottom">
          <div class="level-item">
            <a :class="{ 'invoice-section': true, active: isInvoicedSelected }" @click="selectedSection = 'invoiced'">
              <div class="title-padding-mobile">
                <icon icon="invoicesPaid" width="24" height="24"/>
                <span><h3 class="inline-text">Invoiced</h3></span>
              </div>
            </a>
          </div>
          <div class="level-item">
            <a :class="{ 'invoice-section': true, active: isIssuedSelected }" @click="selectedSection = 'issued'">
              <div class="title-padding-mobile">
                <icon icon="message" width="24" height="24"/>
                <span><h3 class="inline-text">Issued</h3></span>
              </div>
            </a>
          </div>
          <div class="level-item">
            <a :class="{ 'invoice-section': true, active: isDraftSelected }" @click="selectedSection = 'draft'">
              <div class="title-padding-mobile">
                <icon icon="invoicesDraft" width="24" height="24" stroke="#D1D1D1"/>
                <span><h3 class="inline-text">Draft</h3></span>
              </div>
            </a>
          </div>
        </div>
        <card-column :items="selectedSectionQuotes" component="QuoteCard"/>
      </div>
    </div>
  </section>
</template>

<script>

import SubpageHeader from '@/components/SubpageHeader'
import CardColumn from '@/components/CardColumn'
import Icon from '@/components/Icons/Icon'

export default {
  name: 'Quotes',
  components: {
    SubpageHeader,
    CardColumn,
    Icon
  },
  computed: {
    showOnboarding () {
      if (this.isLoading) {
        return false
      }
      if (this.invoiced.length > 0) {
        return false
      }
      if (this.issued.length > 0) {
        return false
      }
      if (this.draft.length > 0) {
        return false
      }
      return true
    },
    primaryAction () {
      return {
        name: 'Add Quote',
        emit: 'add'
      }
    },
    invoiced () {
      return this.$store.getters['quotes/invoiced']
    },
    issued () {
      return this.$store.getters['quotes/issued']
    },
    draft () {
      return this.$store.getters['quotes/draft']
    },
    isInvoicedSelected () {
      return this.selectedSection === 'invoiced'
    },
    isIssuedSelected () {
      return this.selectedSection === 'issued'
    },
    isDraftSelected () {
      return this.selectedSection === 'draft'
    },
    selectedSectionQuotes () {
      if (this.isInvoicedSelected) {
        return this.invoiced
      }
      if (this.isIssuedSelected) {
        return this.issued
      }
      if (this.isDraftSelected) {
        return this.draft
      }
      return []
    }
  },
  data () {
    return {
      isLoading: false,
      selectedSection: 'invoiced'
    }
  },
  methods: {
    goToQuoteCreate () {
      this.$router.push({ name: 'quotes-create' })
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('quotes/fetch')
      .then(() => {
        this.isLoading = false
      })
      .catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  }
}
</script>
