<template>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="icon_arrow_expand_18" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M15.231197,5.30294276 C15.636026,4.89917457 16.2912042,4.89899645 16.6962527,5.30254448 L16.6962527,5.30254448 C17.1006814,5.7080284 17.0998249,6.36084812 16.6966497,6.76296685 L16.6966497,6.76296685 L9.75794321,13.6834911 L9.75794321,13.6834911 L9.7424745,13.6966687 L9.7424745,13.6966687 C9.74134732,13.6978023 9.74021747,13.6989333 9.73908497,13.7000616 C9.33741168,14.1002419 8.68785036,14.1005589 8.28578665,13.7007708 L1.30092584,6.75544388 C0.901296287,6.3580762 0.899462675,5.71198229 1.29683035,5.31235273 L1.30021989,5.30895989 C1.70189318,4.90877956 2.35145449,4.90846255 2.75351821,5.30825061 L8.999,11.518 Z" id="Combined-Shape" fill="#AAAAAA"></path>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconArrowExpand',
  props: ['stroke', 'fill']
}

</script>
