<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20 14C20 11.9228 20 8.15437 20 4C20 2.896 19.104 2 18 2H6C4.895 2 4 2.896 4 4V22.009L6.666 20.009L9.333 22.009L10.0634 21.4613" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16 11.5H8" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16 7.5H8" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 15.5H9H8" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20 18L15.875 22L14 20.1818" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>

export default {
  name: 'IconInvoicesPaid',
  props: ['stroke', 'fill']
}

</script>
