<template>
  <tr>
    <td class="vertical-center">☰</td>
    <td class="vertical-top input-td" v-for="(field, fieldIndex) in fields" v-bind:key="fieldIndex">
      <data-editor-field-textarea v-if="field.type === 'text'" :value="item[field.name]"></data-editor-field-textarea>
      <data-editor-field-decimal v-if="field.type === 'decimal'" :value="item[field.name]" :minPrecision="field.options.minPrecision" :maxPrecision="field.options.maxPrecision"></data-editor-field-decimal>
      <data-editor-field-select v-if="field.type === 'select'" :options="field.options" :value="item[field.name]"></data-editor-field-select>
      <data-editor-field-info v-if="field.type === 'info'">{{ item[field.name] }}</data-editor-field-info>
    </td>
    <td class="vertical-top">🗑</td>
  </tr>
</template>

<style lang="scss" scoped>

td {

  padding: 0;
  margin: 0;

  select {
    border: none;
    outline: none;
    background-color: #FFF;
  }
  input {
    border: none;
    outline: none;
  }
  span {
    width: 100%;
    outline: none;
    border: none;
  }
  span:focus {
    outline: none;
  }
}

.vertical-center {
  vertical-align:middle
}

.vertical-top {
  vertical-align:top;
}

</style>

<script>

import DataEditorFieldTextarea from './DataEditorFieldTextarea.vue'
import DataEditorFieldDecimal from './DataEditorFieldDecimal.vue'
import DataEditorFieldSelect from './DataEditorFieldSelect.vue'
import DataEditorFieldInfo from './DataEditorFieldInfo.vue'

export default {
  components: { DataEditorFieldTextarea, DataEditorFieldDecimal, DataEditorFieldSelect, DataEditorFieldInfo },
  name: 'data-editor-field',
  props: ['item', 'fields']
}

</script>
