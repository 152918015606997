<template>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_sml_logout_18</title>
    <g id="icon_sml_logout_18" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <path d="M7,16 L3.66666667,16 C2.74619208,16 2,15.3035541 2,14.4444444 L2,3.55555556 C2,2.69644594 2.74619208,2 3.66666667,2 L7,2" id="Path" :stroke="stroke" stroke-width="2"></path>
      <polyline id="Path" :stroke="stroke" stroke-width="2" points="12 13 16 9 12 5"></polyline>
      <line x1="15.75" y1="9" x2="6.75" y2="9" id="Path" :stroke="stroke" stroke-width="2"></line>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconExpand',
  props: ['stroke', 'fill']
}

</script>
