<template>
  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.14251 1.42917C8.53091 0.78183 9.46909 0.781829 9.85749 1.42917L17.0913 13.4855C17.4912 14.152 17.0111 15 16.2338 15H1.76619C0.988894 15 0.508782 14.152 0.908696 13.4855L8.14251 1.42917ZM9.96249 4.99985C9.98297 4.45382 9.54587 4.00001 8.99946 4.00001C8.45346 4.00001 8.01651 4.45318 8.0364 4.99881L8.19031 9.222C8.20613 9.65619 8.56271 10 8.99719 10C9.43133 10 9.78776 9.65671 9.80404 9.22287L9.96249 4.99985ZM9 11C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13C8.44771 13 8 12.5523 8 12C8 11.4477 8.44771 11 9 11Z" :fill="stroke"/>
  </svg>
</template>

<script>

export default {
  name: 'IconAlert',
  props: ['stroke', 'fill']
}

</script>
