import Vue from 'vue'
import Vuex from 'vuex'
import each from 'lodash/each'
import account from '@/stores/account'
import albums from '@/stores/albums'
import appointments from '@/stores/appointments'
import cancellationTokens from '@/stores/cancellationTokens'
import clients from '@/stores/clients'
import currencies from '@/stores/currencies'
import expenses from '@/stores/expenses'
import invoices from '@/stores/invoices'
import languages from '@/stores/languages'
import notes from '@/stores/notes'
import settings from '@/stores/settings'
import quotes from '@/stores/quotes'
import reports from '@/stores/reports'
import taxRates from '@/stores/taxRates'
import tasks from '@/stores/tasks'
import user from '@/stores/user'
import Http from '@/http'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    albums,
    appointments,
    cancellationTokens,
    clients,
    currencies,
    expenses,
    invoices,
    languages,
    notes,
    settings,
    taxRates,
    quotes,
    reports,
    tasks,
    user
  },
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,

    /* NavBar */
    isNavBarVisible: true,

    /* Aside Menu */
    isAsideVisible: true,
    isAsideExpanded: false,
    isAsideMobileExpanded: false,

    /* Aside Right */
    isAsideRightVisible: false,
    asideRightMode: 'updates',

    /* FooterBar */
    isFooterBarVisible: true,

    /* Session */
    xsrfToken: null,

    /* SettingsModal */
    isSettingsModalOpen: false

  },
  mutations: {

    /* Session */
    setXSRFToken: (state, xsrfToken) => {
      state.xsrfToken = xsrfToken
    },

    /* Settings */
    setSettingsModalOpen: (state, isOpen) => {
      state.isSettingsModalOpen = isOpen
    },

    /* User Data */
    setUser (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
    },

    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* Full Page mode */
    fullPage (state, payload) {
      state.isNavBarVisible = !payload
      state.isAsideVisible = !payload
      state.isFooterBarVisible = !payload

      each(['has-aside-left', 'has-navbar-fixed-top'], htmlClass => {
        if (payload) {
          document.documentElement.classList.remove(htmlClass)
        } else {
          document.documentElement.classList.add(htmlClass)
        }
      })
    },

    /* Aside Desktop */
    asideStateToggle (state, payload = null) {
      const htmlAsideClassName = 'has-aside-expanded'

      let isExpand

      if (payload !== null) {
        isExpand = payload
      } else {
        isExpand = !state.isAsideExpanded
      }

      if (isExpand) {
        document.documentElement.classList.add(htmlAsideClassName)
      } else {
        document.documentElement.classList.remove(htmlAsideClassName)
      }

      state.isAsideExpanded = isExpand
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    },

    /* Aside Right */
    asideRightToggle (state, payload = null) {
      const htmlClassName = 'has-aside-right'

      let isShow = true

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideRightVisible
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideRightVisible = isShow
    }
  },
  actions: {
    refreshSanctumToken: (context) => {
      const csrfCookieUrl = process.env.VUE_APP_ROOT_API + 'sanctum/csrf-cookie'
      Http.get(csrfCookieUrl)
        .then(response => {
          const xsrfToken = response.config.headers['X-XSRF-TOKEN']
          context.commit('setXSRFToken', xsrfToken)
        })
    },
    setXSRFToken: (context, xsrfToken) => {
      context.commit('setXSRFToken', xsrfToken)
    },
    setUser: (context, user) => {
      context.commit('setUser', user)
    },
    openSettingsModal: (context) => {
      context.commit('setSettingsModalOpen', true)
    },
    closeSettingsModal: (context) => {
      context.commit('setSettingsModalOpen', false)
    }
  },
  getters: {
    xsrfToken: (state) => {
      return state.xsrfToken
    },
    isSettingsModalOpen: (state) => {
      return state.isSettingsModalOpen
    }
  }
})
