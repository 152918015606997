<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>20_create_invoice</title>
    <g id="20_create_invoice" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle id="Oval" fill="#FFFFFF" cx="10" cy="10" r="10"></circle>
      <g id="Group-18" transform="translate(3.000000, 2.000000)" stroke="#ABBAC6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
        <path d="M13.3366667,1.84564718 L13.3333333,1.66666667 C13.3333333,0.746666667 12.5866667,0 11.6666667,0 L1.66666667,0 C0.745833333,0 0,0.746666667 0,1.66666667 L0,16.6741667 L2.22166667,15.0075 L4.44416667,16.6741667 L6.66666667,15.0075 L8.88916667,16.6741667 L11.1108333,15.0075 L13.3333333,16.6741667 C13.3333333,13.0570504 13.3333333,12.2680745 13.3333333,10.819753" id="Path"></path>
        <line x1="9.33333333" y1="7.91666667" x2="3.33333333" y2="7.91666667" id="Path"></line>
        <line x1="9.33333333" y1="4.58333333" x2="3.33333333" y2="4.58333333" id="Path"></line>
        <polyline id="Path" points="5 11.25 4.16666667 11.25 3.33333333 11.25"></polyline>
      </g>
      <g id="Group-22" transform="translate(13.836667, 5.833333)" stroke="#2B3A58" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
        <line x1="2.5" y1="0.5" x2="2.5" y2="4.5" id="Line"></line>
        <line x1="2.5" y1="0.5" x2="2.5" y2="4.5" id="Line" transform="translate(2.500000, 2.500000) rotate(-90.000000) translate(-2.500000, -2.500000) "></line>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconDropdownCreateInvoice',
  props: ['stroke', 'fill']
}

</script>
