<template>
  <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="4" width="22" height="16" rx="2" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 10H23" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 15H11.1143" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.6191 15H17.9931" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_creditcard_24</title>
    <g id="icon_creditcard_24" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="credit-card" transform="translate(1.000000, 4.000000)" stroke="#AAAAAA" stroke-width="2">
            <rect id="Rectangle" x="0" y="0" width="22" height="16" rx="2"></rect>
            <line x1="0" y1="5" x2="22" y2="5" id="Path"></line>
            <line x1="5" y1="10" x2="10" y2="10" id="Stroke-137"></line>
            <line x1="15" y1="10" x2="17" y2="10" id="Stroke-138"></line>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconCreditCard',
  props: ['stroke', 'fill']
}

</script>
