<template>
  <!-- <svg width="17" height="21" viewBox="0 0 17 21" :fill="fill" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3077 1.30768H2.92306C1.90346 1.30768 1.0769 2.13423 1.0769 3.15383V17.9231C1.0769 18.9427 1.90346 19.7692 2.92306 19.7692H14C15.0196 19.7692 15.8461 18.9427 15.8461 17.9231V6.84614L10.3077 1.30768Z" :stroke="stroke" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.3076 1.30768V6.84614H15.8461" :stroke="stroke" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.1538 11.4615H4.76917" :stroke="stroke" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.1538 15.1539H4.76917" :stroke="stroke" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.61532 7.76922H5.69224H4.76917" :stroke="stroke" stroke-width="1.38462" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_new_note_20</title>
    <g id="icon_new_note_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Group-20" transform="translate(3.000000, 1.000000)" stroke="#AAAAAA" stroke-width="2">
            <path d="M9,0 L1.75,0 C0.783501688,0 0,0.80588745 0,1.8 L0,16.2 C0,17.1941125 0.783501688,18 1.75,18 L12.25,18 C13.2164983,18 14,17.1941125 14,16.2 L14,5 L9,0 Z" id="Path"></path>
            <polyline id="Path" points="9 0 9 5 14 5"></polyline>
            <line x1="10.4978902" y1="9" x2="3.5" y2="9" id="Path"></line>
            <line x1="10.4978902" y1="13" x2="3.5" y2="13" id="Path"></line>
            <line x1="5.5" y1="5" x2="3.5" y2="5" id="Path"></line>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconTextFile',
  props: ['stroke', 'fill']
}

</script>
