<template>
  <div>
    <textarea :style="'height: ' + this.height + 'px'" class="text-editor-box" v-model="localValue" @change="changed" @keyup="updateTextHeight"></textarea>
    <div :style="'width: ' + this.width + 'px'" class="text-calculation">{{ localValue }}</div>
  </div>

</template>

<style lang="scss" scoped>

.text-calculation {
  padding: 10px;
  font-size: 20px;
  min-height: 50px;
  font-weight: 400;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  position: absolute;
  top: -1500px;
  left: -1500px;
  line-height: 30px;
  letter-spacing: 0.2pt;
  white-space: pre-wrap;
}

textarea {
  overflow-wrap: break-word;
  width: 100%;
  background: transparent;
  appearance: none;
  margin: 0;
  outline: 0;
  padding: 10px;
  min-height: 50px;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2pt;
  overflow: hidden;
  border: none;
  resize: none;
}

</style>

<script>

export default {
  name: 'data-editor-field-textarea',
  data () {
    return {
      localValue: '',
      width: 100,
      height: 60
    }
  },
  methods: {
    changed () {
      this.$emit('input', this.localValue)
      this.updateTextHeight()
    },
    updateTextHeight () {
      let height = this.$el.querySelector('.text-calculation').offsetHeight
      const numberOfLineBreaksAtEndOfInput = this.countLineBreaksAtEndOfInput()
      height += (numberOfLineBreaksAtEndOfInput * 30) // line height * number of line breaks
      this.height = height
    },
    countLineBreaksAtEndOfInput () {
      const text = this.localValue
      // eslint-disable-next-line
      const matches = text.match('(\\S\\n)')
      if (matches) {
        return matches.length - 1
      }
      return 0
    },
    updateTextWidth () {
      const width = this.$el.querySelector('textarea').offsetWidth
      this.width = width
    }
  },
  mounted () {
    this.updateTextWidth()
    this.$nextTick(() => {
      this.updateTextHeight()
    })
  },
  created () {
    this.localValue = this.value
  },
  props: ['value']
}

</script>
