<template>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>icon_sml_invoices_18</title>
      <g id="icon_sml_invoices_18" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
          <path d="M12.99975,14.5006747 L15,16 L15,2.4993253 C15,1.67169774 14.328,1 13.5,1 L4.5,1 C3.67125,1 3,1.67169774 3,2.4993253 L3,16 L4.9995,14.5006747 L6.99975,16 L9,14.5006747 L11.00025,16 L12.99975,14.5006747 Z" id="Stroke-1" stroke="#AAAAAA" stroke-width="2"></path>
          <line x1="12" y1="8.625" x2="6" y2="8.625" id="Path" stroke="#AAAAAA" stroke-width="2"></line>
          <line x1="12" y1="5.625" x2="6" y2="5.625" id="Path" stroke="#AAAAAA" stroke-width="2"></line>
          <polyline id="Path" stroke="#AAAAAA" stroke-width="2" points="7.5 11.625 6.75 11.625 6 11.625"></polyline>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconSettingsInvoice',
  props: ['stroke', 'fill']
}

</script>
