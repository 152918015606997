<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      :title="'Reports'"
    ></subpage-header>
    <div class="mobile-settings-information-area subpage-information-area-margin has-bunya-max-width">
      <div class="box bunya-box">
        <div class="columns">
          <div class="column settings-mobile-content">
            <transition name="fade" mode="out-in">
              <router-view/>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SubpageHeader from '@/components/SubpageHeader'

export default {
  name: 'Tables',
  components: {
    SubpageHeader
  },
  data () {
    return {
      isHiddenList: true,
      isClientModalActive: false,
      primaryAction: null
    }
  },
  methods: {
    reportCreate () {
      this.$router.push('/reports/create')
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1023px) {
.bunya-box {
  background-color: crimson;
  margin-top: 65px !important;
}
}
.subpage-information-area-margin {
  //background-color: crimson;
  margin-top: -150px;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  //background-color: darkorange !important;
}
.columns {
    align-content: center;
    margin: 0px !important;
  }
</style>
