<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16 4H18C19.1046 4 20 4.89543 20 6V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V6C4 4.89543 4.89543 4 6 4H8V3C8 2.44772 8.44772 2 9 2H15C15.5523 2 16 2.44772 16 3V4Z"
          :fill="fill"/>
    <path
      d="M16 4H18C19.1046 4 20 4.89543 20 6V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V6C4 4.89543 4.89543 4 6 4H8"
      :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="8" y="2" width="8" height="4" rx="1" :stroke="stroke" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M16 13.5H8" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 9.5H8" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 17.5H9H8" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>

export default {
  name: 'IconClipboard',
  props: ['stroke', 'fill']
}

</script>
