<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>20_book_appointment</title>
    <g id="20_book_appointment" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle id="Oval" fill="#FFFFFF" cx="10" cy="10" r="10"></circle>
      <path d="M17,12.6583898 L17,15.2 C17,16.1941125 16.1941125,17 15.2,17 L4.8,17 C3.80588745,17 3,16.1941125 3,15.2 L3,4.8 C3,3.80588745 3.80588745,3 4.8,3 L15.2,3 C15.7805801,3 16.2969608,3.27487014 16.6261066,3.70157479" id="Path" stroke="#ABBAC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      <line x1="17" y1="6.155" x2="17" y2="10.155" id="Line" stroke="#2B3A58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
      <line x1="17" y1="6.155" x2="17" y2="10.155" id="Line" stroke="#2B3A58" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" transform="translate(17.000000, 8.155000) rotate(-90.000000) translate(-17.000000, -8.155000) "></line>
      <line x1="7" y1="1.5" x2="7" y2="4.5" id="Path" stroke="#ABBAC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
      <line x1="13" y1="1.5" x2="13" y2="4.5" id="Path" stroke="#ABBAC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
      <line x1="3" y1="8.15" x2="12.6428572" y2="8.16" id="Path" stroke="#ABBAC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></line>
      <polyline id="Path" stroke="#ABBAC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" points="12.6428572 10.9285714 9.54910713 13.9285714 8.14285714 12.5649351"></polyline>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconDropdownCreateAppointment',
  props: ['stroke', 'fill']
}

</script>
