<template>
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_quotes_30</title>
    <g id="icon_quotes_30" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g id="Group" transform="translate(4.000000, 1.000000)" :stroke="stroke" stroke-width="2">
        <g id="clipboard" transform="translate(0.000000, 3.000000)" :fill="fill">
          <path d="M19.25,0 C20.7687831,0 22,1.24365347 22,2.77777778 L22,22.2222222 C22,23.7563465 20.7687831,25 19.25,25 L2.75,25 C1.23121694,25 0,23.7563465 0,22.2222222 L0,2.77777778 C0,1.24365347 1.23121694,0 2.75,0 L19.25,0 Z" id="Path"></path>
        </g>
        <path d="M6.25,0 L15.75,0 C16.4403559,-1.26816328e-16 17,0.559644063 17,1.25 L17,4.75 C17,5.44035594 16.4403559,6 15.75,6 L6.25,6 C5.55964406,6 5,5.44035594 5,4.75 L5,1.25 C5,0.559644063 5.55964406,3.48860933e-16 6.25,0 Z" id="Rectangle" fill="#FFFFFF"></path>
        <line x1="17" y1="17" x2="5" y2="17" id="Path"></line>
        <line x1="17" y1="12" x2="5" y2="12" id="Path"></line>
        <path d="M8,22 L5,22 L8,22 Z" id="Path"></path>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconTimelineQuote',
  props: ['stroke', 'fill']
}

</script>
