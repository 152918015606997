<template>
  <!-- Turning off settings
  <div class="settings-header is-hidden-touch">
    <div class="container has-bunya-max-width brand-background-header settings-header-margin" :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">
      <div class="line"></div>
      <div class="header-content with-margin-top">
        <div class="information-box">
          <div class="settings-logo">
            <img :src="account.brand_logo_url" v-if="account.brand_logo_url" class="logo-image">
            <div class="text" v-if="!account.brand_logo_url">
              <h1 class="rubik">{{ account.name }}</h1>
            </div>
          </div>
        </div>
        <div class="information-box is-hidden-touch">
          <div class="item-1">
            <h1 class="rubik"><growing-number :value="account.statistics.clients"/></h1>
            <h5 class="light-blue">Clients</h5>
          </div>
          <div class="vertical-line"></div>
          <div class="item-2">
            <h1 class="rubik"><growing-number :value="account.statistics.appointments"/></h1>
            <h5 class="light-blue">Appointments</h5>
          </div>
          <div class="vertical-line"></div>
          <div class="item-2">
            <h1 class="rubik"><growing-number :value="account.statistics.annualSales"/></h1>
            <h5 class="light-blue">Sales this year</h5>
          </div>
          <div class="vertical-line"></div>
          <div class="item-last">
            <h6 class="light-blue is-uppercase">Account Status</h6>
            <h5 class="status">Subscribed</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  -->

  <!-- settings header for mobile
  <div class="settings-header is-hidden-desktop" v-if="account">
    <div class="container has-bunya-max-width brand-background-header settings-header-margin" :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">
      <nav-bar-mobile></nav-bar-mobile>
      <div class="level is-mobile settings-wrapper">
        <div class="information-box">
          <div class="settings-logo">
            <img :src="account.brand_logo_url" v-if="account.brand_logo_url" class="logo-image">
            <div class="text" v-if="!account.brand_logo_url">
              <h1 class="rubik">{{ account.name }}</h1>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="information-box">
              <div class="item-1">
                <h1 class="rubik">
                  <growing-number :value="account.statistics.clients"/>
                </h1>
                <h5 class="light-blue">Clients</h5>
              </div>
              <div class="vertical-line"></div>
              <div class="item-2">
                <h1 class="rubik">
                  <growing-number :value="account.statistics.appointments"/>
                </h1>
                <h5 class="light-blue">Appointments</h5>
              </div>
              <div class="vertical-line"></div>
              <div class="item-2">
                <h1 class="rubik">
                  <growing-number :value="account.statistics.annualSales"/>
                </h1>
                <h5 class="light-blue">Sales this year</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <section class="section has-bunya-max-width">
  <subpage-header
      :title=" ' Settings'"
    ></subpage-header>
  <div class="mobile-settings-information-area subpage-information-area-margin has-bunya-max-width">
    <div class="box bunya-box">
      <div class="columns">
        <div class="column settings-mobile-content">
          <transition name="fade" mode="out-in">
            <router-view/>
          </transition>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>

import SubpageHeader from '@/components/SubpageHeader'

export default {
  name: 'Settings',
  components: { SubpageHeader },
  data () {
    return {
      selectedComponent: 'AccountSetup',
      isModalActive: false
    }
  },
  computed: {
    backgroundImageUrl () {
      if (!this.account) {
        return ''
      }
      return this.account.brand_background_url
    },
    account () {
      return this.$store.getters['account/account']
    },
    isSettingsModalOpen: {
      get () {
        return this.$store.getters.isSettingsModalOpen
      },
      set () {
        this.$store.dispatch('closeSettingsModal')
      }
    }
  },
  mounted () {
    this.$store.dispatch('account/fetch')
  },
  methods: {
    openModal () {
      this.$store.dispatch('openSettingsModal')
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 1023px) {
.bunya-box {
  //background-color: crimson;
  margin-top: 65px !important;
}
}
.fullwidth-header-sub {
    //height: 130px;
  }

  .brand-background-header {
    box-shadow:inset 0 0 0 3000px rgba(255, 255, 255, 0.3);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width:100%;
    height:100%;
  }

  .brand-background-header-overlay {
    background-color: rgba(90,90,90,0.9);
    width: 100%;
  }

  .container {
  background-color: #1BCA8E20;
  }

/* Marc Trying to center */
  .columns {
    align-content: center;
    margin: 0px !important;
  }

  .mobile-header-columns {
    margin-top: -8px;
  }

  .mobile-settings-title {
    padding-left: 15px;
  }

  .has-hover-margin {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    background-color: white !important;
  }

  .active {
    background-color: #1BCA8E20;
    border-radius: 5px;
  }

  .white-background {
    background: white;
  }
  .with-margin-top{
    margin-top: 25px;
  }
  //MARC Padding left right setting header
  .level {
    padding-left: 20px;
    padding-right: 20px;
}
  .settings-header {
    /* background: #EEE;
    /* TODO Set the background image in Vue. */
    /*background-image: url(../assets/background-wireman.jpg);
    background-position: center;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    //margin-left: -24px;
    //margin-right: -24px;
    margin-top: -50px;
    height: 180px; //new line
  }
  .subpage-information-area-margin {
    margin-top: -150px;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    //background-color: darkorange !important;
  }
  .header-content {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .settings-logo {
    // padding-left: 60px;
    margin: 30px;
    display: flex;
    align-items: center;
    .text {
      margin-left: 20px;
    }
  }
  .information-box{
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #ffffffee;
    border-radius: 15px;
    height: 100px;
    //MARC Margin fix
    margin-top: 25px;
    margin-bottom: 0px;
    margin-right: 10px;
    //MARC FIXING SETTINGS
    //padding-left: 100%;
    //padding-right: 100%;
    //margin-left: 10px;
    //margin-right: 10px;
    .item{
      padding: 15px;
    }
    .item-1{
      padding-left: 45px;
      padding-right: 45px;
    }
    .item-2{
      padding-left: 23px;
      padding-right: 23px;
    }
    .item-last{
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .status {
    background-color: #1BCA8E;
    margin-top: 5px;
    border-radius: 30px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 30px;
  }
  .vertical-line {
    background-color: #2d39593f;
    width: 2px;
    height: 60px;
    border-radius: 2px;
  }

  .logo-image {
    max-width: 200px;
    max-height: 100px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

</style>
