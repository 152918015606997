<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 9.5C1 8.67157 1.67157 8 2.5 8H21.5C22.3284 8 23 8.67157 23 9.5V20.5C23 21.3284 22.3284 22 21.5 22H2.5C1.67157 22 1 21.3284 1 20.5V9.5Z" :fill="fill" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 6.5C1 5.67157 1.67157 5 2.5 5H21.5C22.3284 5 23 5.67157 23 6.5V17.5C23 18.3284 22.3284 19 21.5 19H2.5C1.67157 19 1 18.3284 1 17.5V6.5Z" :fill="fill" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 3.5C1 2.67157 1.67157 2 2.5 2H21.5C22.3284 2 23 2.67157 23 3.5V14.5C23 15.3284 22.3284 16 21.5 16H2.5C1.67157 16 1 15.3284 1 14.5V3.5Z" :fill="fill" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12.25 4.8999V13.1499" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.125 6.3999H11.3125C10.5876 6.3999 10 6.98753 10 7.7124C10 8.43728 10.5876 9.0249 11.3125 9.0249H13.1875C13.9124 9.0249 14.5 9.61253 14.5 10.3374C14.5 11.0623 13.9124 11.6499 13.1875 11.6499H10" :stroke="stroke" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 6C3.20914 6 5 4.20914 5 2" :stroke="stroke" stroke-width="2"/>
  <path d="M23 6C20.7909 6 19 4.20914 19 2" :stroke="stroke" stroke-width="2"/>
  <path d="M1 12C3.20914 12 5 13.7909 5 16" :stroke="stroke" stroke-width="2"/>
  <path d="M23 12C20.7909 12 19 13.7909 19 16" :stroke="stroke" stroke-width="2"/>
  </svg>

</template>

<script>

export default {
  name: 'IconIncome',
  props: ['stroke', 'fill']
}

</script>
