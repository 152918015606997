<template>
  <div :class="containerStyle">
    <div class="icon-container flex-child">
      <div class="halo">
        <div class="core"></div>
      </div>
    </div>
    <div class="text-container flex-child"><span>{{ text }}</span></div>
  </div>
</template>

<style lang="scss" scoped>

@import "../scss/_theme-default.scss";

.flex-container {
  display: flex;
  align-items: center;
}

.flex-child {
  margin-right: 5px;
}

.icon-container {
  width: 1rem;
  height: 1rem;
}

.halo {
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
  background-color: $dark-muted;
}

.core {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  position: relative;
  top: 0.25rem;
  left: 0.25rem;
  background-color: $dark;
}

.text-container {
  color: $dark;
  text-transform: uppercase;
  font-weight: 600;
  flex: 9;
}

.is-primary {
  .core { background-color: $primary; }
  .halo { background-color: $primary-muted; }
  .text-container { color: $primary; }
}

.is-info {
  .core { background-color: $info; }
  .halo { background-color: $info-muted; }
  .text-container { color: $info; }
}

.is-success {
  .core { background-color: $success; }
  .halo { background-color: $success-muted; }
  .text-container { color: $success; }
}

.is-warning {
  .core { background-color: $warning; }
  .halo { background-color: $warning-muted; }
  .text-container { color: $warning; }
}

.is-danger {
  .core { background-color: $danger; }
  .halo { background-color: $danger-muted; }
  .text-container { color: $danger; }
}

</style>

<script>

export default {
  name: 'StatusLabel',
  computed: {
    containerStyle () {
      return ['flex-container', this.labelStyle]
    }
  },
  props: ['labelStyle', 'text']
}

</script>
