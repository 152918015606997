<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>20_create_quote</title>
    <g id="20_create_quote" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle id="Oval" fill="#FFFFFF" cx="10" cy="10" r="10"></circle>
      <g id="Group-17" transform="translate(3.000000, 2.000000)" stroke="#ABBAC6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
        <path d="M10,1.66666667 L11.6666667,1.66666667 C12.5871412,1.66666667 13.3333333,2.41285875 13.3333333,3.33333333 M13.3333333,12.3208134 C13.3333333,13.2015253 13.3333333,12.9414546 13.3333333,15 C13.3333333,15.9204746 12.5871412,16.6666667 11.6666667,16.6666667 L1.66666667,16.6666667 C0.746192084,16.6666667 0,15.9204746 0,15 L0,3.33333333 C0,2.41285875 0.746192084,1.66666667 1.66666667,1.66666667 L3.33333333,1.66666667" id="Shape"></path>
        <rect id="Rectangle" x="3.33333333" y="0" width="6.66666667" height="3.33333333" rx="0.833333333"></rect>
        <line x1="9.33333333" y1="9.58333333" x2="3.33333333" y2="9.58333333" id="Path"></line>
        <line x1="9.33333333" y1="6.25" x2="3.33333333" y2="6.25" id="Path"></line>
        <polyline id="Path" points="5 12.9166667 4.16666667 12.9166667 3.33333333 12.9166667"></polyline>
      </g>
      <g id="Group-22" transform="translate(13.836667, 7.333333)" stroke="#2B3A58" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
        <line x1="2.5" y1="0.5" x2="2.5" y2="4.5" id="Line"></line>
        <line x1="2.5" y1="0.5" x2="2.5" y2="4.5" id="Line" transform="translate(2.500000, 2.500000) rotate(-90.000000) translate(-2.500000, -2.500000) "></line>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconDropdownCreateQuote',
  props: ['stroke', 'fill']
}

</script>
