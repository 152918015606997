<template>
  <div class="has-text-right">
    <div :class="[{'is-active': isActive }, 'dropdown', 'is-fullwidth']">
      <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
          <span @click="open">{{ selectedOptionName }}</span>
          <span class="icon is-small">
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <a @click="selectOption(option.id)" class="dropdown-item" v-for="(option, index) in options" v-bind:key="index">
            {{ option.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>

<script>

export default {
  name: 'data-editor-field-select',
  data () {
    return {
      localValue: null,
      active: false
    }
  },

  computed: {
    isActive () {
      return this.active
    },
    selectedOptionName () {
      const selectedOptionId = this.localValue
      if (!selectedOptionId) {
        return '-- SELECT --'
      }
      const selectedOptions = this.options.filter((option) => {
        return selectedOptionId === option.id
      })
      if (selectedOptions.length === 0) {
        return '-- SELECT --'
      }
      return selectedOptions[0].name
    }
  },
  methods: {
    open () {
      this.active = true
    },
    close () {
      this.active = false
    },
    changed () {
      this.$emit('input', this.localValue)
    },
    selectOption (optionId) {
      this.localValue = optionId
      this.close()
    }
  },
  mounted () {
    this.localValue = this.value
  },
  props: ['value', 'options']
}

</script>
