<template>
  <div class="de-container">
    <table class="table is-fullwidth is-bordered">
      <thead>
      <tr>
        <th></th>
        <data-editor-header v-for="(field, index) in fields"
          :width="field.width"
          v-bind:key="index"
          :name="field.label"
          :abbreviation="field.abbreviation"
        />
        <th></th>
      </tr>
      </thead>
      <tbody>
        <data-editor-field v-for="(item, index) in items" v-bind:key="index" :item="item" :fields="fields"></data-editor-field>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>

.de-container {
  padding: 10px;

  .table {
  }

}

</style>

<script>

import DataEditorHeader from '@/components/DataEditor/DataEditorHeader.vue'
import DataEditorField from '@/components/DataEditor/DataEditorField.vue'

export default {
  components: { DataEditorHeader, DataEditorField },
  name: 'data-editor',
  data () {
    return {
      headers: ['Item', 'Quantity', 'Unit Price', 'Tax Rate', 'Item Total'],
      items: [
        { description: 'The first item and now we want the text size to grow. But does it grow? It does not. The stupid box is doing something stupid.', quantity: 10, unitPrice: 45.45, taxRateId: 2, total: '454.50' },
        { description: 'The second item', quantity: 3, unitPrice: 33.3, taxRateId: null, total: '99.90' },
        { description: 'The third item', quantity: 3, unitPrice: 33.3, taxRateId: null, total: '99.90' }
      ],
      fields: [
        { width: 30, align: 'left', name: 'description', abbreviation: 'Item', label: 'Item Description', type: 'text', options: null },
        { width: 10, align: 'right', name: 'quantity', abbreviation: 'Qty.', label: 'Quantity', type: 'decimal', options: { minPrecision: 0, maxPrecision: 4 } },
        { width: 10, align: 'right', name: 'unitPrice', abbreviation: 'Unit Price', label: 'Unit Price', type: 'decimal', options: { minPrecision: 2, maxPrecision: 2 } },
        { width: 20, align: 'right', name: 'taxRateId', abbreviation: 'Tax', label: 'Tax Rate', type: 'select', options: [{ id: 1, name: '21% VAT', rate: 0.21 }, { id: 2, name: 'VAT Exempt', rate: 0 }] },
        { width: 20, align: 'right', name: 'total', abbreviation: 'Total', label: 'Item Total', type: 'readonly', options: null }
      ]
    }
  }
  // props: ['headers', 'fields', 'items']
}

</script>
