<template>
  <div class="box bunya-box">
    <div v-on:click="showQuote">
      <div class="quote-content quote-click-target">
        <div class="level is-mobile component-level-margin quote-click-target">
          <div class="level-left">
            <div class="level-item">
              <div v-if="this.isDraft">
                <h6 :class="[quoteStatusClass, 'quote-click-target']">{{ quote.quote_number_formatted }}</h6>
              </div>
              <div v-else>
                <h6 class="has-opacity quote-click-target">{{ quote.quote_number_formatted }}</h6>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div v-if="isIssued">
              <div class="level-item dashboard-date quote-click-target">
                <h6 class="has-opacity"><b>ISSUED </b>
                  <span class="quote-click-target"> {{ quote.issued_at | moment('D MMM YYYY') }}</span></h6>
              </div>
            </div>
            <div v-else-if="this.isDraft">
              <div class="level-item">
                <h6 :class="['dashboard-date', 'quote-click-target', quoteStatusClass]">{{ quote.issued_at | moment('D MMM YYYY') }}</h6>
              </div>
            </div>
            <div v-else>
              <div class="level-item">
                <h6 class="dashboard-date has-opacity">{{ quote.issued_at | moment('D MMM YYYY') }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="level is-mobile quote-click-target">
          <div class="level-left">
            <div v-if="this.isDraft">
              <div class="block level-item">
                <h4 :class="[quoteStatusClass, 'quote-click-target', 'quote-client-name']">{{ quote.client.name }}</h4>
                <h4 class="no-line-height quote-click-target"><span :class="['amount-text', 'quote-click-target', quoteStatusClass]">{{ quote.amount | currency }}</span>
                </h4>
              </div>
            </div>
            <div v-else>
              <div class="block level-item">
                <h4 class="no-line-height has-dashboard-limit quote-click-target quote-client-name">{{ quote.client.name }}</h4>
                <h4 class="no-line-height quote-click-target"><span :class="['amount-text', 'quote-click-target', quoteStatusClass]">{{ quote.amount | currency }}</span>
                </h4>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <snowman-menu
                :items="quoteActions"
                v-on:quote-pdf-download="quotePDFDownload"
                v-on:quote-view="quoteView"
                :type="'with-hover'"
              ></snowman-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SnowmanMenu from '@/components/SnowmanMenu'
import SendMail from '@/components/SendMail'

export default {
  components: {
    SnowmanMenu
  },
  computed: {
    isInvoiced () {
      return (this.quote.finalized && this.quote.invoice_id !== null)
    },
    isIssued () {
      return (this.quote.finalized && this.quote.invoice_id === null)
    },
    isDraft () {
      return (this.quote.finalized === false)
    },
    quoteStatusClass () {
      if (this.isInvoiced) {
        return 'color-invoiced'
      }
      if (this.isIssued) {
        return 'color-issued'
      }
      if (this.isDraft) {
        return 'color-draft'
      }
      return ''
    },
    quoteActions () {
      if (!this.quote) {
        return []
      }
      return [
        {
          name: 'View Quote',
          description: 'Load the quote editor',
          icon: 'view',
          emit: 'quote-view'
        },
        {
          name: 'Download',
          description: 'Download as PDF',
          icon: 'copy',
          emit: 'quote-pdf-download'
        }
      ]
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    openCollapse () {
      this.isOpen = this.isOverdue
    },
    quotePDFDownload () {
      window.open(this.quote.pdf_link, '_blank')
    },
    quoteView () {
      this.$router.push({ name: 'quotes-show', params: { id: this.quote.id } })
    },
    showQuote (e) {
      if (e.target.classList.contains('quote-click-target')) {
        this.$router.push({ name: 'quotes-show', params: { id: this.quote.id } })
      }
    },
    showSendReminderPanel () {
      this.$buefy.modal.open({
        parent: this,
        component: SendMail,
        props: { quote: this.quote },
        hasModalCard: true,
        trapFocus: true
      })
    }
  },
  name: 'QuoteCard',
  props: ['quote']
}
</script>

<style lang="scss" scoped>

  @import "../scss/_theme-default.scss";

  .fix-overdue {
    .level-left {
      min-width: 0;
      word-break: break-word;
      flex-basis: 49%;

      .level-item {
        flex-shrink: 1;
        justify-content: flex-start;
        // padding: .5em .5em;
      }
    }

    .level-right {
      min-width: 0;
      word-break: break-word;
      flex-basis: 51%;

      .level-item {
        flex-shrink: 1;
        justify-content: flex-end;
        // padding: .5em .5em;
      }
    }
  }
  .has-limit{
    max-width: 220px;
  }
  .quotes-subtitle {
    font-size: 0.95em;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0.75px;
  }

  .quote-content:hover .quote-client-name  {
    color: $info;
    transition: all 0.5s;
  }

  .color-invoiced {
    color: #1bca8e;
  }

  .color-issued {
    color: #ff8b41;
  }

  .color-draft {
    color: #42424250;
  }

  .status-label {
    border-radius: 0 6px 0 6px;
    padding: 0.5rem;
    margin-top: -25px;
    margin-right: -20px;
    border: 0;
    box-shadow: none;
  }

  .status-label-overdue {
    background-color: #FF8B41;
  }

  .overdue-reminder {
    background-color: #fafafa;
    margin: 1.25rem -1.25rem -1.25rem;
    border-top: 1px solid #E3E3E370;
    border-bottom-left-radius: 7.5px;
    border-bottom-right-radius: 7.5px;
    padding: 4px 1.25rem 0.9375rem;
  }

  .overdue {
    background-color: #FF8B41;
    border-radius: 0 6px 0 6px;
    padding: 0.5rem;
    margin-top: -25px;
    margin-right: -20px;
    border: 0;
    box-shadow: none;
  }

  @media only screen and (max-width: 768px) {
    h4 {
      word-break: break-word;
      max-width: 138px;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
