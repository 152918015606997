<template>
  <!-- <svg width="24" height="24" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">

  <path d="M19 10V16C19 16.5523 18.5523 17 18 17H2C1.44772 17 1 16.5523 1 16V5C1 4.44772 1.44772 4 2 4H13" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M1 8H15" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M4 12.5H7" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M14 12.5H15" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M16.5 4H21.5" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M19 6.5V1.5" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>

  </svg> -->
  <svg width="23px" height="20px" viewBox="0 0 23 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_payment_20</title>
    <g id="icon_payment_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="Group" transform="translate(1.000000, 3.500000)" stroke="#AAAAAA" stroke-width="2">
            <path d="M18,6.5 L18,12.5 C18,13.0522847 17.5522847,13.5 17,13.5 L1,13.5 C0.44771525,13.5 0,13.0522847 0,12.5 L0,1.5 C0,0.94771525 0.44771525,0.5 1,0.5 L12,0.5" id="Path"></path>
            <line x1="-2.41501263e-13" y1="5" x2="14" y2="5" id="Line"></line>
            <line x1="3" y1="9.5" x2="6" y2="9.5" id="Line"></line>
            <line x1="13" y1="9.5" x2="14" y2="9.5" id="Line"></line>
        </g>
        <line x1="17" y1="4" x2="21" y2="4" id="Line-2" stroke="#AAAAAA" stroke-width="2"></line>
        <line x1="19" y1="6" x2="19" y2="2" id="Line-3" stroke="#AAAAAA" stroke-width="2"></line>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconRecordPayment',
  props: ['stroke', 'fill']
}

</script>
