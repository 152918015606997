<template>
  <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3.49988 9C3.22374 9 2.99988 8.77614 2.99988 8.5V1.82938L0.93444 3.88679C0.741058 4.07942 0.428256 4.07921 0.235127 3.88633C0.0412917 3.69273 0.0414982 3.37854 0.235588 3.1852L3.07598 0.355855C3.20704 0.225312 3.39293 0.183331 3.55926 0.229861C3.73041 0.177199 3.92428 0.218578 4.05974 0.35398L6.88895 3.1821C7.08417 3.37725 7.08411 3.69375 6.8888 3.88881C6.69371 4.08365 6.37766 4.08358 6.18266 3.88866L3.99988 1.70672V8.5C3.99988 8.77614 3.77602 9 3.49988 9Z" :fill="fill"/>
  </svg>
</template>

<script>

export default {
  name: 'IconArrowUp',
  props: ['stroke', 'fill']
}

</script>
