<template>
  <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.209 9.33947L12 1.3999L1.79096 9.33947H22.209Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13 6C13 6.552 12.553 7 12 7C11.447 7 11 6.552 11 6C11 5.448 11.447 5 12 5C12.553 5 13 5.448 13 6Z" fill="#AAAAAA"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.79096 19.3394H21.209H2.79096Z" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1.79096 22.3394H22.209V22.3394" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4.5 10V19" stroke="#AAAAAA" stroke-width="2" stroke-linecap="square"/>
  <path d="M19.5 10V19" stroke="#AAAAAA" stroke-width="2" stroke-linecap="square"/>
  <path d="M14.5 10V19" stroke="#AAAAAA" stroke-width="2" stroke-linecap="square"/>
  <path d="M9.5 10V19" stroke="#AAAAAA" stroke-width="2" stroke-linecap="square"/>
  </svg> -->
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_bank_24</title>
    <g id="icon_bank_24" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Path" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(12.000000, 5.000000) scale(-1, 1) translate(-12.000000, -5.000000) " points="2 9 12 1 22 9"></polygon>
        <path d="M13,6 C13,6.552 12.553,7 12,7 C11.447,7 11,6.552 11,6 C11,5.448 11.447,5 12,5 C12.553,5 13,5.448 13,6" id="Fill-133" :fill="fill"></path>
        <path d="M21,20 L3,20 L21,20 Z" id="Path" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(12.000000, 20.000000) scale(-1, 1) translate(-12.000000, -20.000000) "></path>
        <path d="M21,23 L3,23 L21,23 Z" id="Path" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(12.000000, 23.000000) scale(-1, 1) translate(-12.000000, -23.000000) "></path>
        <line x1="4.5" y1="10" x2="4.5" y2="19" id="Line-7" :stroke="stroke" stroke-width="2" stroke-linecap="square"></line>
        <line x1="19.5" y1="10" x2="19.5" y2="19" id="Line-7" :stroke="stroke" stroke-width="2" stroke-linecap="square"></line>
        <line x1="14.5" y1="10" x2="14.5" y2="19" id="Line-7" :stroke="stroke" stroke-width="2" stroke-linecap="square"></line>
        <line x1="9.5" y1="10" x2="9.5" y2="19" id="Line-7" :stroke="stroke" stroke-width="2" stroke-linecap="square"></line>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconBank',
  props: ['stroke', 'fill']
}

</script>
