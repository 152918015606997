<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21 16V7.99999C20.9993 7.2862 20.6182 6.62689 20 6.26999L13 2.26999C12.3812 1.91273 11.6188 1.91273 11 2.26999L4 6.26999C3.38183 6.62689 3.00073 7.2862 3 7.99999V16C3.00073 16.7138 3.38183 17.3731 4 17.73L11 21.73C11.6188 22.0873 12.3812 22.0873 13 21.73L20 17.73C20.6182 17.3731 20.9993 16.7138 21 16Z" :fill="fill"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21 16V7.99999C20.9993 7.2862 20.6182 6.62689 20 6.26999L13 2.26999C12.3812 1.91273 11.6188 1.91273 11 2.26999L4 6.26999C3.38183 6.62689 3.00073 7.2862 3 7.99999V16C3.00073 16.7138 3.38183 17.3731 4 17.73L11 21.73C11.6188 22.0873 12.3812 22.0873 13 21.73L20 17.73C20.6182 17.3731 20.9993 16.7138 21 16Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16.5 9.39999L7.75769 4.35858" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.48944 7.08694L12 12.01L20.4508 7.1215" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 21.7951V12" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>

export default {
  name: 'IconPackage',
  props: ['stroke', 'fill']
}

</script>
