import Http from '@/http'

const ReportsStore = {
  namespaced: true,
  state: () => ({
    bas: {
      totalIncome: 0,
      totalGSTOnIncome: 0,
      totalExpenses: 0,
      totalGSTOnExpenses: 0,
      amountYouOweTaxOffice: 0,
      amountTaxOfficeOwesYou: 0
    },
    basReportingPeriods: null,
    incomeReport: [],
    salesTaxesReport: []
  }),
  actions: {
    fetchBas ({ commit, state }, reportingPeriodId) {
      const url = process.env.VUE_APP_ROOT_API + 'reports/bas/' + reportingPeriodId
      return new Promise((resolve, reject) => {
        Http.get(url).then(response => {
          commit('setBas', response.data)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    fetchBasReportingPeriods ({ commit, state }, reportingPeriodId) {
      const url = process.env.VUE_APP_ROOT_API + 'reports/bas/reporting-periods'
      return new Promise((resolve, reject) => {
        Http.get(url).then(response => {
          commit('setBasReportingPeriods', response.data)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    fetchIncomeReport ({ commit, state }, data) {
      const url = process.env.VUE_APP_ROOT_API + 'reports/income'
      return new Promise((resolve, reject) => {
        Http.get(url).then(response => {
          commit('setIncomeReportReport', response.data)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    fetchSalesTaxesReport ({ commit, state }, reportParams) {
      const url = process.env.VUE_APP_ROOT_API + 'reports/sales-taxes'
      return new Promise((resolve, reject) => {
        Http.get(url, { params: reportParams }).then(response => {
          commit('setSalesTaxesReport', response.data)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    bas (state) {
      return state.bas
    },
    basReportingPeriods (state) {
      return state.basReportingPeriods
    },
    incomeReport (state) {
      return state.incomeReport
    },
    salesTaxReport (state) {
      return state.salesTaxesReport
    }
  },
  mutations: {
    setBas (state, bas) {
      state.bas = bas
    },
    setBasReportingPeriods (state, basReportingPeriods) {
      state.basReportingPeriods = basReportingPeriods
    },
    setIncomeReport (state, data) {
      state.incomeReport = data
    },
    setSalesTaxesReport (state, data) {
      state.salesTaxesReport = data
    }
  }
}

export default ReportsStore
