<template>
  <!-- <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" :stroke="stroke" stroke-width="2"/>
  <path d="M13.5 14.9274V14.25C13.5 13.0074 12.4926 12 11.25 12H6.75C5.50736 12 4.5 13.0074 4.5 14.25V14.9274" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_sml_user_18</title>
    <g id="icon_sml_user_18" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <circle id="Oval" stroke="#AAAAAA" stroke-width="2" cx="9" cy="9" r="7.5"></circle>
        <path d="M13.5,14.9273884 L13.5,14.25 C13.5,13.0073593 12.4926407,12 11.25,12 L6.75,12 C5.50735931,12 4.5,13.0073593 4.5,14.25 L4.5,14.9273884" id="Shape" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        <circle id="Oval" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" cx="9" cy="7.5" r="2.25"></circle>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconUser',
  props: ['stroke', 'fill']
}

</script>
