<template>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>icon_sml_bank_18</title>
      <g id="icon_sml_bank_18" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon id="Path" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(9.000000, 4.000000) scale(-1, 1) translate(-9.000000, -4.000000) " points="2 7 9 1 16 7"></polygon>
          <path d="M10,4.5 C10,5.052 9.553,5.5 9,5.5 C8.447,5.5 8,5.052 8,4.5 C8,3.948 8.447,3.5 9,3.5 C9.553,3.5 10,3.948 10,4.5" id="Fill-133" fill="#AAAAAA"></path>
          <path d="M16,17 L2,17 L16,17 Z" id="Path" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(9.000000, 17.000000) scale(-1, 1) translate(-9.000000, -17.000000) "></path>
          <path d="M16,14 L2,14 L16,14 Z" id="Path" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(9.000000, 14.000000) scale(-1, 1) translate(-9.000000, -14.000000) "></path>
          <line x1="3.5" y1="7" x2="3.5" y2="13" id="Line-7" stroke="#AAAAAA" stroke-width="2" stroke-linecap="square"></line>
          <line x1="14.5" y1="7" x2="14.5" y2="13" id="Line-7" stroke="#AAAAAA" stroke-width="2" stroke-linecap="square"></line>
          <line x1="11" y1="7" x2="11" y2="13" id="Line-7" stroke="#AAAAAA" stroke-width="2" stroke-linecap="square"></line>
          <line x1="7" y1="7" x2="7" y2="13" id="Line-7" stroke="#AAAAAA" stroke-width="2" stroke-linecap="square"></line>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconSettingsBank',
  props: ['stroke', 'fill']
}

</script>
