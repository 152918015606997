<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="3" y="3" width="18" height="18" rx="2" :fill="fill"/>
  <path d="M12 6.5V17.5" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.5 8.5H10.75C9.7835 8.5 9 9.2835 9 10.25C9 11.2165 9.7835 12 10.75 12H13.25C14.2165 12 15 12.7835 15 13.75C15 14.7165 14.2165 15.5 13.25 15.5H9" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <rect x="3" y="3" width="18" height="18" rx="2" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>

export default {
  name: 'IconAccounting',
  props: ['stroke', 'fill']
}

</script>
