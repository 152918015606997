<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.576 2C11.0036 2 11.1449 2 6 2C4.895 2 4 2.896 4 4V22.009L6.666 20.009L9.333 22.009L12 20.009L14.667 22.009L17.333 20.009L20 22.009C20 15.9787 20 15.456 20 12.4408" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 2.50001C19.3284 1.67159 20.6716 1.67159 21.5 2.50001C22.3284 3.32844 22.3284 4.67159 21.5 5.50001L12 15L8 16L9 12L18.5 2.50001Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>

export default {
  name: 'IconInvoicesDraft',
  props: ['stroke', 'fill']
}

</script>
