import Http from '@/http'

const Account = {
  namespaced: true,
  state: () => ({
    account: null
  }),
  actions: {
    fetch ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'my-account'
      return new Promise((resolve, reject) => {
        if (state.account === null) {
          Http.get(url).then(response => {
            commit('setAccount', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        } else {
          resolve()
        }
      })
    },
    updateUseMultipleCurrencies ({ commit, state }, useMultipleCurrencies = false) {
      commit('setUseMultipleCurrencies', useMultipleCurrencies)
    }
  },
  getters: {
    account (state) {
      if (state.account) {
        return state.account
      }
      return null
    },
    isSetupComplete (state) {
      if (state.account) {
        return state.account.setup_status.complete
      }
      return false
    },
    isSetupRequired (state) {
      if (state.account) {
        return !state.account.setup_status.complete
      }
      return false
    },
    brandLogoUrl (state) {
      if (state.account) {
        return state.account.brand_logo_url
      }
      return false
    },
    isPayable (state) {
      if (state.account) {
        return state.account.setup_status.invoice
      }
    },
    useMultipleCurrencies (state) {
      if (state.account) {
        return state.account.use_multiple_currencies
      }
      return false
    },
    countryCode (state) {
      if (state.account) {
        return state.account.country_code
      }
      return false
    }
  },
  mutations: {
    setAccount (state, account) {
      state.account = account
    },
    setUseMultipleCurrencies (state, useMultipleCurrencies) {
      state.account.use_multiple_currencies = useMultipleCurrencies
    }
  }
}

export default Account
