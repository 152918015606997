<template>
  <section class="section has-bunya-max-width">
    <subpage-header
      :primary-action="primaryAction"
      :secondary-actions="secondaryActions"
      :title="'Clients'"
      v-on:client-create="clientCreate"
    ></subpage-header>
    <div class="subpage-information-area-margin">
      <hr style="margin-bottom: 20px;" class="is-hidden-touch">
      <div v-if="showOnboarding">
        <div class="box bunya-box no-shadow empty-state large">
            <div class="no-content">
              <img class="large" src="../assets/emptyStates/empty_clients_lrg.svg">
              <h3 class="large">Clients</h3>
              <h5 class="large">You don't have any clients yet.</h5>
              <a class="button is-primary" @click="clientCreate">Create Your First Client</a>
            </div>
        </div>
      </div>
      <div v-else>
        <div class="level is-mobile" style="margin-bottom: 20px;">
          <div class="level-left">
            <div class="level-item">
              <!--            <b-dropdown aria-role="list">-->
              <!--              <h2 class="mobile-h2"-->
              <!--                slot="trigger"-->
              <!--                role="button">-->
              <!--                  Wedding Photography-->
              <!--                  <b-icon icon="chevron-down" size="is-small" class="dropdown-icon"/>-->
              <!--              </h2>-->
              <!--              <b-dropdown-item aria-role="listitem"><span>Work Flow 1</span></b-dropdown-item>-->
              <!--              <b-dropdown-item aria-role="listitem">Work Flow 2</b-dropdown-item>-->
              <!--            </b-dropdown>-->
              <!--            &lt;!&ndash;TODO have to be dropdown with company names&ndash;&gt;-->
            </div>
          </div>
          <div class="level-right is-hidden-touch filter-padding">
            <div class="level-item is-hidden-touch">
              <b-field>
                <b-input
                  placeholder="Filter Clients"
                  type="search"
                  icon="filter-outline"
                  @input="updateFilter"
                  :value="filter">
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
        <clients-table v-if="isHiddenList" :isLoading="isLoading" class="bunya-table"/>
      </div>
    </div>
  </section>
</template>

<script>
import ClientsTable from '@/components/ClientsTable'
import SubpageHeader from '@/components/SubpageHeader'

export default {
  name: 'Clients',
  components: {
    SubpageHeader,
    ClientsTable
  },
  computed: {
    clients () {
      return this.$store.getters['clients/all']
    },
    showOnboarding () {
      return this.clients.length === 0
    },
    filter () {
      return this.$store.getters['clients/filter']
    }
  },
  data () {
    return {
      isLoading: false,
      isHiddenList: true,
      isClientModalActive: false,
      primaryAction: {
        name: 'Add Client',
        icon: null,
        emit: 'client-create'
      },
      secondaryActions: null
    }
  },
  methods: {
    clientCreate () {
      this.$router.push('/clients/create')
    },
    updateFilter (value) {
      this.$store.dispatch('clients/updateFilter', value)
    }
  },
  mounted () {
    this.isLoading = true
    this.$store.dispatch('clients/fetchAll')
      .then(() => {
        this.isLoading = false
      })
      .catch(e => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })
  }
}
</script>

<style scoped>
  .information-box {
    margin-bottom: 20px;
    margin-top: 20px;
    }
  .filter-padding {
    margin-right: 15px;
  }
  hr{
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }
  .navbar-item {
    background-color: #fafafa;
    color: #1BCA8E;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
    padding: 0.25rem 0.75rem 0rem 0.75rem;
    width: 60px;
    height: 30px;
  }
  a.dropdown-item.is-active {
    background-color: #fff;
    color: #000;
  }

  a{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #2D395960;
  }
  a:hover{
    color: #2D3959;
  }
</style>
