<template>
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_note_30</title>
    <g id="icon_note_30" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g id="Group" transform="translate(3.500000, 1.000000)" :stroke="stroke" stroke-width="2">
        <g id="Group-20">
          <path d="M14.6428571,0 L3.25,0 C1.73121694,0 0.5,1.20883118 0.5,2.7 L0.5,25.3 C0.5,26.7911688 1.73121694,28 3.25,28 L19.75,28 C21.2687831,28 22.5,26.7911688 22.5,25.3 L22.5,7.5 L14.6428571,0 Z" id="Path" fill="#FFFFFF"></path>
          <line x1="16.9975825" y1="15" x2="6" y2="15" id="Path"></line>
        </g>
        <polyline id="Path" points="14.5 0 14.5 8 22 8"></polyline>
        <line x1="16.9975825" y1="22" x2="6" y2="22" id="Path"></line>
        <line x1="10.25" y1="8" x2="6" y2="8" id="Path"></line>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconTimelineNote',
  props: ['stroke', 'fill']
}

</script>
