<template>
  <svg width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path d="M308.26,78H118.82a6.75,6.75,0,0,0-6.75,6.75v89.37a6.75,6.75,0,0,0,6.75,6.75H308.26a6.75,6.75,0,0,0,6.75-6.75V84.79A6.76,6.76,0,0,0,308.26,78Zm-6.75,89.37H125.57V91.54H301.51Z" :stroke="stroke"/>
    <path d="M152.65,192.48A25.48,25.48,0,1,0,178.13,218,25.5,25.5,0,0,0,152.65,192.48Zm0,37.45a12,12,0,1,1,12-12A12,12,0,0,1,152.65,229.93Z" :stroke="stroke"/>
    <path d="M213.54,192.48A25.48,25.48,0,1,0,239,218,25.51,25.51,0,0,0,213.54,192.48Zm0,37.45a12,12,0,1,1,12-12A12,12,0,0,1,213.54,229.93Z" :stroke="stroke"/>
    <path d="M274.43,192.48A25.48,25.48,0,1,0,299.9,218,25.51,25.51,0,0,0,274.43,192.48Zm0,37.45a12,12,0,1,1,12-12A12,12,0,0,1,274.43,229.93Z" :stroke="stroke"/>
    <path d="M152.65,250.51A25.48,25.48,0,1,0,178.13,276,25.5,25.5,0,0,0,152.65,250.51Zm0,37.45a12,12,0,1,1,12-12A12,12,0,0,1,152.65,288Z" :stroke="stroke"/>
    <path d="M213.54,250.51A25.48,25.48,0,1,0,239,276,25.5,25.5,0,0,0,213.54,250.51Zm0,37.45a12,12,0,1,1,12-12A12,12,0,0,1,213.54,288Z" :stroke="stroke"/>
    <path d="M274.43,250.51A25.48,25.48,0,1,0,299.9,276,25.5,25.5,0,0,0,274.43,250.51Zm0,37.45a12,12,0,1,1,12-12A12,12,0,0,1,274.43,288Z" :stroke="stroke"/>
    <path d="M191,164a6.77,6.77,0,0,0,8.62-4.12l2.89-8.22h16.09l2.89,8.22a6.77,6.77,0,0,0,6.37,4.51,6.88,6.88,0,0,0,2.25-.39,6.75,6.75,0,0,0,4.12-8.61l-17.31-49.1a6.75,6.75,0,0,0-12.73,0l-17.31,49.1A6.75,6.75,0,0,0,191,164Zm22.84-25.84h-6.57l3.29-9.32Z" :stroke="stroke"/>
    <path d="M155.49,114.8h7.41v42.35a6.75,6.75,0,0,0,13.5,0V114.8h7.41a6.75,6.75,0,0,0,0-13.5H155.49a6.75,6.75,0,0,0,0,13.5Z" :stroke="stroke"/>
    <path d="M239.51,162.88a6.74,6.74,0,0,0,9.25-2.35l8.8-14.77,8.79,14.77a6.75,6.75,0,1,0,11.6-6.9l-12.54-21.06L278,111.5a6.75,6.75,0,1,0-11.6-6.9l-8.79,14.77-8.8-14.77a6.75,6.75,0,1,0-11.6,6.9l12.54,21.07-12.54,21.06A6.76,6.76,0,0,0,239.51,162.88Z" :stroke="stroke"/>
    <path d="M406.35,313.86l-58.57-18V83.48a37.1,37.1,0,0,0-37-37.06H116.35a37.1,37.1,0,0,0-37,37.06v242.1a37.09,37.09,0,0,0,37.05,37.05H252.87c4.92,5.76,8.39,11,10.21,15.35l1.25,3.07c6.68,16.37,14.22,34.84,45.44,55.94v21.84a6.75,6.75,0,0,0,13.5,0V433.37a6.73,6.73,0,0,0-3.07-5.66c-30.26-19.65-36.93-36-43.36-51.76l-1.29-3.13c-3.56-8.6-11.17-18.63-22.64-29.81a9.35,9.35,0,0,1,11.62-14.5c11.1,7.23,30.62,21.78,36.72,37.92a6.75,6.75,0,0,0,13.06-2.38V240.38a10,10,0,0,1,20,0v91.4a6.75,6.75,0,0,0,13.5,0V310l54.62,16.75a23.64,23.64,0,0,1,16.8,22.73v55.13a19.32,19.32,0,0,1-5.67,13.71l-7.71,7.7a6.76,6.76,0,0,0-2,4.78v28a6.75,6.75,0,0,0,13.5,0V433.61l5.73-5.73a32.65,32.65,0,0,0,9.63-23.25V349.5A37.08,37.08,0,0,0,406.35,313.86Zm-82.22-96.8a23.34,23.34,0,0,0-23.32,23.32V341.2c-8.72-9.74-19.63-17.95-28.9-24a22.87,22.87,0,0,0-31.4,31.93H116.35A23.58,23.58,0,0,1,92.8,325.58V83.48a23.58,23.58,0,0,1,23.55-23.56H310.73a23.59,23.59,0,0,1,23.55,23.56V219.29A24,24,0,0,0,324.13,217.06Z" :stroke="stroke"/>
  </svg>
</template>

<script>

export default {
  name: 'IconTax',
  props: ['stroke', 'fill']
}

</script>
