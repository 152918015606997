<template>
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_appointment_30</title>
    <g id="icon_appointment_30" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g id="Group" transform="translate(3.000000, 1.000000)" :stroke="stroke" stroke-width="2">
        <g id="calendar-(1)">
          <rect id="Rectangle" :fill="fill" x="0" y="3" width="24" height="25" rx="2.95238095"></rect>
          <line x1="7" y1="0" x2="7" y2="6.00703562" id="Path"></line>
          <line x1="17" y1="0" x2="17" y2="6.00703562" id="Path"></line>
          <line x1="-1.38555833e-14" y1="11.0222222" x2="24" y2="11.0222222" id="Path"></line>
        </g>
        <polyline id="Path" points="16.2666667 17 10.5833333 22.5111111 8 20.0060606"></polyline>
      </g>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconTimelineAppointment',
  props: ['stroke', 'fill']
}

</script>
