import Http from '@/http'
import moment from 'moment'

const AppointmentsStore = {
  namespaced: true,
  state: () => ({
    appointment: {},
    appointments: []
  }),
  actions: {
    resetAppointment ({ state, commit }) {
      const appointment = {
        attendee_type: 'client'
      }
      commit('setAppointment', appointment)
    },
    save ({ state, commit }, appointment) {
      let url = process.env.VUE_APP_ROOT_API + 'appointments'
      if (state.appointment.id) {
        url = url + '/' + state.appointment.id
      }
      return new Promise((resolve, reject) => {
        const appointment = state.appointment
        appointment.date = moment(appointment.appointmentAt).format('YYYY-MM-DD')
        appointment.time = moment(appointment.appointmentAt).format('HH:mm')
        if (appointment.id) {
          Http.put(url, { appointment: appointment }).then(response => {
            commit('setAppointment', response.data)
            resolve({ appointment: response.data })
          }, error => {
            reject(error)
          })
        } else {
          Http.post(url, { appointment: appointment }).then(response => {
            commit('setInvoice', response.data)
            resolve({ appointment: response.data })
          }, error => {
            reject(error)
          })
        }
      })
    },
    fetch ({ commit }, appointmentId) {
      const url = process.env.VUE_APP_ROOT_API + 'appointments/' + appointmentId
      return new Promise((resolve, reject) => {
        Http.get(url).then(
          response => {
            if (response.data) {
              commit('setAppointment', response.data)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetchUpcoming ({ commit }, payload) {
      const url = process.env.VUE_APP_ROOT_API + 'appointments'
      const requestVars = {
        limit: 5,
        filter: 'upcoming'
      }
      return new Promise((resolve, reject) => {
        Http.get(url, { params: requestVars }).then(
          response => {
            if (response.data) {
              commit('setAppointments', response.data)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetchCalendar ({ commit }, payload) {
      const url = process.env.VUE_APP_ROOT_API + 'appointments'
      let requestVars = {}
      if (payload) {
        const startAtString = moment(payload.startStr).format('YYYY-MM-DD')
        const endAtString = moment(payload.endStr).format('YYYY-MM-DD')
        requestVars = {
          startAt: startAtString,
          endAt: endAtString
        }
      }
      return new Promise((resolve, reject) => {
        Http.get(url, { params: requestVars }).then(
          response => {
            if (response.data) {
              commit('setAppointments', response.data)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    delete ({ commit }, appointmentId) {
      const url = process.env.VUE_APP_ROOT_API + 'appointments/' + appointmentId
      return new Promise((resolve, reject) => {
        Http.delete(url).then(
          response => {
            if (response.data) {
              commit('setAppointment', response.data)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    }
  },
  getters: {
    appointment (state) {
      return state.appointment
    },
    past (state) {

    },
    next (state) {

    },
    upcoming (state) {
      return state.appointments.slice(0, 5)
    },
    calendar (state) {
      return state.appointments
    }
  },
  mutations: {
    setAppointments (state, appointments) {
      state.appointments = appointments
    },
    setAppointment (state, appointment) {
      const appointmentAtDate = (appointment.appointment_at) ? moment(appointment.appointment_at) : moment()
      appointment.appointmentAt = appointmentAtDate.toDate()
      state.appointment = appointment
    }
  }
}

export default AppointmentsStore
