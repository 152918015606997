<template>
  <!-- TODO We need to not use bunyaGreenStroke etc, the whole point of being able to pass in the stroke is lost -->
  <div class="icon-container" :width="computedWidth" :height="computedHeight">
    <component :is="iconComponentName" :stroke="computedStroke" :fill="computedFill" :width="computedWidth" :height="computedHeight"></component>
    <div class="is-complete" v-if="isComplete">
      <icon-check stroke="#FFFFFF" fill="#FFFFFF" width="8" height="8"></icon-check>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .icon-container {
    //display: inline-flex;
    position: relative;
    .is-complete {
      width: 12px;
      height: 12px;
      position: absolute;
      right: -5px;
      top: 0px;
      background: #1BCA8E;
      border-radius: 5px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }

</style>

<script>
import IconDropdownCreateAlbum from '@/components/Icons/DropdownCreateAlbum'
import IconDropdownCreateAppointment from '@/components/Icons/DropdownCreateAppointment'
import IconDropdownCreateInvoice from '@/components/Icons/DropdownCreateInvoice'
import IconDropdownCreateQuote from '@/components/Icons/DropdownCreateQuote'
import IconBunya from '@/components/Icons/Bunya'
import IconAccounting from '@/components/Icons/Accounting'
import IconAppointment from '@/components/Icons/Appointment'
import IconClient from '@/components/Icons/Client'
import IconClipboard from '@/components/Icons/Clipboard'
import IconCog from '@/components/Icons/Cog'
import IconHome from '@/components/Icons/Home'
import IconPackage from '@/components/Icons/Package'
import IconPie from '@/components/Icons/Pie'
import IconReceipt from '@/components/Icons/Receipt'
import IconTax from '@/components/Icons/Tax'
import IconTimeline from '@/components/Icons/Timeline'
import IconCalendar from '@/components/Icons/Calendar'
import IconExpensesUnpaid from '@/components/Icons/ExpensesUnpaid'
import IconExpensesPaid from '@/components/Icons/ExpensesPaid'
import IconChecklist from '@/components/Icons/Checklist'
import IconInvoicesPaid from '@/components/Icons/InvoicesPaid'
import IconInvoicesUnpaid from '@/components/Icons/InvoicesUnpaid'
import IconInvoicesDraft from '@/components/Icons/InvoicesDraft'
import IconIncome from '@/components/Icons/Income'
import IconBank from '@/components/Icons/Bank'
import IconCreditCard from '@/components/Icons/CreditCard'
import IconTheme from '@/components/Icons/Theme'
import IconBusiness from '@/components/Icons/Business'
import IconSetup from '@/components/Icons/Setup'
import IconPassword from '@/components/Icons/Password'
import IconSubscription from '@/components/Icons/Subscription'
import IconUser from '@/components/Icons/User'
import IconMenuExpand from '@/components/Icons/MenuExpand'
import IconMenuCollapse from '@/components/Icons/MenuCollapse'
import IconTextFile from '@/components/Icons/TextFile'
import IconMessage from '@/components/Icons/Message'
import IconPhone from '@/components/Icons/Phone'
import IconPlace from '@/components/Icons/Place'
import IconGrid from '@/components/Icons/Grid'
import IconList from '@/components/Icons/List'
import IconArrowUp from '@/components/Icons/ArrowUp'
import IconArrowDown from '@/components/Icons/ArrowDown'
import IconView from '@/components/Icons/View'
import IconCheck from '@/components/Icons/Check'
import IconPhoto from '@/components/Icons/Photo'
import IconPaperClip from '@/components/Icons/PaperClip'
import IconCamera from '@/components/Icons/Camera'
import IconTask from '@/components/Icons/Task'
import IconClock from '@/components/Icons/Clock'
import IconCross from '@/components/Icons/Cross'
import IconPrinter from '@/components/Icons/Printer'
import IconDownloadDocument from '@/components/Icons/DownloadDocument'
import IconRecordPayment from '@/components/Icons/RecordPayment'
import IconBin from '@/components/Icons/Bin'
import IconCopy from '@/components/Icons/Copy'
import IconExpand from '@/components/Icons/Expand'
import IconLogout from '@/components/Icons/Logout'
import IconSaveAsTemplate from '@/components/Icons/SaveAsTemplate'
import IconFilter from '@/components/Icons/Filter'
import IconAlert from '@/components/Icons/Alert'
import IconHamburger from '@/components/Icons/Hamburger'
import IconCalendarAppointment from '@/components/Icons/CalendarAppointment'
import IconSnowman from '@/components/Icons/Snowman'
import IconChevronDown from '@/components/Icons/ChevronDown'
import IconArrowDownSmall from '@/components/Icons/ArrowDownSmall'
import IconArrowDownLarge from '@/components/Icons/ArrowDownLarge'
import IconClientNote from '@/components/Icons/ClientNote'
import IconClientTask from '@/components/Icons/ClientTask'
import IconClientAlbum from '@/components/Icons/ClientAlbum'
import IconSettingsTheme from '@/components/Icons/SettingsTheme'
import IconSettingsBank from '@/components/Icons/SettingsBank'
import IconSettingsInvoice from '@/components/Icons/SettingsInvoice'
import IconSettingsStripe from '@/components/Icons/SettingsStripe'
import IconEdit from '@/components/Icons/Edit'
import IconTimelineAppointment from '@/components/Icons/TimelineAppointment'
import IconTimelineAlbum from '@/components/Icons/TimelineAlbum'
import IconTimelineInvoice from '@/components/Icons/TimelineInvoice'
import IconTimelineNote from '@/components/Icons/TimelineNote'
import IconTimelineQuote from '@/components/Icons/TimelineQuote'
import IconTimelineTask from '@/components/Icons/TimelineTask'
import IconArrowExpand from '@/components/Icons/ArrowExpand'

export default {
  components: {
    IconDropdownCreateAlbum,
    IconDropdownCreateAppointment,
    IconDropdownCreateInvoice,
    IconDropdownCreateQuote,
    IconAccounting,
    IconAppointment,
    IconClient,
    IconClipboard,
    IconCog,
    IconHome,
    IconReceipt,
    IconPackage,
    IconPie,
    IconTimeline,
    IconCalendar,
    IconExpensesPaid,
    IconExpensesUnpaid,
    IconChecklist,
    IconInvoicesPaid,
    IconInvoicesUnpaid,
    IconInvoicesDraft,
    IconIncome,
    IconBank,
    IconBunya,
    IconCreditCard,
    IconTheme,
    IconBusiness,
    IconSetup,
    IconPassword,
    IconSubscription,
    IconUser,
    IconMenuExpand,
    IconMenuCollapse,
    IconTextFile,
    IconMessage,
    IconPhone,
    IconPlace,
    IconGrid,
    IconList,
    IconArrowUp,
    IconArrowDown,
    IconView,
    IconCheck,
    IconPhoto,
    IconPaperClip,
    IconCamera,
    IconTask,
    IconClock,
    IconCross,
    IconPrinter,
    IconDownloadDocument,
    IconRecordPayment,
    IconBin,
    IconCopy,
    IconExpand,
    IconLogout,
    IconTax,
    IconSaveAsTemplate,
    IconFilter,
    IconAlert,
    IconHamburger,
    IconCalendarAppointment,
    IconSnowman,
    IconChevronDown,
    IconArrowDownSmall,
    IconArrowDownLarge,
    IconClientNote,
    IconClientTask,
    IconClientAlbum,
    IconSettingsTheme,
    IconSettingsBank,
    IconSettingsInvoice,
    IconSettingsStripe,
    IconEdit,
    IconTimelineAlbum,
    IconTimelineAppointment,
    IconTimelineInvoice,
    IconTimelineNote,
    IconTimelineQuote,
    IconTimelineTask,
    IconArrowExpand
  },
  computed: {
    computedStroke () {
      if (this.stroke) {
        return this.stroke
      }
      return '#AAAAAA'
    },
    bunyaGreenStroke () {
      if (this.stroke) {
        return this.stroke
      }
      return '#1BCA8E'
    },
    bunyaOrangeStroke () {
      if (this.stroke) {
        return this.stroke
      }
      return '#FF8B41'
    },
    computedFill () {
      if (this.fill) {
        return this.fill
      }
      return '#FFFFFF'
    },
    computedWidth () {
      if (this.width) {
        return this.width
      }
      return 24
    },
    computedHeight () {
      if (this.height) {
        return this.height
      }
      return 24
    },
    isComplete () {
      if (this.complete) {
        return true
      }
      return false
    },
    iconComponentName () {
      // TODO normalise the icon names when calling the component so we don't need this shit
      if (this.icon === 'dropdownCreateAppointment') return ('icon-dropdown-create-appointment')
      if (this.icon === 'dropdownCreateAlbum') return ('icon-dropdown-create-album')
      if (this.icon === 'dropdownCreateInvoice') return ('icon-dropdown-create-invoice')
      if (this.icon === 'dropdownCreateQuote') return ('icon-dropdown-create-quote')
      if (this.icon === 'accounting') return ('icon-accounting')
      if (this.icon === 'appointment') return ('icon-appointment')
      if (this.icon === 'client') return ('icon-client')
      if (this.icon === 'clipboard') return ('icon-clipboard')
      if (this.icon === 'cog') return ('icon-cog')
      if (this.icon === 'home') return ('icon-home')
      if (this.icon === 'receipt') return ('icon-receipt')
      if (this.icon === 'package') return ('icon-package')
      if (this.icon === 'pie') return ('icon-pie')
      if (this.icon === 'timeline') return ('icon-timeline')
      if (this.icon === 'calendar') return ('icon-calendar')
      if (this.icon === 'expensesPaid') return ('icon-expenses-paid')
      if (this.icon === 'expensesUnpaid') return ('icon-expenses-unpaid')
      if (this.icon === 'checklist') return ('icon-checklist')
      if (this.icon === 'invoicesPaid') return ('icon-invoices-paid')
      if (this.icon === 'invoicesUnpaid') return ('icon-invoices-unpaid')
      if (this.icon === 'invoicesDraft') return ('icon-invoices-draft')
      if (this.icon === 'income') return ('icon-income')
      if (this.icon === 'bank') return ('icon-bank')
      if (this.icon === 'tax') return ('icon-tax')
      if (this.icon === 'bunya') return ('icon-bunya')
      if (this.icon === 'creditCard') return ('icon-credit-card')
      if (this.icon === 'theme') return ('icon-theme')
      if (this.icon === 'business') return ('icon-business')
      if (this.icon === 'setup') return ('icon-setup')
      if (this.icon === 'password') return ('icon-password')
      if (this.icon === 'subscription') return ('icon-subscription')
      if (this.icon === 'user') return ('icon-user')
      if (this.icon === 'menuExpand') return ('icon-menu-expand')
      if (this.icon === 'menuCollapse') return ('icon-menu-collapse')
      if (this.icon === 'textFile') return ('icon-text-file')
      if (this.icon === 'message') return ('icon-message')
      if (this.icon === 'phone') return ('icon-phone')
      if (this.icon === 'place') return ('icon-place')
      if (this.icon === 'grid') return ('icon-grid')
      if (this.icon === 'list') return ('icon-list')
      if (this.icon === 'arrowUp') return ('icon-arrow-up')
      if (this.icon === 'arrowDown') return ('icon-arrow-down')
      if (this.icon === 'view') return ('icon-view')
      if (this.icon === 'check') return ('icon-check')
      if (this.icon === 'photo') return ('icon-photo')
      if (this.icon === 'paperClip') return ('icon-paper-clip')
      if (this.icon === 'camera') return ('icon-camera')
      if (this.icon === 'task') return ('icon-task')
      if (this.icon === 'clock') return ('icon-clock')
      if (this.icon === 'cross') return ('icon-cross')
      if (this.icon === 'printer') return ('icon-printer')
      if (this.icon === 'downloadDocument') return ('icon-download-document')
      if (this.icon === 'recordPayment') return ('icon-record-payment')
      if (this.icon === 'bin') return ('icon-bin')
      if (this.icon === 'copy') return ('icon-copy')
      if (this.icon === 'expand') return ('icon-expand')
      if (this.icon === 'logout') return ('icon-logout')
      if (this.icon === 'saveAsTemplate') return ('icon-save-as-template')
      if (this.icon === 'filter') return ('icon-filter')
      if (this.icon === 'alert') return ('icon-alert')
      if (this.icon === 'hamburger') return ('icon-hamburger')
      if (this.icon === 'calendarAppointment') return ('icon-calendar-appointment')
      if (this.icon === 'snowman') return ('icon-snowman')
      if (this.icon === 'chevronDown') return ('icon-chevron-down')
      if (this.icon === 'arrowDownSmall') return ('icon-arrow-down-small')
      if (this.icon === 'arrowDownLarge') return ('icon-arrow-down-large')
      if (this.icon === 'clientNote') return ('icon-client-note')
      if (this.icon === 'clientTask') return ('icon-client-task')
      if (this.icon === 'clientAlbum') return ('icon-client-album')
      if (this.icon === 'settingsTheme') return ('icon-settings-theme')
      if (this.icon === 'settingsBank') return ('icon-settings-bank')
      if (this.icon === 'settingsInvoice') return ('icon-settings-invoice')
      if (this.icon === 'settingsStripe') return ('icon-settings-stripe')
      if (this.icon === 'edit') return ('icon-edit')
      if (this.icon === 'timelineAppointment') return ('icon-timeline-appointment')
      if (this.icon === 'timelineAlbum') return ('icon-timeline-album')
      if (this.icon === 'timelineInvoice') return ('icon-timeline-invoice')
      if (this.icon === 'timelineNote') return ('icon-timeline-note')
      if (this.icon === 'timelineQuote') return ('icon-timeline-quote')
      if (this.icon === 'timelineTask') return ('icon-timeline-task')
      if (this.icon === 'arrowExpand') return ('icon-arrow-expand')
      return false
    }
  },
  methods: {
    isIcon (icon) {
      return (this.icon === icon)
    }
  },
  name: 'Icon',
  props: ['icon', 'stroke', 'fill', 'width', 'height', 'complete']
}

</script>
