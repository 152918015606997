import Http from '@/http'

const ClientsStore = {
  namespaced: true,
  state: () => ({
    client: null,
    filter: '',
    clients: [],
    timeline: []
  }),
  actions: {
    resetClient ({ commit }) {
      commit('setClient', {
        type: 'business',
        name: '',
        additional_contact: []
      })
    },
    updateFilter ({ commit, state }, filter) {
      commit('setFilter', filter)
    },
    addAdditionalContact ({ commit }) {
      const newAdditionalContact = {
        id: null,
        name: '',
        email: '',
        phone: ''
      }
      commit('setNewAdditionalContact', newAdditionalContact)
    },
    fetch ({ commit }, clientId) {
      const url = process.env.VUE_APP_ROOT_API + 'clients/' + clientId
      commit('setTimeline', [])
      return new Promise((resolve, reject) => {
        Http.get(url).then(
          response => {
            if (response.data) {
              const client = response.data
              const timeline = []
              response.data.timeline.forEach((timelineEntry) => {
                timeline.push({
                  type: timelineEntry.type,
                  timelineable: timelineEntry.timelineable
                })
              })
              client.timeline = null
              commit('setClient', client)
              commit('setTimeline', timeline)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetchAll ({ commit }) {
      const url = process.env.VUE_APP_ROOT_API + 'clients'
      return new Promise((resolve, reject) => {
        Http.get(url).then(
          response => {
            if (response.data) {
              commit('setClients', response.data)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    addTimelineEntry ({ commit }, timelineEntry) {
      commit('setNewTimelineEntry', timelineEntry)
    },
    save ({ state, commit }, client) {
      if (client.id) {
        const url = process.env.VUE_APP_ROOT_API + 'clients/' + state.client.id
        return new Promise((resolve, reject) => {
          const payload = {
            client: client
          }
          payload.client.additional_contacts = payload.client.additional_contacts
            .filter((contact) => {
              return contact.deleted === false || contact.id !== null
            })
            .map((contact) => {
              if (contact.deleted) {
                return {
                  id: contact.id,
                  deleted: true
                }
              }
              return contact
            })
          Http.put(url, payload).then(response => {
            commit('setClient', client)
            resolve(response)
          }, error => {
            reject(error)
          })
        })
      } else {
        const url = process.env.VUE_APP_ROOT_API + 'clients'
        return new Promise((resolve, reject) => {
          Http.post(url, { client: client }).then(response => {
            commit('setClient', client)
            commit('setNewClientInClients', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        })
      }
    }
  },
  getters: {
    filter (state) {
      return state.filter
    },
    client (state) {
      return state.client
    },
    timeline (state) {
      return state.timeline
    },
    all (state) {
      return state.clients
    },
    filtered (state) {
      if (!state.clients) {
        return []
      }
      return state.clients.filter((client) => {
        if (state.filter) {
          return (client.name.toLowerCase().includes(state.filter.toLowerCase()))
        }
        return true
      })
    }
  },
  mutations: {
    setFilter (state, filter) {
      state.filter = filter
    },
    setClient (state, client) {
      state.client = client
    },
    setTimeline (state, client) {
      state.timeline = client
    },
    setNewTimelineEntry (state, timelineEntry) {
      const timeline = state.timeline
      timeline.unshift(timelineEntry)
      state.timeline = timeline
    },
    setClients (state, clients) {
      state.clients = clients
    },
    setNewAdditionalContact (state, additionalContact) {
      state.client.additional_contacts.push(additionalContact)
    },
    setNewClientInClients (state, client) {
      state.clients.push(client)
    }
  }
}

export default ClientsStore
