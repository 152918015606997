import Http from '@/http'
import moment from 'moment'

const TasksStore = {
  namespaced: true,
  state: () => ({
    tasks: []
  }),
  actions: {
    create ({ commit }, task) {
      const url = process.env.VUE_APP_ROOT_API + 'tasks'
      return new Promise((resolve, reject) => {
        Http.post(url, { task: task }).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetch ({ commit }, taskParams) {
      const url = process.env.VUE_APP_ROOT_API + 'tasks'
      return new Promise((resolve, reject) => {
        Http.get(url, { params: taskParams }).then(
          response => {
            if (response.data) {
              commit('setTasks', response.data)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    }
  },
  getters: {
    current (state) {
      return state.tasks.filter(task => {
        const now = moment()
        const daysAgo = now.diff(task.completed_at, 'days')
        return (task.completed_at === null || daysAgo <= 1)
      }).slice(0, 5)
    },
    all (state) {
      return state.tasks
    }
  },
  mutations: {
    setTasks (state, tasks) {
      state.tasks = tasks
    }
  }
}

export default TasksStore
