<template>
  <span class="input is-medium is-fullwidth has-text-right"><slot></slot></span>
</template>

<style>

</style>

<script>

export default {
  name: 'data-editor-field-info'
}

</script>
