<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19 10.2274C19 5.25699 14.9704 1.22736 10 1.22736C5.02963 1.22736 1 5.25699 1 10.2274C1 15.1977 5.02963 19.2274 10 19.2274C14.9704 19.2274 19 15.1977 19 10.2274Z" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M10 6V10.1936L12.4278 11.8825" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>

export default {
  name: 'IconClock',
  props: ['stroke', 'fill']
}

</script>
