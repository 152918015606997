<template>
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_task_30</title>
    <g id="icon_task_30" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <rect id="Rectangle" stroke="#1BCA8E" stroke-width="2" :fill="fill" x="1" y="1" width="28" height="28" rx="4.5"></rect>
      <g id="Group-28" transform="translate(6.500000, 14.500000)" :stroke="stroke" stroke-width="2">
        <g id="Group-20">
          <line x1="2.5" y1="0.5" x2="-1.33226763e-14" y2="0.5" id="Path"></line>
          <line x1="17" y1="0.5" x2="7" y2="0.5" id="Path"></line>
        </g>
      </g>
      <line x1="9" y1="21" x2="6.5" y2="21" id="Path" stroke="#1BCA8E" stroke-width="2"></line>
      <line x1="9" y1="9" x2="6.5" y2="9" id="Path" stroke="#1BCA8E" stroke-width="2"></line>
      <line x1="23.5" y1="21" x2="13.5" y2="21" id="Path" stroke="#1BCA8E" stroke-width="2"></line>
      <line x1="23.5" y1="9" x2="13.5" y2="9" id="Path" stroke="#1BCA8E" stroke-width="2"></line>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconTimelineTask',
  props: ['stroke', 'fill']
}

</script>
