<template>
  <!-- <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 15.75L9 12L3.75 15.75V3.75C3.75 2.92157 4.42157 2.25 5.25 2.25H12.75C13.5784 2.25 14.25 2.92157 14.25 3.75V15.75Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_sml_subscription_18</title>
    <g id="icon_sml_subscription_18" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <path d="M14,15.5 L9,11.75 L4,15.5 L4,3.5 C4,2.67157288 4.63959321,2 5.42857143,2 L12.5714286,2 C13.3604068,2 14,2.67157288 14,3.5 L14,15.5 Z" id="Path" stroke="#AAAAAA" stroke-width="2"></path>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconSubscription',
  props: ['stroke', 'fill']
}

</script>
