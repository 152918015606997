<template>
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19 2H1C0.448 2 0 1.553 0 1C0 0.447 0.448 0 1 0H19C19.552 0 20 0.447 20 1C20 1.553 19.552 2 19 2ZM19 9H1C0.448 9 0 8.553 0 8C0 7.447 0.448 7 1 7H19C19.552 7 20 7.447 20 8C20 8.553 19.552 9 19 9ZM1 16H19C19.552 16 20 15.553 20 15C20 14.447 19.552 14 19 14H1C0.448 14 0 14.447 0 15C0 15.553 0.448 16 1 16Z" :fill="fill"/>
  </svg>
</template>

<script>

export default {
  name: 'IconHamburger',
  props: ['stroke', 'fill']
}

</script>
