<template>
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Settings-New" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
  <g id="icon_reports_selected" transform="translate(1.000000, 1.000000)" stroke="#1BCA8E" stroke-width="2">
  <path d="M19.21093,13.89 C17.4607792,18.028883 13.1647537,20.4920434 8.70870266,19.9115357 C4.25265159,19.3310281 0.731072792,15.8494369 0.0997170985,11.4003055 C-0.531638595,6.95117409 1.88230277,2.62730068 6.00093003,0.83" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20.00093,10 C20.00093,7.3478351 18.9473616,4.80429597 17.0719978,2.92893219 C15.1966341,1.0535684 12.6530949,0 10.00093,0 L10.00093,10 L20.00093,10 Z" id="Path" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </g>
</svg>

</template>

<script>

export default {
  name: 'IconPie',
  props: ['stroke', 'fill']
}

</script>
