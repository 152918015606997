import Http from '@/http'

const CurrenciesStore = {
  namespaced: true,
  state: () => ({
    currencies: []
  }),
  actions: {
    fetch ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'currencies'
      return new Promise((resolve, reject) => {
        if (state.currencies.length === 0) {
          Http.get(url).then(response => {
            commit('setCurrencies', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        } else {
          resolve()
        }
      })
    }
  },
  getters: {
    currencies (state) {
      return state.currencies
    }
  },
  mutations: {
    setCurrencies (state, currency) {
      state.currencies = currency
    }
  }
}

export default CurrenciesStore
