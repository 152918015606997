<template>
  <!-- <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.7" d="M12 8.46416L11.2706 7.7801C11.0967 7.96546 11 8.21005 11 8.46416H12ZM12 17L11.5528 17.8944C11.8628 18.0494 12.2309 18.0329 12.5257 17.8507C12.8205 17.6684 13 17.3466 13 17H12ZM8 15H7C7 15.3788 7.214 15.725 7.55279 15.8944L8 15ZM8 8.4514H9C9 8.19699 8.90303 7.95214 8.72884 7.76672L8 8.4514ZM1 1V0C0.601214 0 0.24055 0.236937 0.0822076 0.602939C-0.0761346 0.968942 -0.00188136 1.39404 0.271162 1.68469L1 1ZM19 1L19.7294 1.68406C20.0021 1.39329 20.0761 0.96834 19.9176 0.602548C19.7592 0.236755 19.3986 0 19 0V1ZM11 8.46416V17H13V8.46416H11ZM12.4472 16.1056L8.44721 14.1056L7.55279 15.8944L11.5528 17.8944L12.4472 16.1056ZM9 15V8.4514H7V15H9ZM8.72884 7.76672L1.72884 0.315314L0.271162 1.68469L7.27116 9.13609L8.72884 7.76672ZM1 2H19V0H1V2ZM18.2706 0.315937L11.2706 7.7801L12.7294 9.14823L19.7294 1.68406L18.2706 0.315937Z" :fill="fill"/>
  </svg> -->
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_filter_20</title>
    <g id="icon_filter_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
        <path d="M19,2 L11.999,9.464 L12,18 L8,16 L7.999,9.45 L1,2 L19,2 Z" id="Combined-Shape" :stroke="stroke" stroke-width="2"></path>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconFilter',
  props: ['stroke', 'fill']
}

</script>
