<template>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>icon_sml_creditcard_18</title>
      <g id="icon_sml_creditcard_18" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
          <rect id="Rectangle" stroke="#AAAAAA" stroke-width="2" x="1" y="3" width="16" height="12" rx="1.5"></rect>
          <line x1="1.28571429" y1="7" x2="16.6567582" y2="7" id="Path" stroke="#AAAAAA" stroke-width="2"></line>
          <line x1="4.5" y1="11.25" x2="8.33571429" y2="11.25" id="Stroke-137" stroke="#AAAAAA" stroke-width="2"></line>
          <line x1="11.7142598" y1="11.25" x2="13.4947309" y2="11.25" id="Stroke-138" stroke="#AAAAAA" stroke-width="2"></line>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconSettingsStripe',
  props: ['stroke', 'fill']
}

</script>
