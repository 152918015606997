<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="icon_edit_24" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
          <g id="Icon" transform="translate(1.000000, 1.000000)" stroke="#AAAAAA" stroke-width="2">
              <polyline id="Stroke-80" points="17 14.8782993 17 21 0 21 0 4 7.24806333 4"></polyline>
              <polygon id="Stroke-82" points="11.60755 14.12525 21.40755 4.28725 17.17555 0.07175 7.37555 9.90975 6.59255 14.92825"></polygon>
              <line x1="14.64255" y1="2.9864" x2="18.53155" y2="6.8754" id="Stroke-84"></line>
          </g>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconEdit',
  props: ['stroke', 'fill']
}

</script>
