<template>
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1429 7C12.1429 8.18329 11.1833 9.14286 10 9.14286C8.81672 9.14286 7.85715 8.18329 7.85715 7C7.85715 5.81672 8.81672 4.85715 10 4.85715C11.1833 4.85715 12.1429 5.81672 12.1429 7V7Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 7C2.28443 3.661 6.60443 1 10.0163 1C13.4286 1 17.6753 3.61643 19 6.91643" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 7C2.28443 10.339 6.60443 13 10.0163 13C13.4286 13 17.6753 10.3836 19 7.08357" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>

export default {
  name: 'IconView',
  props: ['stroke', 'fill']
}

</script>
