<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" :fill="fill"/>
  <path d="M22 12C22 17.522 17.522 22 12 22C6.477 22 2 17.522 2 12C2 6.477 6.477 2 12 2" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 6V12L16 14" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 2C17.522 2 22 6.477 22 12" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="1 3"/>
  </svg>
</template>

<script>

export default {
  name: 'IconAppointment',
  props: ['stroke', 'fill']
}

</script>
