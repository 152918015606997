<template>
<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="icon_contact_email_green_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(1.000000, 4.000000)" :stroke="stroke" stroke-width="2">
            <path d="M1.497375,0 L16.502625,0 C17.3296024,-1.5191328e-16 18,0.670397623 18,1.497375 L18,10.502625 C18,11.3296024 17.3296024,12 16.502625,12 L1.497375,12 C0.670397623,12 1.64711992e-15,11.3296024 0,10.502625 L0,1.497375 C-1.0127552e-16,0.670397623 0.670397623,-2.9217593e-16 1.497375,0 Z" id="Rectangle-7"></path>
            <polyline id="Path" stroke-linecap="round" stroke-linejoin="round" points="17 1 8.9925966 8 1 1"></polyline>
            <path d="M17,11 L11.4069446,6 L17,11 Z" id="Path" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M6.59627879,6 L1,11 L6.59627879,6 Z" id="Path" stroke-linecap="round" stroke-linejoin="round"></path>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconMessage',
  props: ['stroke', 'fill']
}

</script>
