<template>
  <div id="app">
    <!-- <nav-bar/> -->
    <aside-menu :menu="menu"/>
    <router-view/>
    <aside-right/>
  </div>
</template>

<style lang="scss">

</style>
<script>
// import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import AsideRight from '@/components/AsideRight'

export default {
  components: { AsideRight, AsideMenu },
  computed: {
    menu () {
      return [
        {
          to: '/dashboard',
          icon: 'home',
          label: 'Dashboard'
        },
        {
          to: '/clients',
          label: 'Clients',
          icon: 'client'
        },
        // {
        //   to: '/suppliers',
        //   label: 'Suppliers',
        //   icon: 'package'
        // },
        {
          to: '/calendar',
          icon: 'calendar',
          label: 'Appointments'
        },
        // {
        //   label: 'Accounting',
        //   subLabel: 'Features',
        //   icon: 'accounting',
        //   menu: [
        //     {
        //       to: 'quotes',
        //       label: 'Quotes',
        //       icon: 'clipboard'
        //     },
        //     {
        //       to: 'invoices',
        //       label: 'Invoices',
        //       icon: 'accounting'
        //     },
        //     {
        //       to: 'expenses',
        //       label: 'Expenses',
        //       icon: 'receipt'
        //     }
        //   ]
        // },
        {
          to: '/quotes',
          label: 'Quotes',
          icon: 'clipboard'
        },
        {
          to: '/invoices',
          label: 'Invoices',
          icon: 'accounting'
        },
        // {
        //   to: '/expenses',
        //   label: 'Expenses',
        //   icon: 'receipt'
        // },
        {
          to: '',
          label: 'Expand',
          icon: 'expand',
          expand: true
        },
        {
          to: '/reports',
          icon: 'pie',
          label: 'Reports'
        },
        {
          to: '/settings',
          icon: 'cog',
          label: 'Settings',
          updateMark: false
        },
        {
          to: '/logout',
          icon: 'logout',
          label: 'Logout',
          updateMark: false
        }
      ]
    }
  },
  created () {
    this.$store.dispatch('refreshSanctumToken')
  }
}
</script>
