<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="icon_contact_address_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M10.0306171,19 C10.3033445,19 16.0612342,10.361235 16.0612342,7.03061712 C16.0612342,3.69999925 13.361235,1 10.0306171,1 C6.69999925,1 4,3.69999925 4,7.03061712 C4,10.361235 9.75788975,19 10.0306171,19 Z" id="Oval-4" :stroke="stroke" stroke-width="2"></path>
        <circle id="Oval-5" :stroke="stroke" stroke-width="2" cx="10.148867" cy="7.14886698" r="2.14886698"></circle>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconPlace',
  props: ['stroke', 'fill']
}

</script>
