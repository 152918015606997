<template>
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M5.00129 0C4.449 0 4.00129 0.447715 4.00129 1V6.4681L1.72805 4.20667C1.33422 3.81488 0.69313 3.82007 0.300133 4.2127C-0.0945783 4.60704 -0.0984413 5.25153 0.297109 5.64503L4.30078 9.6279C4.43422 9.76065 4.59606 9.84783 4.76702 9.88979C5.09794 9.97451 5.46378 9.88737 5.72288 9.6284L9.714 5.63934C10.1048 5.24871 10.1048 4.61515 9.71385 4.22461L9.69339 4.20417C9.30288 3.81403 8.67012 3.8141 8.27969 4.20432L6.00129 6.48156V1C6.00129 0.447715 5.55357 0 5.00129 0Z" :fill="fill"/>
  </svg>
</template>

<script>

export default {
  name: 'IconArrowDown',
  props: ['stroke', 'fill']
}

</script>
