<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g opacity="0.7">
  <path d="M4.85714 16H3.41071H1.48214C1.24557 16 1 15.824 1 15.607V7.35696C1 6.05515 2.20471 5 3.625 5H16.4821C17.9024 5 19 6.05515 19 7.35696V15.607C19 15.824 18.8616 16 18.625 16H16.8036H15.1429" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 18.5556C15 18.8009 14.7964 19 14.5455 19H5.45455C5.20364 19 5 18.8009 5 18.5556V11.4444C5 11.1991 5.20364 11 5.45455 11H14.5455C14.7964 11 15 11.1991 15 11.4444V18.5556V18.5556Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M4 4V1.375C4 1.168 4.26446 1 4.51923 1H15.5962C15.8509 1 16 1.168 16 1.375V4" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  </svg>

</template>

<script>

export default {
  name: 'IconPrinter',
  props: ['stroke', 'fill']
}

</script>
