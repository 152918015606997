<template>
  <li :class="{'is-active':isDropdownActive, 'is-hidden-mobile':isExpand, 'aside-text': true}" @mouseover="stroke = '#FFFFFF'" @mouseleave="stroke = '#6C758A'">
    <component :is="componentIs" :to="itemTo" :href="itemHref" @click="menuClick" exact-active-class="is-active" :class="{'has-icon':!!item.icon}">
        <div class="menu-item-wrapper">
          <div class="badge-container" v-if="item.updateMark">
            <div class="badge">
              <icon v-if="item.icon" :icon="itemIcon" width="24" height="24" class="menu-item-display"/>
            </div>
          </div>
          <div v-else>
            <icon v-if="item.icon" :icon="itemIcon" :stroke="stroke" :fill="'#2D3958'" width="24" height="24" class="menu-item-display"/>
          </div>
          <span v-if="item.label" :class="{'menu-item-label':!!item.icon}">{{ itemLabel }}</span>
        </div>
    </component>
    <aside-menu-list
        v-if="hasDropdown"
        :menu="item.menu"
        :isSubmenuList="true"/>
  </li>
</template>

<style lang="scss">
.aside-tooltip {
  z-index: 10000;
}

.aside-text span {
  color: #CCC;
}

.aside-text:hover span {
  color: #FFF;
}

</style>

<script>
import { mapState } from 'vuex'
import Icon from '@/components/Icons/Icon'

export default {
  components: { Icon },
  name: 'AsideMenuItem',
  data () {
    return {
      isDropdownActive: false,
      stroke: '#6C758A'
    }
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    menuClick () {
      if (this.isExpand) {
        this.$store.commit('asideStateToggle')
        return true
      }
      this.$emit('menu-click', this.item)
      if (this.hasDropdown) {
        this.isDropdownActive = (!this.isDropdownActive)

        if (!this.isAsideMobileExpanded) {
          this.$store.commit('asideStateToggle', true)
        }
      }
    }
  },
  computed: {
    componentIs () {
      return this.item.to ? 'router-link' : 'a'
    },
    hasDropdown () {
      return !!this.item.menu
    },
    isExpand () {
      return !!this.item.expand
    },
    itemTo () {
      return this.item.to ? this.item.to : null
    },
    itemHref () {
      return this.item.href ? this.item.href : null
    },
    itemLabel () {
      if (this.isAsideExpanded && this.item.expand) {
        return 'Collapse'
      }
      return this.item.label
    },
    itemIcon () {
      if (this.isAsideExpanded && this.item.expand) {
        return 'menuCollapse'
      }
      return this.item.icon
    },
    ...mapState([
      'isAsideExpanded',
      'isAsideMobileExpanded'
    ])
  },
  watch: {
    isAsideExpanded (newValue) {
      if (!newValue) {
        this.isDropdownActive = false
      }
    }
  }
}
</script>

<style scoped>
.menu-item-display {
  display: flex;
}

li a {
  justify-content: left;
}

</style>
