<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="icon_contact_call_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M14.4480191,12.2982603 C13.1379977,12.8973476 13.2460328,13.7985912 11.4447397,12.897639 C9.6434466,11.9966868 6.53324026,10.079891 6.53324026,9.40346016 C6.53324026,9.04588678 6.74236381,8.37987542 7.16061091,7.4054261 L7.16061826,7.40542926 C7.38782737,6.87606811 7.29121718,6.26330291 6.91215743,5.82952773 C5.67742518,4.41656786 4.43135359,3.47990652 3.17394265,3.0195437 C2.1704616,2.65214959 -2.23736248,7.51575264 5.40950901,13.2960004 C13.0563805,19.0762481 17.5005906,18.5365966 18.4266787,16.0145002 C18.579803,15.5974837 19,14.6100509 19,14.1398369 C19,13.463157 15.3753225,11.8741943 14.4480191,12.2982603 Z" id="phone" :stroke="stroke" stroke-width="2"></path>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconPhone',
  props: ['stroke', 'fill']
}

</script>
