import Http from '@/http'

const LanguagesStore = {
  namespaced: true,
  state: () => ({
    languages: []
  }),
  actions: {
    fetch ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'languages'
      return new Promise((resolve, reject) => {
        if (state.languages.length === 0) {
          Http.get(url).then(response => {
            commit('setLanguages', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        } else {
          resolve()
        }
      })
    }
  },
  getters: {
    languages (state) {
      return state.languages
    }
  },
  mutations: {
    setLanguages (state, language) {
      state.languages = language
    }
  }
}

export default LanguagesStore
