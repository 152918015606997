<template>
  <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="2" y="7" width="20" height="14" rx="2" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16 7V5C16 3.89543 15.1046 3 14 3H10C8.89543 3 8 3.89543 8 5V7" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22 10V12C22 13.6569 20.6569 15 19 15H5C3.34315 15 2 13.6569 2 12V10" :stroke="stroke" stroke-width="2"/>
  <path d="M16.5 14V17" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.5 14V17" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>icon_business_24</title>
      <g id="icon_business_24" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="briefcase-(1)" transform="translate(2.000000, 3.000000)" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
              <rect id="Rectangle" x="0" y="4" width="20" height="14" rx="2"></rect>
              <path d="M14,4 L14,2 C14,0.8954305 13.1045695,0 12,0 L8,0 C6.8954305,0 6,0.8954305 6,2 L6,4" id="Path"></path>
          </g>
          <path d="M22,10 L22,12 C22,13.6568542 20.6568542,15 19,15 L5,15 C3.34314575,15 2,13.6568542 2,12 L2,10 L2,10" id="Path" stroke="#AAAAAA" stroke-width="2"></path>
          <line x1="16.5" y1="14" x2="16.5" y2="17" id="Path" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
          <line x1="7.5" y1="14" x2="7.5" y2="17" id="Path" stroke="#AAAAAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconBusiness',
  props: ['stroke', 'fill']
}

</script>
