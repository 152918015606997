<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_copy_20</title>
    <g id="icon_copy_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-10" transform="translate(3.000000, 1.000000)" stroke="#AAAAAA" stroke-width="2">
            <path d="M1,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,17 C12,17.5522847 11.5522847,18 11,18 L1,18 C0.44771525,18 6.76353751e-17,17.5522847 0,17 L0,4 C-6.76353751e-17,3.44771525 0.44771525,3 1,3 Z" id="Rectangle"></path>
            <path d="M3,1 C3,0.44771525 3.44771525,0 4,0 L14,0 C14.5522847,0 15,0.44771525 15,1 L15,14 C15,14.5522847 14.5522847,15 14,15" id="Path"></path>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconCopy',
  props: ['stroke', 'fill']
}

</script>
