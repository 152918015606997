<template>
  <svg width="39" height="42" viewBox="0 0 39 42" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.15559 37.5691L18.5349 41.8186C19.1188 42.0576 20.0677 42.0606 20.6516 41.8265L31.0552 37.6546C31.6403 37.4199 32.3884 36.7677 32.7244 36.2005L38.7108 26.095C39.0475 25.5266 39.0979 24.577 38.8213 23.9696L32.5149 10.1215C32.2392 9.51605 31.5733 8.76142 31.0266 8.4354L20.3662 0.245687C19.8199 -0.0800803 18.9325 -0.0822205 18.3834 0.241265L7.77456 8.32396C7.22578 8.64729 6.56063 9.40307 6.2901 10.0094L0.171858 23.7219C-0.0992049 24.3295 -0.0439384 25.2785 0.29753 25.8454L6.482 36.1129C6.82247 36.6781 7.57298 37.3306 8.15559 37.5691ZM10.2442 21.2619L18.3889 11.3098C18.9383 10.6385 19.8365 10.6288 20.3957 11.2889L28.7531 21.1553C29.312 21.8151 29.6529 23.0699 29.5147 23.9563L28.4465 30.8109C28.3082 31.6981 27.6085 32.761 26.8794 33.1874L20.5566 36.8857C19.8294 37.3111 18.656 37.304 17.933 36.8683L12.0032 33.2949C11.2814 32.8599 10.5923 31.786 10.4643 30.8986L9.48149 24.0861C9.35332 23.1977 9.69467 21.9334 10.2442 21.2619Z" :fill="fill"/>
  </svg>

</template>

<script>

export default {
  name: 'IconBunya',
  props: ['stroke', 'fill']
}

</script>
