<template>
  <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="icon_snowman_31" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group" transform="translate(14.000000, 7.000000)" fill="#AAAAAA">
              <circle id="Oval" cx="1.5" cy="1.5" r="1.5"></circle>
              <circle id="Oval" cx="1.5" cy="15.5" r="1.5"></circle>
              <circle id="Oval" cx="1.5" cy="8.5" r="1.5"></circle>
          </g>
      </g>
  </svg>

</template>

<script>

export default {
  name: 'IconSnowman',
  props: ['stroke', 'fill']
}

</script>
