import Http from '@/http'

const AlbumsStore = {
  namespaced: true,
  state: () => ({
    albums: [],
    thumbnails: []
  }),
  actions: {
    create ({ commit }, album) {
      const url = process.env.VUE_APP_ROOT_API + 'albums'
      return new Promise((resolve, reject) => {
        Http.post(url, { album: album }).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetch ({ commit }) {
      const url = process.env.VUE_APP_ROOT_API + 'albums'
      return new Promise((resolve, reject) => {
        Http.get(url).then(
          response => {
            if (!response.data) {
              reject(response)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetchThumbnails ({ state, commit }, albumId) {
      if (state.thumbnails[albumId]) {
        return true
      }
      const url = process.env.VUE_APP_ROOT_API + 'albums/' + albumId + '/thumbnails'
      return new Promise((resolve, reject) => {
        const thumbnailParams = {
          thumbnail_width: 256,
          thumbnail_height: 256
        }
        Http.get(url, { params: thumbnailParams })
          .then(
            response => {
              if (!response.data) {
                reject(response)
              }
              commit('setThumbnails', {
                albumId: albumId,
                thumbnails: response.data
              })
              resolve(response)
            },
            error => {
              reject(error)
            }
          )
      })
    }
  },
  getters: {
    all (state) {
      return state.albums
    },
    albumThumbnails: (state) => (albumId) => {
      if (state.thumbnails.length > 0 && state.thumbnails[albumId]) {
        return state.thumbnails[albumId]
      }
      return false
    }
  },
  mutations: {
    setAlbums (state, albums) {
      state.albums = albums
    },
    setThumbnails (state, payload) {
      state.thumbnails[payload.albumId] = payload.thumbnails
    }
  }
}

export default AlbumsStore
