<template>
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_expenses_30</title>
    <g id="icon_expenses_30" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <rect id="Rectangle" :stroke="stroke" stroke-width="2" :fill="fill" x="1" y="1" width="28" height="28" rx="4.5"></rect>
      <path d="M15,6 C15,17.3819853 15,23.4063112 15,24.0729779 C15,23.4063112 15,17.3819853 15,6 Z" id="Path" stroke="#1BCA8E" stroke-width="2"></path>
      <path d="M19.1666667,9 L12.9166667,9 C11.3058362,9 10,10.3431458 10,12 C10,13.6568542 11.3058362,15 12.9166667,15 L17.0833334,15 C18.6941639,15 20,16.3431458 20,18 C20,19.6568542 18.6941639,21 17.0833334,21 L10,21" id="Path" stroke="#1BCA8E" stroke-width="2"></path>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconTimelineInvoice',
  props: ['stroke', 'fill']
}

</script>
