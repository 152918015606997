<template>
  <div class="modal-card">
    <header class="modal-card-head modal-header">
      <p class="modal-header-text">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <div class="subsection">
        <h3>Select client contacts to email.</h3>
        <b-field v-for="(emailAddress, index) in emailAddresses" :key="index" class="checkbox-field">
          <b-checkbox :id="'selected_recipient_' + index" v-model="selectedRecipients" :native-value="emailAddress.email" type="is-success">
            {{ emailAddress.name }} <span class="is-hidden-mobile">&lt;{{ emailAddress.email }}&gt;</span>
          </b-checkbox>
        </b-field>
      </div>
      <div class="subsection">
        <h3>Additional email recipients</h3>
        <b-field message="Email addresses separated by comma or semicolon.">
          <b-input placeholder="sam@mail.com; sarah@mail.com" v-model="additionalRecipients"></b-input>
        </b-field>
      </div>
      <div class="subsection">
        <h3>Customise message</h3>
        <quill-editor v-model="messageBody" :options="quillOptions"></quill-editor>
      </div>
    </section>
    <footer class="modal-card-foot modal-footer is-right">
      <button class="button is-light" type="button"  @click="$parent.close()">Close</button>
      <button class="button is-primary" :disabled="isSendDisabled" @click="send">Send</button>
    </footer>
  </div>
</template>

<style>

  .ql-editor{
    min-height:80px;
  }

  h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .checkbox-field {
    margin-left: 20px;
  }

  .subsection {
    padding-bottom: 10px;
  }

</style>

<script>

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  data () {
    return {
      selectedRecipients: [],
      additionalRecipients: '',
      messageBody: ''
    }
  },
  components: {
    quillEditor
  },
  computed: {
    isSendDisabled () {
      if (this.selectedRecipients.length === 0 && this.additionalRecipients === '') {
        return true
      }
      if (this.messageBody === '') {
        return true
      }
      return false
    },
    quillOptions () {
      return {
        modules: {
          toolbar: false
        },
        theme: 'snow'
      }
    }
  },
  methods: {
    send () {
      this.$emit('send-email', {
        selectedRecipients: this.selectedRecipients,
        additionalRecipients: this.additionalRecipients,
        messageBody: this.messageBody
      })
      this.$parent.close()
    }
  },
  name: 'SendEmail',
  mounted () {
    this.messageBody = this.defaultMessageBody
  },
  props: ['title', 'emailAddresses', 'defaultMessageBody']
}
</script>

<style>
  .is-left{
    text-align: left;
  }
</style>
