import Http from '@/http'
import moment from 'moment'

const InvoicesStore = {
  namespaced: true,
  state: () => ({
    invoice: {
      items: [],
      payments: []
    },
    invoices: [],
    invoicesGraphData: null
  }),
  actions: {
    resetInvoice ({ commit }) {
      const billedAt = moment().toDate()
      const dueAt = moment().add('14', 'days').toDate()
      const invoice = {
        finalized: false,
        billedAt: billedAt,
        dueAt: dueAt,
        tax_inclusive: true,
        status: 'New',
        items: [
          {
            id: null,
            index: null,
            description: '',
            quantity: 0,
            unit_price: 0,
            tax_rate: {},
            tax_rate_group: {},
            tax_rate_id: 1,
            total_price: 0
          }
        ],
        payments: []
      }
      commit('setInvoice', invoice)
    },
    deleteInvoice ({ commit }, invoiceId) {
      const url = process.env.VUE_APP_ROOT_API + 'invoices/' + invoiceId
      return new Promise((resolve, reject) => {
        Http.delete(url)
          .then(response => { resolve(response) }, error => { reject(error) })
      })
    },
    sendInvoiceEmail ({ commit, state }, payload) {
      const url = process.env.VUE_APP_ROOT_API + 'invoices/' + payload.invoiceId + '/email'
      return new Promise((resolve, reject) => {
        Http.post(url, payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    sendReminder ({ commit, state }, payload) {
      const url = process.env.VUE_APP_ROOT_API + 'invoices/' + payload.invoiceId + '/send-reminder'
      return new Promise((resolve, reject) => {
        Http.post(url, payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    recordPayment ({ commit, state }, payload) {
      const invoicePayment = {
        invoice_id: payload.invoiceId,
        payment_method_id: payload.paymentMethodId,
        amount: payload.paymentAmount,
        reference: payload.paymentReference,
        paid_at: moment(payload.paymentDate).format('YYYY-MM-DD')
      }
      const url = process.env.VUE_APP_ROOT_API + 'invoices/' + payload.invoiceId + '/payment'
      return new Promise((resolve, reject) => {
        Http.post(url, { invoice_payment: invoicePayment }).then(response => {
          commit('setInvoice', response.data.invoice)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    deletePayment ({ commit, state }, invoicePaymentIndex) {
      const payment = state.invoice.payments[invoicePaymentIndex]
      const url = process.env.VUE_APP_ROOT_API + 'invoice-payments/' + payment.id
      return new Promise((resolve, reject) => {
        Http.delete(url).then(response => {
          commit('setInvoicePaymentDeleted', invoicePaymentIndex)
          commit('setInvoice', response.data.invoice)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    fetch ({ commit }) {
      const url = process.env.VUE_APP_ROOT_API + 'invoices'
      const payload = { filters: ['exclude-historic-paid', 'exclude-bad-debtors'] }
      return new Promise((resolve, reject) => {
        Http.get(url, { params: payload }).then(
          response => {
            if (response.data) {
              commit('setInvoices', response.data)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    updateInvoiceItem ({ commit, state, getters }, payload) {
      const invoiceItem = {
        id: payload.item.id,
        index: payload.item.index,
        invoice_id: state.invoice.id,
        description: payload.item.description,
        quantity: payload.item.quantity,
        unit_price: payload.item.unitPrice,
        tax_rate: payload.item.taxRate,
        total_price: (payload.item.unitPrice * payload.item.quantity).toFixed(2),
        deleted: payload.item.deleted
      }
      commit('setInvoiceItem', {
        index: payload.item.index,
        item: invoiceItem
      })
    },
    updateTaxInclusiveStatus ({ commit, state }, taxInclusive) {
      commit('setInvoiceTaxInclusive', taxInclusive)
    },
    deleteInvoiceItem ({ commit }, invoiceItemIndex) {
      commit('setInvoiceItemDeleted', invoiceItemIndex)
    },
    fetchInvoice ({ commit }, payload) {
      const url = process.env.VUE_APP_ROOT_API + 'invoices/' + payload.invoiceId
      Http.get(url)
        .then((response) => {
          if (response.data) {
            commit('setInvoice', response.data)
          }
        })
    },
    saveInvoice ({ state, getters, commit }) {
      let url = process.env.VUE_APP_ROOT_API + 'invoices'
      if (state.invoice.id) {
        url = url + '/' + state.invoice.id
      }
      return new Promise((resolve, reject) => {
        const invoice = state.invoice
        invoice.billed_at = moment(invoice.billedAt).format('YYYY-MM-DD')
        invoice.due_at = moment(invoice.dueAt).format('YYYY-MM-DD')
        invoice.amount = getters.invoiceTotal
        invoice.items.map((item) => {
          item.tax_rate_code = item.tax_rate.code
          if (item.deleted) {
            item.deleted_at = moment().format('YYYY-MM-DD')
          }
          return item
        })
        if (invoice.id) {
          Http.put(url, { invoice: invoice }).then(response => {
            commit('setInvoice', response.data)
            resolve({ isNew: false, invoice: response.data })
          }, error => {
            reject(error)
          })
        } else {
          Http.post(url, { invoice: invoice }).then(response => {
            commit('setInvoice', response.data)
            resolve({ isNew: true, invoice: response.data })
          }, error => {
            reject(error)
          })
        }
      })
    },
    fetchGraphData ({ commit }) {
      const url = process.env.VUE_APP_ROOT_API + 'invoices/graph-data'
      Http.get(url)
        .then((response) => {
          if (response.data) {
            const invoicesGraphData = {
              // TODO Localize labels
              chartData: {
                labels: response.data.labels,
                datasets: [
                  {
                    label: 'Recent Income',
                    backgroundColor: response.data.colors,
                    data: response.data.values
                  }
                ]
              },
              extraOptions: {
                responsive: true,
                maintainAspectRatio: false
              }
            }
            commit('setInvoicesGraphData', invoicesGraphData)
          }
        })
    }
  },
  getters: {
    invoice (state) {
      return state.invoice
    },
    invoiceItems (state) {
      return state.invoice.items
        .map((invoiceItem, index) => {
          invoiceItem.index = index
          return invoiceItem
        })
        .filter((invoiceItem) => {
          return !invoiceItem.deleted
        })
    },
    invoicePayments (state) {
      return state.invoice.payments
        .map((invoicePayment, index) => {
          invoicePayment.index = index
          return invoicePayment
        })
        .filter((invoicePayment) => {
          return !invoicePayment.deleted
        })
    },
    invoiceSubtotal (state, getters) {
      let subtotal = 0
      state.invoice.items
        .filter((invoiceItem) => { return !invoiceItem.deleted })
        .forEach((invoiceItem) => {
          subtotal += invoiceItem.quantity * invoiceItem.unit_price
        })
      return subtotal
    },
    invoiceTaxTotal (state) {
      let taxTotal = 0
      const taxInclusive = state.invoice.tax_inclusive
      state.invoice.items
        .filter((invoiceItem) => { return !invoiceItem.deleted })
        .forEach((invoiceItem) => {
          const itemTotal = invoiceItem.quantity * invoiceItem.unit_price
          const taxRate = invoiceItem.tax_rate.rate
          if (taxInclusive) {
            taxTotal += itemTotal - (itemTotal / (1 + taxRate))
          } else {
            taxTotal += itemTotal * taxRate
          }
        })
      if (!taxTotal) {
        taxTotal = 0
      }
      return taxTotal.toFixed(2)
    },
    invoicePaidTotal (state) {
      let paidTotal = 0
      if (state.invoice.payments.length) {
        state.invoice.payments
          .filter((invoicePayment) => { return !invoicePayment.deleted })
          .forEach((invoicePayment) => {
            paidTotal += invoicePayment.amount
          })
      }
      return paidTotal.toFixed(2)
    },
    invoiceUnpaidTotal (state, getters) {
      return (getters.invoiceTotal - getters.invoicePaidTotal).toFixed(2)
    },
    invoiceTotal (state) {
      let invoiceTotal = 0
      state.invoice.items
        .filter((invoiceItem) => { return !invoiceItem.deleted })
        .forEach((invoiceItem) => {
          if (state.invoice.tax_inclusive) {
            invoiceTotal += invoiceItem.quantity * invoiceItem.unit_price
          } else {
            invoiceTotal += invoiceItem.quantity * invoiceItem.unit_price * (1 + invoiceItem.tax_rate.rate)
          }
        })
      return invoiceTotal.toFixed(2)
    },
    invoiceItem: (state, getters) => (itemIndex) => {
      return state.invoice.items[itemIndex]
    },
    paid (state) {
      return state.invoices.filter((invoice) => {
        return (invoice.finalized && invoice.paid_at !== null)
      })
    },
    unpaid (state) {
      return state.invoices.filter((invoice) => {
        return (invoice.finalized && invoice.paid_at === null)
      })
    },
    unpaidNotOverdue (state) {
      return state.invoices.filter((invoice) => {
        return (invoice.finalized && invoice.paid_at === null && !invoice.is_overdue)
      })
    },
    unpaidOverdue (state) {
      return state.invoices.filter((invoice) => {
        return (invoice.finalized && invoice.paid_at === null && invoice.is_overdue)
      })
    },
    draft (state) {
      return state.invoices.filter((invoice) => {
        return (invoice.finalized === false)
      })
    },
    invoiceFinalized (state) {
      return !(state.invoice.finalized !== true)
    },
    graphData (state) {
      return state.invoicesGraphData
    }
  },
  mutations: {
    setInvoice (state, invoice) {
      const billedAtDate = (invoice.billed_at) ? moment(invoice.billed_at) : moment()
      const dueAtDate = (invoice.due_at) ? moment(invoice.due_at) : moment().add('14', 'days')
      invoice.billedAt = billedAtDate.toDate()
      invoice.dueAt = dueAtDate.toDate()
      invoice.items.map((invoiceItem) => {
        invoiceItem.deleted = false
        return invoiceItem
      })
      invoice.payments.map((invoicePayment) => {
        invoicePayment.deleted = false
        return invoicePayment
      })
      state.invoice = invoice
    },
    setInvoices (state, invoices) {
      state.invoices = invoices
    },
    setInvoicesGraphData (state, invoicesGraphData) {
      state.invoicesGraphData = invoicesGraphData
    },
    deleteInvoiceItem (state, payload) {
      state.invoice.items.splice(payload.index, 1)
    },
    setInvoiceItem (state, payload) {
      if (payload.index === null) {
        state.invoice.items.push(payload.item)
      } else {
        state.invoice.items.splice(payload.index, 1, payload.item)
      }
    },
    setInvoiceItemDeleted (state, invoiceItemIndex) {
      state.invoice.items[invoiceItemIndex].deleted = true
    },
    setInvoicePaymentDeleted (state, invoicePaymentIndex) {
      state.invoice.payments[invoicePaymentIndex].deleted = true
    },
    setInvoiceTaxInclusive (state, taxInclusive) {
      // const invoiceItems = state.invoice.items.map((item) => {
      //   if (taxInclusive) {
      //     item.total_price = item.quantity * item.unit_price
      //   } else {
      //     let taxRate = 0
      //     if (item.tax_rate_group) {
      //       taxRate = item.tax_rate_group.rate
      //     }
      //     if (item.tax_rate) {
      //       taxRate = item.tax_rate.rate
      //     }
      //     item.total_price = item.quantity * item.unit_price * (1 + taxRate)
      //   }
      //   return item
      // })
      // state.invoice.items = invoiceItems
      state.invoice.tax_inclusive = taxInclusive
    }
  }
}

export default InvoicesStore
