<template>
  <section v-if="componentName">
    <component
      :is="componentName"
      v-for="(item, index) in items"
      :key="index"
      :quote="item"
      :invoice="item"
    />
  </section>
</template>

<script>
import InvoiceCard from './InvoiceCard'
import QuoteCard from './QuoteCard'

export default {
  name: 'CardColumn',
  props: ['items', 'component'],
  components: {
    InvoiceCard: InvoiceCard,
    QuoteCard: QuoteCard
  },
  computed: {
    componentName () {
      if (this.component === 'InvoiceCard') {
        return 'InvoiceCard'
      }
      if (this.component === 'QuoteCard') {
        return 'QuoteCard'
      }
      return null
    }
  },
  data () {
    return {}
  }
}
</script>
