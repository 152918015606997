import store from './store'
import Axios from 'axios'
import router from './router'
import { Storage } from '@capacitor/storage'

const http = Axios.create({
  baseURL: process.env.API_ROOT,
  headers: {
    Accept: 'application/json'
  },
  withCredentials: true,
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  timeout: 30000
})

let accessToken = null

// Add a request interceptor
http.interceptors.request.use(
  async request => {
    if (process.env.NODE_ENV === 'mobile') {
      if (accessToken === null) {
        accessToken = await Storage.get({ key: 'accessToken' })
      }
      if (accessToken !== null) {
        http.defaults.headers.common.Authorization = 'Bearer ' + accessToken.value
      }
    }

    const source = Axios.CancelToken.source()
    request.cancelToken = source.token
    store.commit('cancellationTokens/addCancellationToken', source)

    return request
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (Axios.isCancel(error)) {
      return false
    }
    if (error.response.status === 401) {
      if (router.currentRoute.name !== 'login') {
        router.push('/login')
      }
    }
    return Promise.reject(error)
  }
)

export default http
