const CancellationTokensStore = {
  namespaced: true,
  state: () => ({
    cancellationTokens: []
  }),
  actions: {
    cancelPendingRequests (context) {
      context.state.cancellationTokens.forEach((request, i) => {
        if (request.cancel) {
          request.cancel()
        }
      })
      context.commit('clearCancellationTokens')
    }
  },
  getters: {
    cancellationTokens (state) {
      return state.cancellationTokens
    }
  },
  mutations: {
    addCancellationToken (state, token) {
      state.cancellationTokens.push(token)
    },
    clearCancellationTokens (state) {
      state.cancellationTokens = []
    }
  }
}

export default CancellationTokensStore
