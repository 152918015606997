<template>
  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>icon_sml_theme_18</title>
      <g id="icon_sml_theme_18" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
          <path d="M9.01607143,7.17535854 C6.29352857,7.17535854 4.0875,4.72076806 4.0875,2 L13.9446429,2 C13.9446429,4.72076806 11.7386143,7.17535854 9.01607143,7.17535854 Z" id="Stroke-365" stroke="#AAAAAA" stroke-width="2"></path>
          <polyline id="Stroke-366" stroke="#AAAAAA" stroke-width="2" points="5.95690714 6.10754081 4.08799286 10.1305737 9.01656429 16.0410039 13.9451357 10.1305737 12.3650357 5.85634752"></polyline>
          <line x1="9.01607143" y1="16.0410039" x2="9.01607143" y2="11.1156454" id="Stroke-367" stroke="#AAAAAA" stroke-width="2"></line>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconSettingsTheme',
  props: ['stroke', 'fill']
}

</script>
