<template>
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.125 9.375C13.125 11.0319 11.7817 12.375 10.125 12.375C8.46825 12.375 7.125 11.0319 7.125 9.375C7.125 7.71806 8.46825 6.375 10.125 6.375C11.7817 6.375 13.125 7.71806 13.125 9.375V9.375Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1 4.375V16.375H19.125V4.375H15.125L13.125 1.375H7L5 4.375H1Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>

export default {
  name: 'IconCamera',
  props: ['stroke', 'fill']
}

</script>
