import Http from '@/http'

const TaxRatesStore = {
  namespaced: true,
  state: () => ({
    taxRates: []
  }),
  actions: {
    fetch ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'tax-rates'
      return new Promise((resolve, reject) => {
        if (state.taxRates.length === 0) {
          Http.get(url).then(response => {
            commit('setTaxRates', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        } else {
          resolve()
        }
      })
    }
  },
  getters: {
    taxRates (state) {
      return state.taxRates
    }
  },
  mutations: {
    setTaxRates (state, taxRate) {
      state.taxRates = taxRate
    }
  }
}

export default TaxRatesStore
