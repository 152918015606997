<template>
  <div class="box bunya-box">
    <div v-on:click="showInvoice">
      <div class="invoice-content invoice-click-target">
        <div class="level is-mobile component-level-margin invoice-click-target">
          <div class="level-left">
            <div class="level-item">
              <div v-if="this.isDraft">
                <h6 :class="[invoiceStatusClass, 'invoice-click-target']">{{ invoice.invoice_number_formatted }}</h6>
              </div>
              <div v-else>
                <h6 class="has-opacity invoice-click-target">{{ invoice.invoice_number_formatted }}</h6>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div v-if="isOverdue">
              <div class="level-item dashboard-date invoice-click-target">
                <h6 class="has-opacity"><b>DUE</b>
                  <span class="invoice-click-target"> {{ invoice.due_at | moment('D MMM YYYY') }}</span></h6>
              </div>
            </div>
            <div v-else-if="this.isDraft">
              <div class="level-item">
                <h6 :class="['dashboard-date', 'invoice-click-target', invoiceStatusClass]">{{ invoice.due_at | moment('D MMM YYYY') }}</h6>
              </div>
            </div>
            <div v-else>
              <div class="level-item">
                <h6 class="dashboard-date has-opacity">{{ invoice.due_at | moment('D MMM YYYY') }}</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="level is-mobile invoice-click-target">
          <div class="level-left">
            <div v-if="this.isDraft">
              <div class="block level-item">
                <h4 :class="[invoiceStatusClass, 'invoice-client-name', 'invoice-click-target']">{{ invoice.client.name }}</h4>
                <h4 class="no-line-height invoice-click-target"><span :class="['amount-text', 'invoice-click-target', invoiceStatusClass]">{{ invoice.currency.code }} {{ invoice.total }}</span></h4>
              </div>
            </div>
            <div v-else>
              <div class="block level-item">
                <h4 class="no-line-height has-dashboard-limit invoice-click-target invoice-client-name">{{ invoice.client.name }}</h4>
                <h4 class="no-line-height invoice-click-target"><span :class="['amount-text', 'invoice-click-target', invoiceStatusClass]">{{ invoice.currency.code }} {{ invoice.total | currency }}</span></h4>
                <span class="reminder-tag tag is-warning" v-if="invoice.reminded_at">Reminder sent {{ invoice.reminded_at | moment('D MMM YYYY') }}</span>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <snowman-menu
                :items="invoiceActions"
                v-on:invoice-pdf-download="invoicePDFDownload"
                v-on:invoice-view="invoiceView"
                :type="'with-hover'"
              ></snowman-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top:-12px;" v-if="isReminderNeeded">
      <div class="overdue-reminder" style="margin-top: 0.25rem;">
        <div class="has-padding-top">
          <status-label text="OVERDUE" labelStyle="is-warning"/>
        </div>
        <div class="level is-mobile fix-overdue">
          <div class="level-left">
            <div class="level-item">
              <h5 class="normal">Let {{ invoice.client.contact_person ? invoice.client.contact_person : 'them' }} know it's overdue?</h5>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <a class="button is-primary" v-on:click="showSendReminderPanel">Reminder</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import moment from 'moment'
import SnowmanMenu from '@/components/SnowmanMenu'
import SendMail from '@/components/SendMail'
import StatusLabel from '@/components/StatusLabel'

export default {
  components: {
    StatusLabel,
    SnowmanMenu
  },
  computed: {
    clientContacts () {
      const baseContact = {
        name: this.invoice.client.name,
        email: this.invoice.client.email
      }
      if (this.invoice.client.type === 'business' && this.invoice.client.contact_person) {
        baseContact.name = this.invoice.client.contact_person
      }
      const clientContacts = [baseContact]
      return clientContacts
    },
    defaultMessageBody () {
      let message = ''
      message += '<p>Payment for invoice <strong>' + this.invoice.invoice_number_formatted + '</strong> is now overdue. '
      message += 'Please pay the outstanding amount of <strong>' + this.$options.filters.currency(this.invoice.amount_unpaid) + '</strong> as soon as possible.' + '</p>'
      return message
    },
    isPaid () {
      return (this.invoice.paid_at)
    },
    isUnpaid () {
      return this.invoice.finalized && this.invoice.paid_at === null
    },
    isDraft () {
      return (this.invoice.finalized === false)
    },
    isOverdue () {
      return (this.invoice.is_overdue === true)
    },
    isReminderNeeded () {
      if (!this.isOverdue) {
        return false
      }
      if (!this.invoice.reminded_at) {
        return true
      }
      const now = moment()
      const daysAgo = now.diff(this.invoice.reminded_at, 'days')
      return (daysAgo > 14)
    },
    invoiceStatusClass () {
      if (this.isPaid) {
        return 'color-paid'
      }
      if (this.isUnpaid) {
        return 'color-unpaid'
      }
      if (this.isDraft) {
        return 'color-draft'
      }
      return ''
    },
    invoiceActions () {
      if (!this.invoice) {
        return []
      }
      return [
        {
          name: 'View Invoice',
          description: 'Load the invoice editor',
          icon: 'view',
          emit: 'invoice-view'
        },
        {
          name: 'Download',
          description: 'Download as PDF',
          icon: 'copy',
          emit: 'invoice-pdf-download'
        }
      ]
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    openCollapse () {
      this.isOpen = this.isOverdue
    },
    invoicePDFDownload () {
      window.open(this.invoice.pdf_link, '_blank')
    },
    invoiceView () {
      this.$router.push({ name: 'invoices-show', params: { id: this.invoice.id } })
    },
    showInvoice (e) {
      if (e.target.classList.contains('invoice-click-target')) {
        this.$router.push({ name: 'invoices-show', params: { id: this.invoice.id } })
      }
    },
    showSendReminderPanel () {
      this.$buefy.modal.open({
        parent: this,
        component: SendMail,
        props: {
          title: 'Send Reminder For ' + this.invoice.invoice_number_formatted,
          emailAddresses: this.clientContacts,
          defaultMessageBody: this.defaultMessageBody
        },
        fullScreen: this.isMobile,
        events: {
          'send-email': (payload) => {
            payload.invoiceId = this.invoice.id
            this.sendReminderEmail(payload)
          }
        },
        hasModalCard: true,
        trapFocus: true
      })
    },
    sendReminderEmail (payload) {
      this.$store.dispatch('invoices/sendReminder', payload)
        .then(() => {
          this.invoice.reminded_at = moment().format('YYYY-MM-DD')
          this.$buefy.toast.open({
            message: 'Email sent.',
            type: 'is-success'
          })
        })
        .catch((e) => {
          this.$buefy.toast.open({
            message: `Error: ${e.response.data.message}`,
            type: 'is-danger'
          })
        })
    }
  },
  name: 'InvoiceCard',
  props: ['invoice']
}
</script>

<style lang="scss" scoped>

  @import "../scss/_theme-default.scss";

  .reminder-tag {
    background-color: #F39E67 !important;
    margin-top: 20px !important;
    padding: 15px !important;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .is-warning {
    color: #fff !important;
    border-radius: 20px !important;
  }

  .fix-overdue {
    .level-left {
      min-width: 0;
      word-break: break-word;
      flex-basis: 49%;

      .level-item {
        flex-shrink: 1;
        justify-content: flex-start;
        // padding: .5em .5em;
      }
    }

    .level-right {
      min-width: 0;
      word-break: break-word;
      flex-basis: 51%;

      .level-item {
        flex-shrink: 1;
        justify-content: flex-end;
        // padding: .5em .5em;
      }
    }
  }
  .has-limit{
    max-width: 220px;
  }
  .invoices-subtitle {
    font-size: 0.95em;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0.75px;
  }

  .color-paid {
    color: $success;
  }

  .color-unpaid {
    color: $warning;
  }

  .color-draft {
    color: #C0C3CC;
  }

  .invoice-content {
    transition:color 3s ease-in;
    cursor: pointer;
  }

  .invoice-content:hover .invoice-client-name  {
    color: $info;
    transition: all 0.5s;
  }

  .status-label {
    border-radius: 0 6px 0 6px;
    padding: 0.5rem;
    margin-top: -25px;
    margin-right: -20px;
    border: 0;
    box-shadow: none;
  }

  .status-label-overdue {
    background-color: $warning;
  }

  .overdue-reminder {
    background-color: #fafafa;
    margin: 1.25rem -1.25rem -1.25rem;
    border-top: 1px solid #E3E3E370;
    border-bottom-left-radius: 7.5px;
    border-bottom-right-radius: 7.5px;
    padding: 4px 1.25rem 0.9375rem;
  }

  .overdue {
    background-color: $warning;
    border-radius: 0 6px 0 6px;
    padding: 0.5rem;
    margin-top: -25px;
    margin-right: -20px;
    border: 0;
    box-shadow: none;
  }

  @media only screen and (max-width: 768px) {
    h4 {
      word-break: break-word;
      max-width: 138px;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
