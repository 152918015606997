import http from '@/http'
import { Storage } from '@capacitor/storage'

const UserStore = {
  namespaced: true,
  state: () => ({
    user: {
      id: null,
      email: null,
      name: null,
      initials: null,
      displayName: null,
      iCalUrl: null
    }
  }),
  actions: {
    fetch ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'me'
      return new Promise((resolve, reject) => {
        http.get(url).then(response => {
          commit('setUser', response.data)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    sendPasswordResetLink ({ commit }, params) {
      const url = process.env.VUE_APP_ROOT_API + 'password-reset'
      return new Promise((resolve, reject) => {
        http.post(url, params).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    confirmPasswordReset ({ commit }, params) {
      const url = process.env.VUE_APP_ROOT_API + 'password-reset-confirm'
      return new Promise((resolve, reject) => {
        http.post(url, params).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    webLogin ({ commit }, params) {
      const url = process.env.VUE_APP_ROOT_API + 'login'
      return new Promise((resolve, reject) => {
        http.post(url, params).then(response => {
          if (response.status === 200) {
            resolve(response)
          }
        }, error => {
          reject(error)
        })
      })
    },
    mobileLogin ({ commit }, params) {
      const url = process.env.VUE_APP_ROOT_API + 'sanctum/token'
      return new Promise((resolve, reject) => {
        http.post(url, params).then(response => {
          if (response.status === 200) {
            http.defaults.headers.common.Authorization = 'Bearer ' + response.data.token
            const accessToken = {
              key: 'accessToken',
              value: response.data.token
            }
            Storage.set(accessToken)
            commit('setUser', response.data.user)
            resolve(response)
          }
        }, error => {
          reject(error)
        })
      })
    },
    logout ({ commit }) {
      const url = process.env.VUE_APP_ROOT_API + 'logout'
      return new Promise((resolve, reject) => {
        http.post(url).then(response => {
          commit('setUser', null)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    email (state) {
      return state.user.email
    },
    displayName (state) {
      if (state.user) {
        return state.user.displayName
      }
    },
    initials (state) {
      return state.user.initials
    },
    name (state) {
      return state.user.name
    },
    iCalUrl (state) {
      if (state.user) {
        return state.user.iCalUrl
      }
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    }
  }
}

export default UserStore
