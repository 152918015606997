<template>
  <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16 3V18C16 18.5523 15.5523 19 15 19H3C2.44772 19 2 18.5523 2 18V3" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M1 3H17" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2V1.33333C6 1.14933 6.2 1 6.44643 1H11.5536C11.8 1 12 1.14933 12 1.33333V2H6Z" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M6.5 8V15" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M11.5 8V15" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  </svg> -->
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Delete</title>
    <g id="icon_delete_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="Group-3" transform="translate(2.000000, 1.000000)" :stroke="stroke" stroke-width="2">
            <path d="M15,2 L15,17 C15,17.5522847 14.5522847,18 14,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,2 L1,2" id="Path"></path>
            <line x1="0" y1="2" x2="16" y2="2" id="Stroke-264"></line>
            <path d="M5,1 L5,0.333333333 C5,0.149333333 5.2,0 5.44642857,0 L10.5535714,0 C10.8,0 11,0.149333333 11,0.333333333 L11,1 L5,1 Z" id="Path"></path>
            <line x1="5.5" y1="7" x2="5.5" y2="14" id="Stroke-272"></line>
            <line x1="10.5" y1="7" x2="10.5" y2="14" id="Stroke-272"></line>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconBin',
  props: ['stroke', 'fill']
}

</script>
