<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="3" y="4" width="18" height="18" rx="2" :fill="fill" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16 2V6" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 2V6" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3 10H21" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15 14L10.875 18L9 16.1818" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg> <!--true version-->

  <!-- <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="4" width="27" height="27" rx="2.95238" fill="white" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M20.1905 1V6.90476" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.38098 1V6.90476" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M1 12.8095H27.5714" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.8571 19L12.7679 24.9048L10 22.2208" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <!-- <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_appointment</title>
    <g id="icon_appointment" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Group" transform="translate(3.000000, 1.000000)" stroke="#1BCA8E" stroke-width="2">
            <g id="calendar-(1)">
                <rect id="Rectangle" fill="#FFFFFF" x="0" y="3" width="24" height="25" rx="2.95238095"></rect>
                <line x1="7" y1="0" x2="7" y2="6.00703562" id="Path"></line>
                <line x1="17" y1="0" x2="17" y2="6.00703562" id="Path"></line>
                <line x1="-1.38555833e-14" y1="11.0222222" x2="24" y2="11.0222222" id="Path"></line>
            </g>
            <polyline id="Path" points="16.2666667 17 10.5833333 22.5111111 8 20.0060606"></polyline>
        </g>
    </g>
</svg> -->
</template>

<script>

export default {
  name: 'IconCalendar',
  props: ['stroke', 'fill']
}

</script>
