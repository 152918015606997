<template>
  <!-- <svg width="32" height="27" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="27" height="27" rx="3" :fill="fill" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 11.5C10.4926 11.5 11.5 10.4926 11.5 9.25C11.5 8.00736 10.4926 7 9.25 7C8.00736 7 7 8.00736 7 9.25C7 10.4926 8.00736 11.5 9.25 11.5Z" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M28 19L20.5 11.5L4 28" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_new_file_20</title>
    <g id="icon_new_file_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="Group-21" transform="translate(1.000000, 1.000000)" stroke="#AAAAAA" stroke-width="2">
            <rect id="Rectangle" x="0" y="0" width="18" height="18" rx="3"></rect>
            <circle id="Oval" cx="5.5" cy="5.5" r="1.5"></circle>
            <polyline id="Path" points="18 12 13 7 2.30630493 17.6936951"></polyline>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconPhoto',
  props: ['stroke', 'fill']
}

</script>
