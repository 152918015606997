<template>
  <div class="columns mobile-header-columns is-hidden-desktop">
    <div class="column is-12">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item" v-on:click="toggleNav">
            <icon icon="hamburger" width="20" height="16" :fill="iconFill"/>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item" v-if="showSearch">
            <b-field class="header-search" expanded>
              <b-input placeholder="Search..." type="search" icon="magnify" expanded>
              </b-input>
            </b-field>
          </div>
          <div class="level-item" v-if="showCreate">
            <a class="button is-primary">Create</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  @import '../scss/_tag-colors';

  .nav-bar-mobile {
    margin: 0px 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    .column {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .column {
    padding: 0px !important;
  }

  .level-item {
    //background-color: crimson;
    min-height: 60px;
    padding-top: 34px;
    padding-bottom: 0px;
    //MARC Hamburger align below
    padding-left: 5px;
    //background-color: crimson;
    //align-content: baseline;
  }

  .tag-color-blue {
      background-color: $bunya-tag-color-blue;
  }
  .tag-color-white {
    background-color: $bunya-tag-color-white;
  }

</style>

<script>

import Icon from '@/components/Icons/Icon'

export default {
  name: 'NavBarMobile',
  components: { Icon },
  computed: {
    iconFill () {
      if (this.colorClass === 'white' || this.colorClass === 'yellow') {
        return '#2D3958'
      }
      return '#FFF'
    },
    showSearch () {
      return false
    },
    showCreate () {
      return false
    },
    colorClass () {
      if (this.color) {
        return this.color
      }
      return 'white'
    }
  },
  methods: {
    toggleNav () {
      this.$store.commit('asideMobileStateToggle')
    }
  },
  props: ['color']
}
</script>
