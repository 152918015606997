<template>
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>icon_file_30</title>
      <g id="icon_file_30" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
          <g id="Group" transform="translate(1.000000, 1.000000)" stroke="#1BCA8E" stroke-width="2">
              <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="28" height="28" rx="4.5"></rect>
              <circle id="Oval" cx="8.25" cy="8.25" r="2.25"></circle>
              <polyline id="Path" points="28 18.8360203 19.5 10.5 2.52421442 27.5012779"></polyline>
          </g>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconClientAlbum',
  props: ['stroke', 'fill']
}

</script>
