
import '@/scss/main.scss'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Buefy from 'buefy'
import VueI18n from 'vue-i18n'
import router from './router'
import store from './store'
import Http from './http'
import './registerServiceWorker'
import App from './App.vue'
import AsideMenuList from '@/components/AsideMenuList'
import i18n from './i18n'
import VueMobileDetection from 'vue-mobile-detection'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit('asideMobileStateToggle', false)
})
Vue.config.productionTip = false
Vue.component('AsideMenuList', AsideMenuList)
Vue.use(Buefy)
Vue.use(VueI18n)
Vue.use(require('vue-moment'))
Vue.use(VueMobileDetection)

Vue.filter('currency', function (value) {
  return value.toFixed(2)
})

Vue.filter('basReportFigure', function (value) {
  const numberFormatter = new Intl.NumberFormat('en-AU')
  return numberFormatter.format(value)
})

Vue.prototype.$http = Http

Sentry.init({
  Vue,
  dsn: 'https://9accba7364f54b6cb7f8c454de343726@o1160390.ingest.sentry.io/6244837',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['staging.bunya.app', 'bunya.app', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount('#app')
