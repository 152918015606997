<template>
  <div>
    <!-- clients list desktop -->
    <div class="box bunya-box is-hidden-mobile">
      <modal-box
        :is-active="isModalActive"
        :trash-object-name="trashObjectName"
        @confirm="trashConfirm"
        @cancel="trashCancel"/>
      <b-table
        :checkable="false"
        :loading="isLoading"
        :striped="false"
        :sort-icon="'chevron-up'"
        :hoverable="true"
        default-sort="name"
        selectable
        @select="loadClient"
        :data="filteredClients">
        <template slot-scope="props">
          <b-table-column class="has-no-head-mobile is-image-cell" width="80px">
            <div class="flex-circle-avatar"><h5>{{ props.row.initials }}</h5></div>
          </b-table-column>
          <b-table-column label="Name" field="name" sortable>
            <h3>{{ props.row.name }}</h3>
          </b-table-column>
          <b-table-column label="Contact" field="contact_person" sortable>
            <h5 class="light normal">{{ props.row.contact_person }}</h5>
          </b-table-column>
          <b-table-column label="Email" field="email" sortable>
            <h5 class="light normal">{{ props.row.email }}</h5>
          </b-table-column>
          <b-table-column label="Modified">
            <small class="has-text-grey is-abbr-like" :title="props.row.updated">{{ props.row.updated_at | moment('Do MMM YYYY') }}</small>
          </b-table-column>
          <b-table-column class="is-hidden">T</b-table-column>
        </template>
        <section class="section" slot="empty">
          <!-- <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>You haven't created any client yet&hellip;</p>
          </div> -->
        </section>
      </b-table>
    </div>
    <!-- end clients list desktop -->

    <!-- clients list mobile -->
    <div class="box bunya-box is-hidden-tablet client-box">
      <div class="has-blue-background">
        <b-field>
          <b-input
            @input="updateFilter"
            :value="filter"
            placeholder="Filter Clients"
            type="search"
            icon="filter-outline"/>
        </b-field>
      </div>
      <div
        v-for="(client, index) in filteredClients" :key="index"
        @click="loadClient(client)"
        class="level is-mobile has-border no-margin-bottom fixed-client-level"
      >
        <div class="level-left">
          <div class="level-item with-zero-grow">
            <div class="flex-circle-avatar"><h5>{{ client.initials }}</h5></div>
          </div>
          <div class="level-item">
            <p class="user-name-text">{{ client.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- end clients list mobile -->

  </div>
</template>

<script>

import ModalBox from '@/components/ModalBox'

export default {
  name: 'ClientsTable',
  components: {
    ModalBox
  },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      clients: []
    }
  },
  computed: {
    filter () {
      return this.$store.getters['clients/filter']
    },
    filteredClients () {
      return this.$store.getters['clients/filtered']
    },
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }
      return null
    }
  },
  props: ['isLoading'],
  methods: {
    clientAdd () {
      this.$router.push({ name: 'clients-create' })
    },
    updateFilter (value) {
      this.$store.dispatch('clients/updateFilter', value)
    },
    loadClient (client) {
      this.$router.push({ path: '/clients/' + client.id })
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.$buefy.snackbar.open({
        message: 'Confirmed',
        queue: false
      })
    },
    trashCancel () {
      this.isModalActive = false
    }
  }
}
</script>

<style lang="scss" scoped>

@media only screen and (min-width: 320px) and (max-width: 1023px) {
.subpage-information-area-margin {
    margin-top: 0px !important;
  }
}
</style>
