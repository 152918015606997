<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="24" height="24" fill="#F43535"/>
  </svg>
</template>

<script>

export default {
  name: 'IconGrid',
  props: ['stroke', 'fill']
}

</script>
