import Http from '@/http'
import moment from 'moment'

const Settings = {
  namespaced: true,
  state: () => ({
    account: null,
    availability: null,
    payment: null,
    theme: null,
    bankAccount: null,
    bankAccounts: null,
    bankInstitutions: null,
    invoiceSettings: null,
    service: null,
    services: null,
    serviceCategories: null,
    serviceCategory: null,
    subscription: null,
    stripe: null,
    taxRateGroup: null,
    taxRateGroups: null,
    taxRates: null,
    countries: null,
    timeZones: null,
    user: null,
    users: null
  }),
  actions: {
    fetchAccount ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/account'
      return new Promise((resolve, reject) => {
        if (state.account === null) {
          Http.get(url).then(response => {
            commit('setAccount', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.account)
        }
      })
    },
    saveAccount ({ commit, state }, account) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/account'
      const payload = {
        account: account
      }
      return new Promise((resolve, reject) => {
        Http.put(url, payload).then(response => {
          commit('setAccount', response.data)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    fetchAvailability ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/availability'
      return new Promise((resolve, reject) => {
        if (state.availability === null) {
          Http.get(url).then(response => {
            const availability = response.data
            for (const [key, value] of Object.entries(availability)) {
              if (value.start) {
                availability[key].start = moment('2020-01-01 ' + value.start).toDate()
              } else {
                availability[key].start = moment('2020-01-01 08:30').toDate()
              }
              if (value.end) {
                availability[key].end = moment('2020-01-01 ' + value.end).toDate()
              } else {
                availability[key].end = moment('2020-01-01 17:00').toDate()
              }
            }
            commit('setAvailability', availability)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.availability)
        }
      })
    },
    saveAvailability ({ commit, state }, availabilityUpdate) {
      const availability = {
        sunday: { start: null, end: null, closed: null },
        monday: { start: null, end: null, closed: null },
        tuesday: { start: null, end: null, closed: null },
        wednesday: { start: null, end: null, closed: null },
        thursday: { start: null, end: null, closed: null },
        friday: { start: null, end: null, closed: null },
        saturday: { start: null, end: null, closed: null }
      }
      for (const [key, value] of Object.entries(availabilityUpdate)) {
        availability[key].start = moment(value.start).format('HH:mm')
        availability[key].end = moment(value.end).format('HH:mm')
        availability[key].closed = value.closed
      }
      const url = process.env.VUE_APP_ROOT_API + 'settings/availability'
      const payload = {
        availability: availability
      }
      return new Promise((resolve, reject) => {
        Http.put(url, payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    savePassword ({ commit, state }, passwordUpdate) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/password-update'
      const payload = {
        passwordUpdate: passwordUpdate
      }
      return new Promise((resolve, reject) => {
        Http.post(url, payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    fetchTheme ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/theme'
      return new Promise((resolve, reject) => {
        if (state.theme === null) {
          Http.get(url).then(response => {
            commit('setTheme', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.theme)
        }
      })
    },
    saveTheme ({ commit, state }, theme) {
      console.log('saving theme', theme)
      const url = process.env.VUE_APP_ROOT_API + 'settings/theme'
      const payload = {
        theme: theme
      }
      return new Promise((resolve, reject) => {
        Http.put(url, payload).then(response => {
          commit('setTheme', response.data)
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    fetchBankAccount ({ commit, state }, bankAccountId) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/bank-accounts/' + bankAccountId
      return new Promise((resolve, reject) => {
        if (state.bankAccount === null || state.bankAccount.id !== bankAccountId) {
          Http.get(url).then(response => {
            commit('setBankAccount', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.bankAccount)
        }
      })
    },
    fetchBankInstitutions ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/bank-institutions'
      return new Promise((resolve, reject) => {
        if (state.bankInstitutions === null) {
          Http.get(url).then(response => {
            commit('setBankInstitutions', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.bankInstitutions)
        }
      })
    },
    fetchBankAccounts ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/bank-accounts'
      return new Promise((resolve, reject) => {
        if (state.bankAccounts === null) {
          Http.get(url).then(response => {
            commit('setBankAccounts', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.bankAccount)
        }
      })
    },
    saveBankAccount ({ commit, state }, bankAccount) {
      let url = process.env.VUE_APP_ROOT_API + 'settings/bank-accounts'
      const payload = {
        bankAccount: bankAccount
      }
      if (bankAccount.id) {
        url += '/' + bankAccount.id
      }
      return new Promise((resolve, reject) => {
        if (bankAccount.id) {
          Http.put(url, payload).then(response => {
            commit('setBankAccounts', null)
            commit('setBankAccount', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        } else {
          Http.post(url, payload).then(response => {
            commit('setBankAccounts', null)
            commit('setBankAccount', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        }
      })
    },
    fetchServiceCategory ({ commit, state }, serviceCategoryId) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/service-categories/' + serviceCategoryId
      return new Promise((resolve, reject) => {
        if (state.serviceCategory === null || state.serviceCategory.id !== serviceCategoryId) {
          Http.get(url).then(response => {
            commit('setServiceCategory', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.serviceCategory)
        }
      })
    },
    fetchServiceCategories ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/service-categories'
      return new Promise((resolve, reject) => {
        if (state.serviceCategories === null) {
          Http.get(url).then(response => {
            commit('setServiceCategories', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.serviceCategories)
        }
      })
    },
    saveServiceCategory ({ commit, state }, serviceCategory) {
      let url = process.env.VUE_APP_ROOT_API + 'settings/service-categories'
      const payload = {
        service_category: serviceCategory
      }
      if (serviceCategory.id) {
        url += '/' + serviceCategory.id
      }
      return new Promise((resolve, reject) => {
        if (serviceCategory.id) {
          Http.put(url, payload).then(response => {
            commit('setServiceCategories', null)
            commit('setServiceCategory', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        } else {
          Http.post(url, payload).then(response => {
            commit('setServiceCategories', null)
            commit('setServiceCategory', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        }
      })
    },
    fetchService ({ commit, state }, serviceId) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/services/' + serviceId
      return new Promise((resolve, reject) => {
        if (state.service === null || state.service.id !== serviceId) {
          Http.get(url).then(response => {
            commit('setService', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.service)
        }
      })
    },
    fetchServices ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/services'
      return new Promise((resolve, reject) => {
        if (state.services === null) {
          Http.get(url).then(response => {
            commit('setServices', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.services)
        }
      })
    },
    saveService ({ commit, state }, service) {
      let url = process.env.VUE_APP_ROOT_API + 'settings/services'
      const payload = {
        service: service
      }
      if (service.id) {
        url += '/' + service.id
      }
      return new Promise((resolve, reject) => {
        if (service.id) {
          Http.put(url, payload).then(response => {
            commit('setServices', null)
            commit('setService', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        } else {
          Http.post(url, payload).then(response => {
            commit('setServices', null)
            commit('setService', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        }
      })
    },
    fetchTaxRateGroup ({ commit, state }, taxRateGroupId) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/tax-rate-groups/' + taxRateGroupId
      return new Promise((resolve, reject) => {
        if (state.service === null || state.taxRateGroup.id !== taxRateGroupId) {
          Http.get(url).then(response => {
            commit('setTaxRateGroup', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.taxRateGroup)
        }
      })
    },
    fetchTaxRateGroups ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/tax-rate-groups'
      return new Promise((resolve, reject) => {
        if (state.taxRateGroups === null) {
          Http.get(url).then(response => {
            commit('setTaxRateGroups', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.taxRateGroups)
        }
      })
    },
    saveTaxRateGroup ({ commit, state }, taxRateGroup) {
      let url = process.env.VUE_APP_ROOT_API + 'settings/tax-rate-groups'
      const payload = {
        taxRateGroup: taxRateGroup
      }
      if (taxRateGroup.id) {
        url += '/' + taxRateGroup.id
      }
      return new Promise((resolve, reject) => {
        if (taxRateGroup.id) {
          Http.put(url, payload).then(response => {
            commit('setTaxRateGroups', null)
            commit('setTaxRateGroup', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        } else {
          Http.post(url, payload).then(response => {
            commit('setTaxRateGroups', null)
            commit('setTaxRateGroup', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        }
      })
    },
    fetchCountries ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/countries'
      return new Promise((resolve, reject) => {
        if (state.countries === null) {
          Http.get(url).then(response => {
            commit('setCountries', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.countries)
        }
      })
    },
    fetchTimeZones ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/time-zones'
      return new Promise((resolve, reject) => {
        if (state.timeZones === null) {
          Http.get(url).then(response => {
            commit('setTimeZones', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.timeZones)
        }
      })
    },
    fetchUser ({ commit, state }, userId) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/users/' + userId
      return new Promise((resolve, reject) => {
        if (state.user === null || state.user.id !== userId) {
          Http.get(url).then(response => {
            commit('setUser', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.user)
        }
      })
    },
    fetchUsers ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/users'
      return new Promise((resolve, reject) => {
        if (state.users === null) {
          Http.get(url).then(response => {
            commit('setUsers', response.data)
            resolve(response.data)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.users)
        }
      })
    },
    saveUser ({ commit, state }, user) {
      let url = process.env.VUE_APP_ROOT_API + 'settings/users'
      const payload = {
        user: user
      }
      if (user.id) {
        url += '/' + user.id
      }
      return new Promise((resolve, reject) => {
        if (user.id) {
          Http.put(url, payload).then(response => {
            commit('setUsers', null)
            commit('setUser', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        } else {
          Http.post(url, payload).then(response => {
            commit('setUsers', null)
            commit('setUser', response.data)
            resolve(response)
          }, error => {
            reject(error)
          })
        }
      })
    },
    fetchInvoiceSettings ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/invoice'
      return new Promise((resolve, reject) => {
        if (state.invoiceSettings === null) {
          Http.get(url).then((response) => {
            const invoiceSettings = response.data.invoiceSettings
            commit('setInvoiceSettings', invoiceSettings)
            resolve(invoiceSettings)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.invoiceSettings)
        }
      })
    },
    saveInvoiceSettings ({ commit, state }, invoiceSettings) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/invoice'
      const payload = {
        invoiceSettings: invoiceSettings
      }
      return new Promise((resolve, reject) => {
        Http.put(url, payload).then(response => {
          const invoiceSettings = response.data.invoiceSettings
          commit('setInvoiceSettings', invoiceSettings)
          resolve(invoiceSettings)
        }, error => {
          reject(error)
        })
      })
    },
    fetchStripe ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/stripe'
      return new Promise((resolve, reject) => {
        if (state.stripe === null) {
          Http.get(url).then((response) => {
            const stripe = response.data.stripe
            commit('setStripe', stripe)
            resolve(stripe)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.stripe)
        }
      })
    },
    fetchSubscription ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/subscription'
      return new Promise((resolve, reject) => {
        if (state.subscription === null) {
          Http.get(url).then((response) => {
            const subscription = response.data.subscription
            commit('setSubscription', subscription)
            resolve(subscription)
          }, error => {
            reject(error)
          })
        } else {
          resolve(state.subscription)
        }
      })
    },
    saveSubscription ({ commit, state }, stripeCardToken) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/subscription'
      const payload = {
        subscription: {
          stripeCardToken: stripeCardToken
        }
      }
      return new Promise((resolve, reject) => {
        Http.post(url, payload).then(response => {
          const subscription = response.data.subscription
          commit('setSubscription', subscription)
          resolve(subscription)
        }, error => {
          reject(error)
        })
      })
    },
    cancelSubscription ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/subscription'
      return new Promise((resolve, reject) => {
        Http.delete(url).then((response) => {
          const subscription = response.data.subscription
          commit('setSubscription', subscription)
          resolve(subscription)
        }, error => {
          reject(error)
        })
      })
    },
    resumeSubscription ({ commit, state }) {
      const url = process.env.VUE_APP_ROOT_API + 'settings/subscription-resume'
      return new Promise((resolve, reject) => {
        Http.post(url).then((response) => {
          const subscription = response.data.subscription
          commit('setSubscription', subscription)
          resolve(subscription)
        }, error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    account (state) {
      if (state.account) {
        return state.account
      }
      return null
    },
    availability (state) {
      if (state.availability) {
        return state.availability
      }
      return null
    },
    bankAccount (state) {
      if (state.bankAccount) {
        return state.bankAccount
      }
      return null
    },
    bankAccounts (state) {
      if (state.bankAccounts) {
        return state.bankAccounts
      }
      return null
    },
    bankInstitutions (state) {
      if (state.bankInstitutions) {
        return state.bankInstitutions
      }
      return null
    },
    invoiceSettings (state) {
      if (state.invoiceSettings) {
        return state.invoiceSettings
      }
      return null
    },
    payment (state) {
      if (state.payment) {
        return state.payment
      }
      return null
    },
    service (state) {
      if (state.service) {
        return state.service
      }
      return null
    },
    services (state) {
      if (state.services) {
        return state.services
      }
      return null
    },
    serviceCategory (state) {
      if (state.serviceCategory) {
        return state.serviceCategory
      }
      return null
    },
    serviceCategories (state) {
      if (state.serviceCategories) {
        return state.serviceCategories
      }
      return null
    },
    stripe (state) {
      if (state.stripe) {
        return state.stripe
      }
      return null
    },
    subscription (state) {
      if (state.subscription) {
        return state.subscription
      }
      return null
    },
    theme (state) {
      if (state.theme) {
        return state.theme
      }
      return null
    },
    taxRateGroup (state) {
      if (state.taxRateGroup) {
        return state.taxRateGroup
      }
      return null
    },
    taxRateGroups (state) {
      if (state.taxRateGroups) {
        return state.taxRateGroups
      }
      return null
    },
    countries (state) {
      if (state.countries) {
        return state.countries
      }
      return null
    },
    timeZones (state) {
      if (state.timeZones) {
        return state.timeZones
      }
      return null
    },
    user (state) {
      if (state.user) {
        return state.user
      }
      return null
    },
    users (state) {
      if (state.users) {
        return state.users
      }
      return null
    }
  },
  mutations: {
    setAccount (state, account) {
      state.account = account
    },
    setAvailability (state, availability) {
      state.availability = availability
    },
    setBankAccount (state, bankAccount) {
      state.bankAccount = bankAccount
    },
    setBankAccounts (state, bankAccounts) {
      if (bankAccounts && bankAccounts.length) {
        state.bankAccounts = bankAccounts
      } else {
        state.bankAccounts = null
      }
    },
    setBankInstitutions (state, bankInstitutions) {
      state.bankInstitutions = bankInstitutions
    },
    setInvoiceSettings (state, invoiceSettings) {
      state.invoiceSettings = invoiceSettings
    },
    setPayment (state, payment) {
      state.payment = payment
    },
    setService (state, service) {
      state.service = service
    },
    setServices (state, services) {
      if (services && services.length) {
        state.services = services
      } else {
        state.services = null
      }
    },
    setServiceCategory (state, serviceCategory) {
      state.serviceCategory = serviceCategory
    },
    setServiceCategories (state, serviceCategories) {
      if (serviceCategories && serviceCategories.length) {
        state.serviceCategories = serviceCategories
      } else {
        state.serviceCategories = null
      }
    },
    setSubscription (state, subscription) {
      state.subscription = subscription
    },
    setStripe (state, stripe) {
      state.stripe = stripe
    },
    setTheme (state, theme) {
      state.theme = theme
    },
    setTaxRateGroup (state, taxRateGroup) {
      state.taxRateGroup = taxRateGroup
    },
    setTaxRateGroups (state, taxRateGroups) {
      if (taxRateGroups && taxRateGroups.length) {
        state.taxRateGroups = taxRateGroups
      } else {
        state.taxRateGroups = null
      }
    },
    setCountries (state, countries) {
      state.countries = countries
    },
    setTimeZones (state, timeZones) {
      state.timeZones = timeZones
    },
    setUser (state, user) {
      state.user = user
    },
    setUsers (state, users) {
      if (users && users.length) {
        state.users = users
      } else {
        state.users = null
      }
    }
  }
}

export default Settings
