<template>
  <!-- <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.2857 4.39023C11.2857 2.59623 9.65457 1.14209 7.64286 1.14209C5.63114 1.14209 4 2.92109 4 4.71509" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8.28571 6.5714V13C8.28571 14.1833 7.32614 15.1428 6.14286 15.1428C4.95957 15.1428 4 14.1833 4 13V4.71509" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M11.2857 4.39026C11.2857 4.39026 11.2857 11.2186 11.2857 13.8406C11.2857 16.4626 8.983 18.5879 6.14286 18.5879C3.30271 18.5879 1 16.4626 1 13.8406V6.57143" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_attachment_30</title>
    <g id="icon_attachment_30" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="Group" transform="translate(14.500000, 16.000000) scale(-1, 1) rotate(45.000000) translate(-14.500000, -16.000000) translate(1.000000, 10.000000)" stroke="#AAAAAA" stroke-width="2">
            <path d="M16.8544589,8.59689957 C14.5701487,8.59689957 11.1436835,8.59689957 6.57506328,8.59689957 C5.13435377,8.59689957 3.96642796,7.62897628 3.96642796,6.4349824 L3.96642796,5.58954664 C3.96642796,4.39555276 5.13435377,3.42762947 6.57506328,3.42762947 L17.1215704,3.42762947" id="Rectangle-5"></path>
            <path d="M16.6176586,12.0012481 L6.15466772,12.0012481 C2.2237142,12.0012481 3.96255809e-14,9.00640896 3.96255809e-14,5.74861517 C3.96255809e-14,2.49082139 2.34423397,1.36358617e-13 6.27518749,1.36358617e-13 L16.6176586,1.36358617e-13 L20.3992036,1.36358617e-13" id="Rectangle-5-Copy"></path>
            <path d="M23.7591756,8.59689957 L16.3727918,8.59689957 C13.5977219,8.59689957 11.3480832,6.67242192 11.3480832,4.29845843 C11.3480832,1.92449493 13.5977219,2.03955196e-13 16.3727918,2.03955196e-13 L23.6740943,2.03955196e-13 L26.3436888,2.03955196e-13" id="Rectangle-5-Copy-2" transform="translate(18.845886, 4.298450) scale(-1, 1) translate(-18.845886, -4.298450) "></path>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconPaperClip',
  props: ['stroke', 'fill']
}

</script>
