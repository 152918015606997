import Http from '@/http'

const NotesStore = {
  namespaced: true,
  state: () => ({
    notes: []
  }),
  actions: {
    create ({ commit }, note) {
      const url = process.env.VUE_APP_ROOT_API + 'notes'
      return new Promise((resolve, reject) => {
        Http.post(url, { note: note }).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    fetch ({ commit }) {
      const url = process.env.VUE_APP_ROOT_API + 'notes'
      return new Promise((resolve, reject) => {
        Http.get(url).then(
          response => {
            if (response.data) {
              reject(response)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    }
  },
  getters: {
    all (state) {
      return state.notes
    }
  },
  mutations: {
    setNotes (state, notes) {
      state.notes = notes
    }
  }
}

export default NotesStore
