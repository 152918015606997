<template>
  <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>arrow_lrg_10</title>
      <g id="arrow_lrg_10" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M4.99726902,0 C5.54910987,-1.01371518e-16 5.99646526,0.447355392 5.99646526,0.999196234 L5.996,6.533 L8.26940101,4.24133026 L8.26940101,4.24133026 C8.66707428,3.84668512 9.30023449,3.84914089 9.68924117,4.24117692 L9.68924117,4.24117692 L9.71466448,4.2667982 C10.1014798,4.6566258 10.1015477,5.28545152 9.7148166,5.67536267 L9.7148166,5.67536267 L5.72192357,9.70108868 L5.72192357,9.70108868 C5.57174254,9.85015401 5.39442036,9.94036551 5.20864242,9.97745599 C5.20153765,9.97836022 5.19446856,9.97971253 5.18738838,9.98098879 C5.17026615,9.98450006 5.15291026,9.98711159 5.13550893,9.98926604 C4.83803735,10.0267485 4.52693541,9.93206845 4.29765206,9.70455683 L4.29363757,9.70055088 L0.28815223,5.68104219 C-0.103264655,5.28825494 -0.10394147,4.65308199 0.286637451,4.25946148 L0.300743477,4.24524561 C0.692881496,3.85005385 1.33113838,3.84757831 1.72633014,4.23971633 L1.73034463,4.24372228 L3.998,6.519 L3.99807279,0.999196234 C3.99807279,0.447355392 4.44542818,1.01371518e-16 4.99726902,0 Z" id="Combined-Shape" fill="#AAAAAA"></path>
      </g>
  </svg>
</template>

<script>

export default {
  name: 'IconArrowDownLarge',
  props: ['stroke', 'fill']
}

</script>
