<template>
  <input class="input is-medium is-fullwidth has-text-right" type="text" v-model="localValue" @click="selectNumber" @blur="reformat"/>
</template>

<script>

export default {
  data () {
    return {
      localValue: 0
    }
  },
  name: 'data-editor-field-decimal',
  methods: {
    reformat () {
      let validatedValue = this.validateValue(this.localValue)
      console.log('starting reformat', validatedValue)
      if (!this.maxPrecision && !this.minPrecision) {
        this.localValue = validatedValue.toFixed(2)
        return
      }
      const powerMultiplier = Math.pow(10, this.maxPrecision)
      const maxPrecisionNumber = validatedValue = Math.round((validatedValue + Number.EPSILON) * powerMultiplier) / powerMultiplier
      const minPrecisionNumber = parseFloat(maxPrecisionNumber + '')
      console.log('minPrecisionNumber', minPrecisionNumber)
      console.log('maxPrecisionNumber', maxPrecisionNumber)
      if (minPrecisionNumber === maxPrecisionNumber) {
        this.localValue = minPrecisionNumber
        return
      }
      for (let i = this.minPrecision; i <= this.maxPrecision; i++) {
        if (i === 0) {
          if (minPrecisionNumber.toFixed(i) + '.0' === minPrecisionNumber.toFixed(i + 1)) {
            this.localValue = minPrecisionNumber.toFixed(i)
            return
          }
        } else {
          if (minPrecisionNumber.toFixed(i) + '0' === minPrecisionNumber.toFixed(i + 1)) {
            this.localValue = minPrecisionNumber.toFixed(i)
            return
          }
        }
      }
      this.localValue = validatedValue.toFixed(this.maxPrecision)
    },
    selectNumber () {
      const input = this.$el.querySelector('input')
      input.focus()
      input.setSelectionRange(0, this.localValue.length)
    },
    validateValue (value) {
      if (!value) {
        return 0
      }
      // value = value + ''
      // value = value.replace('$', '')
      // value = value.replace(',', '')
      const floatVal = parseFloat(value)
      if (!floatVal) {
        return 0
      }
      return floatVal
    }
  },
  mounted () {
    this.localValue = this.value
  },
  props: ['value', 'minPrecision', 'maxPrecision']
}

</script>
