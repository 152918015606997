<template>
  <!-- <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15 6.52257V18.5277C15 18.7874 14.7809 19 14.5133 19H1.37278C1.16695 19 1 18.838 1 18.6383V1.23486C1 1.105 1.10865 1 1.24248 1H9.16667" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23042 1.06244L14.884 6.40982C15.0501 6.56915 14.9943 6.95565 14.7783 6.95711L9.28329 6.99999C9.06165 7.00145 9 6.81045 9 6.57657V1.22654C9 1.22654 9.06383 0.902626 9.23042 1.06244Z" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
  <path d="M8 10V15L6 12.7931" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 15L10 13" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg> -->
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>icon_download_pdf_20</title>
    <g id="icon_download_pdf_20" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="Group-8" transform="translate(3.000000, 1.000000)" stroke="#AAAAAA" stroke-width="2">
            <path d="M14,5.52257143 L14,17.5277143 C14,17.7874286 13.7809279,18 13.5132714,18 L0.372775838,18 C0.166954108,18 2.81913382e-13,17.838 2.81913382e-13,17.6382857 L2.81913382e-13,0.234857143 C2.81913382e-13,0.105 0.108652673,0 0.242480966,0 L8.16666667,0" id="Stroke-188"></path>
            <path d="M8.23041595,0.0624492769 L13.8840023,5.40982157 C14.0501178,5.56915282 13.9942743,5.95565178 13.7782771,5.95711353 L8.28329041,5.99999167 C8.06164626,6.00145342 8.00000001,5.81045082 8.00000001,5.57657008 L8.00000001,0.226548209 C8.00000001,0.226548209 8.0638299,-0.0973692271 8.23041595,0.0624492769 Z" id="Stroke-190"></path>
            <polyline id="Stroke-298" stroke-linejoin="round" points="7 9 7 14 5 11.7930663"></polyline>
            <line x1="9" y1="14" x2="7" y2="12" id="Stroke-298" stroke-linejoin="round" transform="translate(8.000000, 13.000000) scale(-1, 1) translate(-8.000000, -13.000000) "></line>
        </g>
    </g>
</svg>
</template>

<script>

export default {
  name: 'IconDownloadDocument',
  props: ['stroke', 'fill']
}

</script>
