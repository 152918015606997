<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="18" height="18" rx="2" :fill="fill"/>
    <rect x="3" y="3" width="18" height="18" rx="2" :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="m9,3l0,18"/>
    <path :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="m15.18799,9.8l-2.8,2l2.8,2"/>
    <path :stroke="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="m18.008,11.92l-5,0"/>
  </svg>
</template>

<script>

export default {
  name: 'IconMenuCollapse',
  props: ['stroke', 'fill']
}

</script>
