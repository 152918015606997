import Http from '@/http'
import moment from 'moment'

const QuotesStore = {
  namespaced: true,
  state: () => ({
    quote: {
      items: []
    },
    quotes: []
  }),
  actions: {
    resetQuote ({ commit }) {
      const issuedAt = moment().toDate()
      const expiresAt = moment().add('14', 'days').toDate()
      const quote = {
        finalized: false,
        issuedAt: issuedAt,
        expiresAt: expiresAt,
        invoice_id: null,
        tax_inclusive: true,
        status: 'New',
        items: [
          {
            id: null,
            index: null,
            description: '',
            quantity: 0,
            unit_price: 0,
            tax_rate: {
              id: 1,
              name: '10% GST',
              rate: 0.1
            },
            tax_rate_id: 1,
            total_price: 0
          }
        ]
      }
      commit('setQuote', quote)
    },
    deleteQuote ({ commit }, quoteId) {
      const url = process.env.VUE_APP_ROOT_API + 'quotes/' + quoteId
      return new Promise((resolve, reject) => {
        Http.delete(url)
          .then(response => { resolve(response) }, error => { reject(error) })
      })
    },
    sendQuoteEmail ({ commit, state }, payload) {
      const url = process.env.VUE_APP_ROOT_API + 'quotes/' + payload.quoteId + '/email'
      return new Promise((resolve, reject) => {
        Http.post(url, payload).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    convertToInvoice ({ commit, state }, payload) {
      const url = process.env.VUE_APP_ROOT_API + 'quotes/' + payload.quoteId + '/convert-to-invoice'
      return new Promise((resolve, reject) => {
        Http.post(url).then(response => {
          resolve(response)
        }, error => {
          reject(error)
        })
      })
    },
    fetch ({ commit }) {
      const url = process.env.VUE_APP_ROOT_API + 'quotes'
      return new Promise((resolve, reject) => {
        Http.get(url).then(
          response => {
            if (response.data) {
              commit('setQuotes', response.data)
            }
            resolve(response)
          },
          error => {
            reject(error)
          }
        )
      })
    },
    updateQuoteItem ({ commit, state, getters }, payload) {
      const quoteItem = {
        id: payload.item.id,
        index: payload.item.index,
        quote_id: state.quote.id,
        description: payload.item.description,
        quantity: payload.item.quantity,
        unit_price: payload.item.unitPrice,
        tax_rate: payload.item.taxRate,
        total_price: payload.item.totalIncTax,
        deleted: payload.item.deleted
      }
      commit('setQuoteItem', {
        index: payload.item.index,
        item: quoteItem
      })
    },
    updateTaxInclusiveStatus ({ commit, state }, taxInclusive) {
      commit('setQuoteTaxInclusive', taxInclusive)
    },
    deleteQuoteItem ({ commit }, quoteItemIndex) {
      commit('setQuoteItemDeleted', quoteItemIndex)
    },
    fetchQuote ({ commit }, payload) {
      const url = process.env.VUE_APP_ROOT_API + 'quotes/' + payload.quoteId
      Http.get(url)
        .then((response) => {
          if (response.data) {
            commit('setQuote', response.data)
          }
        })
    },
    saveQuote ({ state, getters, commit }) {
      let url = process.env.VUE_APP_ROOT_API + 'quotes'
      if (state.quote.id) {
        url = url + '/' + state.quote.id
      }
      return new Promise((resolve, reject) => {
        const quote = state.quote
        quote.issued_at = moment(quote.issuedAt).format('YYYY-MM-DD')
        quote.expires_at = moment(quote.expiresAt).format('YYYY-MM-DD')
        quote.amount = getters.quoteTotal
        quote.items.map((item) => {
          item.tax_rate_id = item.tax_rate.id
          if (item.deleted) {
            item.deleted_at = moment().format('YYYY-MM-DD')
          }
          return item
        })
        if (quote.id) {
          Http.put(url, { quote: quote }).then(response => {
            commit('setQuote', response.data)
            resolve({ isNew: false, quote: response.data })
          }, error => {
            reject(error)
          })
        } else {
          Http.post(url, { quote: quote }).then(response => {
            commit('setQuote', response.data)
            resolve({ isNew: true, quote: response.data })
          }, error => {
            reject(error)
          })
        }
      })
    }
  },
  getters: {
    quote (state) {
      return state.quote
    },
    quoteItems (state) {
      return state.quote.items
        .map((quoteItem, index) => {
          quoteItem.index = index
          return quoteItem
        })
        .filter((quoteItem) => {
          return !quoteItem.deleted
        })
    },
    quoteSubtotal (state, getters) {
      let subtotal = 0
      state.quote.items
        .filter((quoteItem) => { return !quoteItem.deleted })
        .forEach((quoteItem) => {
          subtotal += quoteItem.quantity * quoteItem.unit_price
        })
      return subtotal
    },
    quoteTaxTotal (state) {
      let taxTotal = 0
      state.quote.items
        .filter((quoteItem) => { return !quoteItem.deleted })
        .forEach((quoteItem) => {
          taxTotal += quoteItem.quantity * quoteItem.unit_price * quoteItem.tax_rate.rate
        })
      return taxTotal.toFixed(2)
    },
    quoteTotal (state) {
      let quoteTotal = 0
      state.quote.items
        .filter((quoteItem) => { return !quoteItem.deleted })
        .forEach((quoteItem) => {
          if (state.quote.tax_inclusive) {
            quoteTotal += quoteItem.quantity * quoteItem.unit_price
          } else {
            quoteTotal += quoteItem.quantity * quoteItem.unit_price * (1 + quoteItem.tax_rate.rate)
          }
        })
      return quoteTotal.toFixed(2)
    },
    quoteItem: (state, getters) => (itemIndex) => {
      return state.quote.items[itemIndex]
    },
    invoiced (state) {
      return state.quotes.filter((quote) => {
        return (quote.finalized && quote.invoice_id !== null)
      })
    },
    issued (state) {
      return state.quotes.filter((quote) => {
        return (quote.finalized === true && quote.invoice_id === null)
      })
    },
    draft (state) {
      return state.quotes.filter((quote) => {
        return (quote.finalized === false)
      })
    },
    quoteFinalized (state) {
      return !(state.quote.finalized !== true)
    }
  },
  mutations: {
    setQuote (state, quote) {
      const issuedAtDate = (quote.issued_at) ? moment(quote.issued_at) : moment()
      const expiresAtDate = (quote.expires_at) ? moment(quote.expires_at) : moment().add('30', 'days')
      quote.issuedAt = issuedAtDate.toDate()
      quote.expiresAt = expiresAtDate.toDate()
      quote.items.map((quoteItem) => {
        quoteItem.deleted = false
        return quoteItem
      })
      state.quote = quote
    },
    setQuotes (state, quotes) {
      state.quotes = quotes
    },
    deleteQuoteItem (state, payload) {
      state.quote.items.splice(payload.index, 1)
    },
    setQuoteItem (state, payload) {
      if (payload.index === null) {
        state.quote.items.push(payload.item)
      } else {
        state.quote.items.splice(payload.index, 1, payload.item)
      }
    },
    setQuoteItemDeleted (state, quoteItemIndex) {
      state.quote.items[quoteItemIndex].deleted = true
    },
    setQuoteTaxInclusive (state, taxInclusive) {
      const quoteItems = state.quote.items.map((item) => {
        if (taxInclusive) {
          item.total_price = item.quantity * item.unit_price
        } else {
          item.total_price = item.quantity * item.unit_price * (1 + item.tax_rate.rate)
        }
        return item
      })
      state.quote.items = quoteItems
      state.quote.tax_inclusive = taxInclusive
    }
  }
}

export default QuotesStore
