<template>
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>20_create_album</title>
    <g id="20_create_album" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <circle id="Oval" fill="#FFFFFF" cx="10" cy="10" r="10"></circle>
      <g id="Group" transform="translate(3.000000, 3.000000)" stroke="#ABBAC6" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
        <path d="M14,9.54870902 L14,12.2 C14,13.1941125 13.1941125,14 12.2,14 L1.8,14 C0.80588745,14 0,13.1941125 0,12.2 L0,1.8 C0,0.80588745 0.80588745,0 1.8,0 L12.2,0 C12.637904,0 13.0392851,0.156372743 13.3513499,0.416324648" id="Path"></path>
        <polyline id="Path" points="14 9.47376359 10.1768086 7 2 14"></polyline>
      </g>
      <g id="Group-22" transform="translate(14.500000, 5.240000)" stroke="#2B3A58" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
        <line x1="2.5" y1="0.5" x2="2.5" y2="4.5" id="Line"></line>
        <line x1="2.5" y1="0.5" x2="2.5" y2="4.5" id="Line" transform="translate(2.500000, 2.500000) rotate(-90.000000) translate(-2.500000, -2.500000) "></line>
      </g>
      <path d="M7.44193413,8.84572037 C8.1783138,8.84572037 8.77526747,8.2487667 8.77526747,7.51238703 C8.77526747,6.77600737 8.1783138,6.1790537 7.44193413,6.1790537 C6.70555447,6.1790537 6.1086008,6.77600737 6.1086008,7.51238703 C6.1086008,8.2487667 6.70555447,8.84572037 7.44193413,8.84572037 Z" id="Oval" stroke="#ABBAC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    </g>
  </svg>
</template>

<script>

export default {
  name: 'IconDropdownCreateAlbum',
  props: ['stroke', 'fill']
}

</script>
