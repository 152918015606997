<template>
  <section>
    <b-dropdown aria-role="list" position="is-bottom-left" :active="false">
      <a :class="['link', type]" slot="trigger" role="button">
        <icon icon="snowman" class="is-flex" width="31" height="31"/>
      </a>
      <b-dropdown-item v-for="(item, index) in items" :key="index" value="action" aria-role="listitem" @click="itemClicked(item)">
        <div class="media">
          <icon class="media-left" width="20" height="20" :icon="item.icon"/>
          <div class="media-content">
            <span>{{ item.name }}</span>
            <!-- <small>{{ item.description }}</small> -->
          </div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </section>
</template>

<style lang="scss" scoped>

.tag {
  cursor: pointer;
}

a.dropdown-item.is-active {
  font-weight: 600;
  font-size: 14px;
  color: #42424260;
  background-color: #fff;
}

a.dropdown-item.is-active:hover {
  background-color: #eee;
}

a {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #42424260;
}

a:hover {
  color: #424242;
}

</style>

<script>
import Icon from '@/components/Icons/Icon'

export default {
  name: 'SnowmanMenu',
  components: { Icon },
  methods: {
    itemClicked (item) {
      if (item.emit) {
        this.$emit(item.emit)
      }
      if (item.route) {
        this.$router.push(item.route)
      }
      if (item.link) {
        window.location(item.link)
      }
      return null
    }
  },
  props: ['items', 'type']
}
</script>
